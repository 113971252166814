import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import { PatientInfo } from "../../../shared/types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { app } from "../../firebase";
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import isPHN from "./isPHN";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>{
    return {
        ...sharedStyles(theme)
    }
});



interface PHNInputProps extends ControlledInputProps {
    required?: boolean,
    setValue?: (patientInfo: PatientInfo) => void,
    ignoreChecksum?: boolean,
    skipServerWarmup?: boolean,
    disabled?: boolean
}


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}
  
const TextMaskCustom = (props: TextMaskCustomProps) => {
    const { inputRef, ...other } = props;
  
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
            inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}

//Dropdown lookup for patient info based on PHN
//Value returned is a PatientInfo object
const PHNInputLookup = ({ name, control, label, required, setValue, ignoreChecksum, skipServerWarmup, disabled }: PHNInputProps) => {
    const classes = useStyles();
    const defaultValue: PatientInfo = {
        phn: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    }

    const lookupPatientInfo = app.functions().httpsCallable("patientInfo-lookup");

    const [patientInfoList, setPatientInfoList] = useState<PatientInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    //Wakeup server
    useEffect(() => {
        if(!skipServerWarmup){
            lookupPatientInfo()
            .then((res) => {
                console.log(res.data.message);
            })
            .catch((error: Error) => {
                console.log(error);
            });
        }
    }, [])

    //Search phns
    const handleSearchStringChange = (searchString: string) => {
        if(searchString.length == 3 || searchString.length == 9) {
            setLoading(true);
            lookupPatientInfo({phn: searchString})
            .then((res) => {
                setPatientInfoList(res.data as PatientInfo[]);
            })
            .catch((error: Error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })
        }
        else if(searchString.length < 3) {
            setPatientInfoList([]);
        }
    }


    const filterOptions = createFilterOptions({
        stringify: (option: PatientInfo): string => option.phn,
    });

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ validate: {
                required: (value: PatientInfo) => {
                    if(required)
                        return value.phn === "" ? "PHN is required" : undefined;
                    return undefined;
                },
                isPHN: (value: PatientInfo) => {
                    if(ignoreChecksum)
                        return undefined;
                    return isPHN(value.phn);
                }
            }}}
            render={({field, fieldState}) =>
                <Autocomplete
                    {...field}
                    className={classes.canDisable}
                    options={patientInfoList}
                    getOptionLabel={(option:PatientInfo) => {
                        let labelName = `${option.firstName} ${option.lastName}`;
                        if(labelName === ' ') {
                            labelName = option.email
                        }
                        return `${option.phn.substr(0, 5)}-${option.phn.substr(5, 9)}: ${labelName}`
                    }}
                    renderInput={(params) => { 
                        const inputProps: any = params.InputProps;
                        if(!ignoreChecksum) {
                            inputProps.inputComponent = TextMaskCustom;
                        }

                        return (    
                            <TextField
                                {...field}
                                {...Object.assign(params, {InputProps: inputProps})}
                                label={label}
                                onChange={(e) => {
                                    const phn = e.target.value.split('-').join('');
                                    handleSearchStringChange(phn);
                                    field.onChange({...field.value, phn: phn});
                                }}
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    disableAnimation: true
                                }}
                            />
                        );
                    }}
                    inputValue={field?.value?.phn ? field.value.phn : ""}
                    onChange={(_, data) => {                       
                        if(data === null) {
                            field.onChange(defaultValue);
                            if(setValue) {
                                setValue(defaultValue);
                            }
                        }
                        else {
                            field.onChange(data);
                            if(setValue) {
                                setValue(data as PatientInfo);
                            }
                        }                        
                    }}
                    freeSolo
                    loading={loading}
                    filterOptions={filterOptions}
                    disabled={disabled}
                />
            }
        />

    );
}

export default PHNInputLookup;