import React from 'react'
import { Gallery } from '../../Pages/Gallery'
import OtoscopeCard from './OtoscopeCard'

const GalleryContainer = ({autoRefresh, paperHeight}: any) => {
    return (
        <OtoscopeCard paperHeight={paperHeight}><Gallery autoRefresh /></OtoscopeCard>
    )
}

export default GalleryContainer
