import { createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import React, {  useEffect } from 'react';
import Webcam from "react-webcam";


interface IOtoscopeCameraProps {
    image: any,
    webcamRef: any
    devices: any
    deviceId: any
    videoConstraints: any
    setvideoConstraints: any,
    cameraHeight: number
}

export const OtoscopeCamera = ({ image, webcamRef, devices, deviceId, videoConstraints, setvideoConstraints, cameraHeight }: IOtoscopeCameraProps) => {


    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            video: {
                width: '100%'
            }
        }),
    );

    const classes = useStyles()


    //update video constraints when the available device list is updated
    useEffect(() => {
        if (devices != undefined) {
            setvideoConstraints({ ...videoConstraints, deviceId: deviceId.deviceId })
        }
    }, [devices, deviceId])


    return (
        <>
            {devices.length == 0 && image == '' ?
                <div style={{textAlign:'center'}}>
                    <Typography>No Available Cameras...</Typography>
                    <Typography>Please refresh the device list after plugging a device in.</Typography>
                    <Typography>If your device is already plugged in try refreshing the device list</Typography>
                </div> : 
           
            ( <Webcam
                className={classes.video}
                audio={false}
                ref={webcamRef}
                screenshotQuality={1}
                imageSmoothing={true}
                forceScreenshotSourceSize={true}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                height={cameraHeight}
            />)}
        </>
    );
};
