import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AuthContext } from '../../../../AuthProvider';
import { fbFirestore } from '../../../../firebase';

import { getDocumentData } from '@alethea-medical/utilities';
import { SpecialistTemplate, SpecialistTemplateField, SpecialistTemplateSuper, SpecialistTemplateSuperOptions, SpecialistTemplateOption } from "../../../../../shared/types";
import TemplateField from './TemplateField';
import { InputContext } from '../InputProvider';
import FloatingButton from '../../../../components/FloatingButton';
import { thinScrollbar } from '../../../../styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        box: {
			borderColor: theme.palette.grey[300],
        },
        templateContainer: {
            padding: theme.spacing(2),
            overflowY: "auto"
        },
    }),
);

interface SpecialistTemplatesProps {
    show: boolean,
    setShow: (show: boolean) => void,
    height: number,
}

const SpecialistTemplates = ({ show, setShow, height }: SpecialistTemplatesProps) => {

    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const inputContext = useContext(InputContext);

    //If specialist does not have a template (or not specialist, then do not show this menu)
    const [isAvailable, setIsAvailable] = useState<boolean>(false);

    const [templateOptions, setTemplateOptions] = useState<SpecialistTemplateSuperOptions[]>([]);
    const [selectedTemplateUid, setSelectedTemplateUid] = useState<string>("");
    const [selectedTemplate, setSelectedTemplate] = useState<SpecialistTemplate>();
    const [globalFields, setGlobalFields] = useState<SpecialistTemplateField[]>([]);

    useEffect(() => {
        if(authContext?.uid !== "") {
            fbFirestore.collection("specialist_templates").doc(authContext.uid).get().then(getDocumentData)
            .then((templateSuper: SpecialistTemplateSuper) => {
                if(templateSuper.templates.length > 0)
                    setSelectedTemplateUid(templateSuper.templates[0].uid);
                setTemplateOptions(templateSuper.templates);
                setGlobalFields(templateSuper.globalFields);
                setIsAvailable(true);
            })
            .catch((error: any) => {
                setIsAvailable(false);
            })
        }
    }, [authContext.uid])

    useEffect(() => {
        if(selectedTemplateUid !== undefined && selectedTemplateUid !== "") {
            fbFirestore.collection("specialist_templates").doc(authContext.uid).collection("templates").doc(selectedTemplateUid).get().then(getDocumentData)
            .then((template: SpecialistTemplate) => {
                setSelectedTemplate(template);
            })
        }
    }, [selectedTemplateUid])


    const optionSelectedHandler = (option: SpecialistTemplateOption) => {

        inputContext.setValue(`${inputContext.value}${inputContext.value.length > 0 ? " " : ""}${option.text}`);
        if(option.outcome !== undefined)
            inputContext.setOutcome(option.outcome)
        if(option.diagnosis !== undefined)
            inputContext.setDiagnosis(option.diagnosis)
    }

    return (
        <>
        {isAvailable && (
            <>
                <FloatingButton show={show} setShow={setShow} textShow="Show Specialist Templates" textHide="Hide Specialist Templates" top={10} right={0}/>
                <Slide in={show} direction="left" unmountOnExit>
                    <Grid item xs={4}>
                        <Box
                            className={`${classes.box} ${classes.templateContainer} ${classes.thinScrollbar}`}
                            borderLeft={1}
                            height={height}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Global Fields
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {globalFields.map((field) => 
                                            <TemplateField key={`global_field_${field.name}`} field={field} optionSelectedHandler={optionSelectedHandler}/>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        select
                                        label={"Selected Template"}
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedTemplateUid}
                                        onChange={(e) => {
                                            setSelectedTemplateUid(e.target.value as string);
                                        }}
                                        
                                    >
                                        {templateOptions.map((option: SpecialistTemplateSuperOptions, index: number) => {
                                            return <MenuItem key={`${option.uid}_${index}`} value={option.uid}>{option.name}</MenuItem>
                                        })}
                                    </TextField>
                                </Grid>
                                {selectedTemplate !== undefined && (
                                    <>
                                        {selectedTemplate.fields.map((field) => 
                                            <TemplateField key={`template_field_${field.name}`} field={field} optionSelectedHandler={optionSelectedHandler}/>
                                        )}
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Slide>
            </>
        )}
        </>
    );
}

export default SpecialistTemplates;