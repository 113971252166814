import React, { FC, useEffect, useState } from 'react';
import * as serviceWorker from '../serviceWorkerRegistration';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
//From https://felixgerschau.com/create-a-pwa-update-notification-with-create-react-app/

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };
  
    useEffect(() => {
        console.log("Registering service worker")
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);
  
    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };
  
    return (
        <Snackbar
            open={showReload}
            message="A new version is available!"
            onClick={reloadPage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
            <Button
                color="secondary"
                size="small"
                onClick={reloadPage}
            >
                Reload
            </Button>
            }
        />
    );
  }

export default ServiceWorkerWrapper;