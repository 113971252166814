import React, { useEffect } from "react";
import { useWatch, Control, UseFormGetValues, FieldValues } from "react-hook-form";
import Tooltip from "@material-ui/core/Tooltip";
import PHNInputLookup from "../../../components/PHNInputLookup";
import EmailInput from "../../../components/EmailInput";
import { PatientInfo } from "../../../../shared/types";
import FormCheckbox from "../../../components/FormCheckbox";


//Components are isolated to prevent rerenders to the entire page since useWatch will trigger a rerender
interface ReferralPHNProps {
    control: Control,
    trigger: (name?: string | string[]) => void
    setValue: (newPatientInfo: PatientInfo) => void,
    getValues: UseFormGetValues<FieldValues>,
    disabled: boolean
}

interface ReferralEmailProps {
    control: Control,
    trigger: (name?: string | string[]) => void
}

interface ReferralEmailPatientProps {
    control: Control,
    setValue: (name: string, value: any) => void
}
export const ReferralPHN = ({control, trigger, setValue, getValues, disabled}: ReferralPHNProps) => {
    const oop = useWatch({control, name: "oop", defaultValue: false });

    useEffect(() => {
        const patientInfo = getValues("patientInfo");
        if(patientInfo?.phn !== undefined && patientInfo?.phn !== "")
            trigger("patientInfo");
    }, [oop])

    return (
        <PHNInputLookup
            name="patientInfo"
            control={control}
            label="PHN"
            required
            setValue={setValue}
            ignoreChecksum={oop}
            disabled={disabled}
        />
    );
}

export const ReferralEmail = ({control, trigger}: ReferralEmailProps) => {
    const emailPatient = useWatch({control, name: "emailPatient", defaultValue: false })

    useEffect(() => {
        trigger('patientEmail');
    }, [emailPatient])

    return (
        <EmailInput  
            name="patientEmail"
            label="Patient Email"
            control={control}
            rules={{required: {value: emailPatient, message: "Email is required"}}}
        />
    );
}
export const ReferralEmailPatient = ({control, setValue}: ReferralEmailPatientProps) => {
    const patientEmail = useWatch({control, name: "patientEmail", defaultValue: ""})

    useEffect(() => {
        if(patientEmail == "")
        {
            setValue("emailPatient", false);
        }
    }, [patientEmail])


    return (
        <>
            {patientEmail !== "" && 
                //Wrap in span to stop error about refs from being thrown
                <Tooltip title="Send the patient a copy of the eConsult through Alethea Care. The eConsult does not contain any of your or the specialist's contact information.
                The eConsult will be sent and billed for tomorrow at 7:00 AM MST."
                placement="right">
                    <span>
                        <FormCheckbox
                            name="emailPatient"
                            control={control}
                            label="Send eConsult copy to patient"
                        />
                    </span>
                </Tooltip>
            }
        </>
    );
    
}