import React, { useContext, useState, useEffect } from "react";
import { logAnalyticsEvent } from "../../../firebase";
import { AuthContext } from "../../../AuthProvider";
import { formatTimeLong } from "../../../models/formatTime";
import { Activity } from "./types";
import MessageListItem from "../../../components/MessageListItem";
import EconsultOutcomesLabel from "./Econsult/EconsultOutcomesLabel";

interface ActivityListItemProps {
    activity: Activity,
    selected: boolean,
    setSelected: (id: string, checked: boolean) => void,
    openActivity: (id: string) => void
}

const ActivityListItem = ({ activity, openActivity, selected, setSelected }: ActivityListItemProps) => {
	const authContext = useContext(AuthContext);

    const [read, setRead] = useState<boolean>(true);


    useEffect(() => {
        setRead(activity.recentMessage.readBy.includes(authContext.uid))
    }, [activity])

    return (
        <MessageListItem
            selected={selected}
            setSelected={setSelected}
            openMessage={openActivity}
            id={activity.id}
            read={read}
            primary={activity.subject}
            secondary={
                <>
                    <EconsultOutcomesLabel specialistResponse={activity.exposedEconsultData?.specialistResponse} fontSize="small"/>
                    <div>
                        Last message sent at {formatTimeLong(activity.recentMessage.sentAt.toDate())}
                    </div>
                    <div>
                        eConsult sent at {formatTimeLong(activity.created.toDate())}
                    </div>
                </>
            }
        />
    );
}

export default ActivityListItem;