import React, { useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import { Location } from "../../../shared/types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>{
    return {
        ...sharedStyles(theme)
    }
});

interface LocationDropdownProps extends ControlledInputProps {
    disabled?: boolean
}

const LocationDropdown = ({ name, control, defaultValue, label, disabled, ...rest } : LocationDropdownProps) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : 0}
            rules={{validate: {
                locationSelected: (locationIdx: number | undefined): string | undefined => {
                    return authContext?.profile?.locations === undefined || authContext.profile.locations.length === 0 ? "Please select a location" : undefined
                }
            }}}
            render={({field, fieldState}) => 
                <TextField 
                    {...field}
                    {...rest}
                    className={classes.canDisable}
                    disabled={disabled || authContext?.profile?.locations === undefined || authContext.profile.locations.length <= 1}
                    select
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                >
                    {(authContext?.profile?.locations && authContext.profile.locations.length > 1) && authContext.profile.locations.map((loc: Location, index: number) => {
                        return <MenuItem key={`location_${index}`} value={index}>{loc.clinicName}</MenuItem>
                    })}
                    {(authContext?.profile?.locations && authContext.profile.locations.length === 1) && 
                        <MenuItem key={`location_${0}`} value={0}>{authContext.profile.locations[0].clinicName}</MenuItem>
                    }
                    {(authContext?.profile?.locations === undefined || authContext.profile.locations.length === 0) &&
                        <MenuItem key={`location_${0}`} value={0}>No clinics found</MenuItem>
                    }
                </TextField>
            }
        />
    );
}


export default LocationDropdown;