import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            marginRight: theme.spacing(1)
        }
    }),
);

interface SecureMessagingToolbarProps {
    archived: boolean,
    atLeastOneSelected: boolean,
    markAsUnread: () => void,
    toggleArchive: (archive: boolean) => void,
}

const SecureMessagingToolbar = ({ archived, atLeastOneSelected, markAsUnread, toggleArchive }: SecureMessagingToolbarProps) => {
    const classes = useStyles();

    return (
        <>
            <Collapse in={atLeastOneSelected} unmountOnExit>
                <ButtonGroup>
                    <Tooltip
                        title="Mark as unread"
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={markAsUnread}
                            >
                                <MarkunreadMailboxIcon className={classes.iconButton}/>
                                Unread
                            </Button>      
                        }
                    />      
                    <Tooltip
                        title={archived ? "Move to Inbox" : "Move to Archive"}
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {toggleArchive(!archived)}}
                            >
                                {archived ? <UnarchiveIcon className={classes.iconButton}/>: <ArchiveIcon className={classes.iconButton}/>}
                                {archived ? "Unarchive" : "Archive"}
                            </Button>      
                        }
                    />   
                </ButtonGroup>  
            </Collapse>

        </>
    );
}

export default SecureMessagingToolbar;