import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { fbRemoteConfig, fbAnalytics } from "../firebase";


const versionString = 'PORTAL_VERSION';
const VersionCheck = () => {
    const cookies = new Cookies();

        
    useEffect(() => {
        const userVersion = cookies.get(versionString);
        fbRemoteConfig.fetchAndActivate()
        .then(() => {
            const currentVersion = fbRemoteConfig.getString(versionString);
            console.log(`Running on version: ${userVersion}. Remote config version: ${currentVersion}`);
            if(userVersion !== currentVersion) {
                console.log(`Updating to version ${currentVersion}`);
                cookies.set(versionString, currentVersion);
            }
            fbAnalytics.setUserProperties({portalVersion: `${currentVersion}`});
            
        })
        .catch((error: Error) => {
            console.error(error);
        })

    }, []);

    return (
        <>
        </>
    );

}

export default VersionCheck;