import React, { useContext, } from "react";
import {  ActivityUser } from "../../../../../shared/types";

import EconsultInfo from "./EconsultInfo";
import EconsultProfile from "./EconsultProfile";
import EconsultPatient from "./EconsultPatient";
import EconsultCard from "./EconsultCard";

import MessagingGallery from "../../Gallery/MessagingGallery";

import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { formatTimeLong } from "../../../../models/formatTime";
import { EconsultContext } from "./EconsultProvider";
import EconsultOutcomesLabel from "./EconsultOutcomesLabel";

interface EconsultViewProps {
    econsultId?: string,//To check if equal when updating component
    otherUser: ActivityUser
}

const EconsultView = ({ otherUser }: EconsultViewProps) => {
    const econsult = useContext(EconsultContext).econsult;

    return (
        <>
            <Grid container
                spacing={1}
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Typography>
                        {`${econsult.specialty !== '' ? `${econsult.specialty} - ${econsult.subsite} Referral` : "Loading..."}`}
                    </Typography>
                    <EconsultOutcomesLabel specialistResponse={econsult.specialistResponse}/>
                    <Typography variant="subtitle1">
                        {`${econsult.created ? `Sent at ${formatTimeLong(econsult.created.toDate())}` : ""}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <EconsultPatient econsult={econsult}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <EconsultProfile otherUser={otherUser} econsult={econsult}/>
                </Grid>
                <EconsultInfo econsult={econsult}/>
                {econsult.referralMediaURIs.length > 0 && (
                    <Grid item xs={12}>
                        <EconsultCard title="Attachments">
                            <MessagingGallery referralMediaURIs={econsult.referralMediaURIs} econsultImageLabels={econsult.imageLabels}/>
                        </EconsultCard>
                    </Grid>
                )}
            </Grid>

        </>
    );
}

function isUserEqual(prevUser: ActivityUser, nextUser: ActivityUser) {
    return prevUser.firstName === nextUser.firstName && prevUser.lastName === nextUser.lastName && prevUser.email === nextUser.email;
}

//Only update this component if the econsult id changes
function areEqual(prevProps: Readonly<EconsultViewProps>, nextProps: Readonly<EconsultViewProps>): boolean {
    return prevProps.econsultId === nextProps.econsultId && isUserEqual(prevProps.otherUser as ActivityUser, nextProps.otherUser as ActivityUser);
}

export default React.memo(EconsultView, areEqual);