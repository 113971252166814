import React from "react";
import { useWatch, Control } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
    return {
        delayCheckbox: {
            marginLeft: "4px",
        }
    }  
});
    
interface DelayAllCheckboxProps {
    control: Control,
    setValue: (name: string, value: any) => void
}

export const DelayAllCheckbox = ({control, setValue}: DelayAllCheckboxProps) => {
    const classes = useStyles();
    const patients = useWatch({control, name: "patients", defaultValue: []});

    const checkDelayAll = (): boolean => {
        return patients !== undefined && patients.length > 0 && patients.every((patient: any) => {
            return patient.delay;
        })
    }

    return (
        <div className={classes.delayCheckbox}>
            <FormControlLabel
                label="Delay All"
                checked={checkDelayAll()}
                onChange={(event: any) => {
                    patients.forEach((_: any, index: number) => {
                        setValue(`patients.${index}.delay`, event.target.checked);
                    });
                }}
                control={
                    <Checkbox 
                        color="primary"
                        disableRipple  
                    />
                }
            />
        </div>
    );
}

export default DelayAllCheckbox;