import { fbStorage } from "../firebase";


export interface FileRef extends firebase.default.storage.Reference {

}

export type BinnedImages = {
    [timestamp: string]: FileRef[]
}

export const fetchFiles = (folder: string, uid?: string): Promise<FileRef[]> => {
    if(uid === undefined) {
        return Promise.resolve([]);
    }

    const storageRef = fbStorage.ref(`${folder}/${uid}`)

    return storageRef.listAll()
    .then((result) => {
        const files = result.items;
    
        return files.reverse();
    })
}

export const fetchAndBinFiles = (folder: string, uid?: string): Promise<BinnedImages> => {
    return fetchFiles(folder, uid)
    .then((files: FileRef[]) => {
        const binnedFiles: BinnedImages = {};
        //Images are fetched in order starting from oldest to newest (since their filename is the timestamp)
        //Need to reverse the order
        files.forEach((file: FileRef) => {
            //Bin each image based on the day it was taken
            try {
                //Find the day
                let unixTimestamp = parseInt(file.name.split('.')[0]);
                let timestamp = new Date(unixTimestamp);
                timestamp.setHours(0);
                timestamp.setMinutes(0);
                timestamp.setSeconds(0);
                timestamp.setMilliseconds(0);
                
                //If we have a bin for that day, add the image to that bin, otherwise create a new bin.
                if(binnedFiles[timestamp.getTime()]) {
                    binnedFiles[timestamp.getTime()].push(file);
                }
                else {
                    binnedFiles[timestamp.getTime()] = [file];
                }
            }
            catch {
                console.log("Error converting image name into timestamp");
            }
        });
        return Promise.resolve(binnedFiles);
    })
}

