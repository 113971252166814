import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter} from "react-router-dom";
import List from '@material-ui/core/List';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { fbFirestore, logAnalyticsEvent } from "../../../firebase";
import firebase from "firebase";

import { PatientMessage } from './types';


import { AuthContext } from '../../../AuthProvider';

import PatientMessageListItem from './PatientMessageListItem';
import Toolbar from "./PatientMessageToolbar";
import ViewMessage from "./ViewMessage";


import PaperPage from '../../../components/PaperPage';

import { thinScrollbar } from '../../../styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SearchBar, SearchOption } from '../../../components/SearchBar';
import { PatientMessageFolder } from '../../../../shared/types';
import SnackbarMessage from '../../../components/SnackbarMessage';
import useUndoMoveFolder from './useUndoMoveFolder';

import useProcessState from "../../../components/useProcessState";
import { ProcessStatus } from "@alethea-medical/react-components";
import useSizeManager from '../../../components/useSizeManager';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        listBox: {
            padding: 0,
            overflowY: "scroll"
        },
        list: {
            padding: 0
        },
        header: {
            padding: 0
        },
        listItem: {
            padding: 0,
        },
        hidden: {
			display: "none"
		},
        toolbar: {
            paddingLeft: theme.spacing(1)
        }
    }),
);

export interface PatientMessageDict {
	[messageId: string]: PatientMessage
}

const searchOptions: SearchOption[] = [
    {field: "subType", display: "Message Type", options: [{value: "Forms", display: "Form"}, {value: "OneWayPatient", display: "One-Way Message"}, {value: "PatientCC", display: "Patient CC"}, {value: "MOAMessage", display: "MOA Message"}]},
	{field: "patientInfo.phn", display: "PHN"}, 
	{field: "patientInfo.email", display: "Patient Email"},
	{field: "subject", display: "Subject"},
]


const PatientMessages = withRouter(({ location }) => {
    const classes = useStyles();


    const authContext = useContext(AuthContext);

    const loadMoreAmount = 20;
    const history = useHistory();
    
    const [messages, setMessages] = useState<PatientMessageDict>({});
    const [newMessageQueue, setNewMessageQueue] = useState<PatientMessage[]>([]);
    const [openMessageId, setOpenMessageId] = useState<string | undefined>(undefined);
    const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [oldestMessageTime, setOldestMessageTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
    const [folder, setFolder] = useState<PatientMessageFolder>("inbox");

    const {processState, setProcessState, processErrorMessage, setProcessErrorMessage, errorHandler } = useProcessState();

    const [enableSearch, setEnableSearch] = useState<boolean>(false);
    const [messageFilter, setMessageFilter] = useState<((message: PatientMessage) => boolean) | undefined>(undefined);

	const { sizeRef: paperSizeRef, height: paperHeight} = useSizeManager();
	const { sizeRef: headerSizeRef, updateSize: updateHeaderHeight, height: headerHeight } = useSizeManager();

    const loadMessages = (fetchLessThan: firebase.firestore.Timestamp) => {
        fbFirestore.collection("patient_messages").where("physicianUid", "==", authContext.uid).where("folder", "==", folder).where("statusUpdatedAt", "<", fetchLessThan)
        .orderBy("statusUpdatedAt", "desc").limit(loadMoreAmount).get()
        .then(newMessagesHandler)
        .catch((error: Error) => {			
			errorHandler({
                error: error, 
                userMessage: "Error loading patient messages"
            });
		})
    }
        
	const loadMoreHandler = () => {
        logAnalyticsEvent("patient_message_load_more");

		loadMessages(oldestMessageTime);
	}

    const newMessagesHandler = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        if(snapshot.size > 0) {
			console.log(`Retrieved ${snapshot.size} messages`);
            const newMessages = snapshot.docs.filter((doc) => {
                return doc.exists;
            }).map((doc) => {
                return Object.assign(doc.data(), { id: doc.id }) as PatientMessage;
            })
            setNewMessageQueue(newMessages);
        }
	}

    const openMessageHandler = (id: string) => {
        logAnalyticsEvent("patient_message_select");
        setOpenMessageId(id);
        markAsRead(id);
        history.push(location.pathname);//Push current path so when back is pressed, user stays on current page
    }

    const goBackHandler = () => {
        setOpenMessageId(undefined);
    }

    const tabChangeHandler = (_: any, value: PatientMessageFolder) => {
        if(value !== folder) {
            setFolder(value);
            resetMessages();
        }
	}

    const runSearch = (filter: (message: PatientMessage) => boolean) => {
        logAnalyticsEvent("patient_message_search");
        setEnableSearch(true);
        setMessageFilter(() => filter);
    }

    const clearSearch = () => {
        setEnableSearch(false);
        setMessageFilter(undefined);
    }

    const resetMessages = () => {
        setMessages({});
        setSelectedMessages([]);
        setOldestMessageTime(firebase.firestore.Timestamp.now());
    }
    
    const markAsRead = (messageId: string) => {
        if(!messages[messageId].read) {
            const newMessages = {...messages};
            fbFirestore.collection("patient_messages").doc(messageId).update({read: true})
            .then(() => {
                newMessages[messageId].read = true;
                setMessages(newMessages);            
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error
                });
            })
        }
    }

    const messageSelectedHandler = (id: string, checked: boolean) => {
        if(checked) {
            if(!selectedMessages.includes(id)) {
                const newSelectedMessages = [...selectedMessages];
                newSelectedMessages.push(id);
                setSelectedMessages(newSelectedMessages);
            }
        }
        else {
            const idxToRemove = selectedMessages.indexOf(id);
            if(idxToRemove !== -1) {
                const newSelectedMessages = [...selectedMessages];
                newSelectedMessages.splice(idxToRemove, 1);
                setSelectedMessages(newSelectedMessages);
            }
        }
    }

    const selectAllHandler = (selectAll: boolean) => {
        if(selectAll) {
            const newSelectedMessages = [...selectedMessages];
            Object.keys(messages).forEach((id) => {
                if(!newSelectedMessages.includes(id)) {
                    newSelectedMessages.push(id);
                }
            })
            setSelectedMessages(newSelectedMessages);
        }
        else {
            setSelectedMessages([]);
        }
    }


    const handleRefresh = () => {
        resetMessages();
        loadMessages(firebase.firestore.Timestamp.now());
    }

    const onUndoMoveFolder = (prevFolder: PatientMessageFolder, changedIds: string[]) => {
        return Promise.all(changedIds.map((id) => {
            return fbFirestore.collection("patient_messages").doc(id).update({folder: prevFolder})
        }))
        .then(() => {
            setShowUndoSnackbar(false);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error undoing the previous action"
            });
        })
    }

    const { undo, setUndoIds, setPrevFolder } = useUndoMoveFolder(messages, setMessages, onUndoMoveFolder);
    const [ showUndoSnackbar, setShowUndoSnackbar ] = useState<boolean>(false);
    const [ undoSnackbarAction, setUndoSnackbarAction ] = useState<string>("");
    const [ showUnreadSnackbar, setShowUnreadSnackbar] = useState<boolean>(false);


    const markAsUnreadHandler = (messageIds: string[]) => {
        const newMessages = {...messages};
        return Promise.all(messageIds.map((id) => {
            return fbFirestore.collection("patient_messages").doc(id).update({read: false})
            .then(() => {
                newMessages[id].read = false;
            })
        }))
        .then(() => {
            logAnalyticsEvent("patient_message_mark_unread");
            setShowUnreadSnackbar(true);
            setOpenMessageId(undefined);
            setMessages(newMessages);            
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error marking message as unread"
            });
        })
    }



    const moveFolderHandler = (messageIds: string[], newFolder: PatientMessageFolder) => {
        const newMessages = {...messages};
        const newUndoIds: string[] = [];
        return Promise.all(messageIds.map((id) => {
            return fbFirestore.collection("patient_messages").doc(id).update({folder: newFolder})
            .then(() => {
                newUndoIds.push(id);
                delete newMessages[id];
            })
        }))
        .then(() => {
            logAnalyticsEvent(`patient_message_move_to_${newFolder}`);

            setUndoSnackbarAction(newFolder)
            setUndoIds(newUndoIds);
            setPrevFolder(folder);
            setSelectedMessages([]);
            setShowUndoSnackbar(true);
            setOpenMessageId(undefined);
            setMessages(newMessages);            
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: `Error moving messages to ${newFolder}`
            });
        })
    }


    useEffect(() => {
        loadMessages(firebase.firestore.Timestamp.now());
    }, [folder])

    useEffect(() => {
        return history.listen(() => {
            if(history.action === 'POP') {
                setOpenMessageId(undefined);//Go back to message list instead of previous route
            }
        })
    }, [history])

    	//Run when new activities are put into newActivityQueue
	useEffect(() => {
		//Put the update logic into a separate function from the onSnapshot function so that it contains
		//all updated values of activityIndex and activities
		const newMessages = {...messages};
		newMessageQueue.forEach((newMessage) => {
		    if(newMessage.created < oldestMessageTime) {
				setOldestMessageTime(newMessage.created);
			}
			newMessages[newMessage.id] = newMessage;
		})		
		setMessages(newMessages);
		
	}, [newMessageQueue])

    useEffect(() => {        
        if(selectedMessages.length > 0) {
            setAllSelected(Object.keys(messages).every((id) => {
                return selectedMessages.includes(id);
            }));
        }
        else {
            setAllSelected(false);
        }
    }, [selectedMessages])

    return (
        <>
            <div ref={paperSizeRef}>
                <PaperPage lockHeight>
                    <div className={openMessageId === undefined ? "" : classes.hidden}>
                        <List className={classes.header}>
                            <div ref={headerSizeRef}>
                                <ListItem divider>
                                    <Typography variant="h5">
                                        Patient Messages
                                    </Typography>
                                    </ListItem>
								<ListItem divider>
									{/* dynamically change orientation */}
									<Tabs value={folder} onChange={tabChangeHandler}
										variant="fullWidth"
									>
										<Tab value={"inbox"} label="Inbox"/>
                                        <Tab value={"sent"} label="Sent"/>
										<Tab value={"archive"} label="Archive"/>
									</Tabs>
								</ListItem>
                                <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>
                                <ListItem divider>
                                    <SearchBar enableSearch={enableSearch} searchOptions={searchOptions}
                                        runSearch={runSearch} clearSearch={clearSearch}
                                        onSizeChanged={updateHeaderHeight}
                                    />
                                </ListItem>
                                <ListItem className={classes.toolbar} divider>
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                        <Grid item>
                                            <Checkbox checked={allSelected} onChange={(e) => { selectAllHandler(e.target.checked) }}/>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={handleRefresh}><RefreshIcon/></IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Toolbar 
                                                folder={folder}
                                                atLeastOneSelected={selectedMessages.length > 0}
                                                markAsUnread={() => {markAsUnreadHandler(selectedMessages)}} 
                                                moveFolder={(folder: PatientMessageFolder) => {moveFolderHandler(selectedMessages, folder)}} 
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        </List>

         
    
                        {/* <Divider/> */}
                        <Box 
                            className={`${classes.listBox} ${classes.thinScrollbar}`}
                            height={paperHeight - headerHeight}
                        >
                            <List className={classes.list}>
                                {Object.values(messages).sort((a, b) => {
                                    if(a.statusUpdatedAt < b.statusUpdatedAt)
                                        return 1;
                                    else if(a.statusUpdatedAt > b.statusUpdatedAt)
                                        return -1;
                                    else
                                        return 0;
                                }).map((m, index) => {
                                    const jsx = <PatientMessageListItem 
                                                    key={`message_${m.subject}_${index}`} 
                                                    message={m} 
                                                    selected={selectedMessages.includes(m.id)}
                                                    setSelected={messageSelectedHandler}
                                                    openMessage={openMessageHandler}
                                                />
                                    if(messageFilter !== undefined) {
                                        if(messageFilter(m))
                                            return jsx;
                                        return <></>
                                    }
                                    else {
                                        return jsx
                                    }
                                })}	
                                <ListItem
                                    button
                                    onClick={loadMoreHandler}
                                    alignItems="center"
                                    divider
                                >
                                    <ListItemIcon>
                                        <ArrowDownwardIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={enableSearch ? "Load More Search Results" : "Load More"}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </div>
                    {openMessageId !== undefined && messages[openMessageId] !== undefined && (
                        <ViewMessage 
                            message={messages[openMessageId]} 
                            paperHeight={paperHeight} 
                            goBackHandler={goBackHandler}
                            markAsUnread={() => {markAsUnreadHandler([openMessageId])}} 
                            moveFolder={(folder) => {moveFolderHandler([openMessageId], folder)}} 
                        />
                    )}

                </PaperPage>
            </div>
            <SnackbarMessage message={`Moved to ${undoSnackbarAction}`} show={showUndoSnackbar} setShow={setShowUndoSnackbar} useButton buttonText="UNDO" onClick={undo}/>
            <SnackbarMessage message={`Marked as unread`} show={showUnreadSnackbar} setShow={setShowUnreadSnackbar}/>
        </>
    );
})

export default PatientMessages;