import React from "react";
import { Control, useWatch } from "react-hook-form";
import { Col, Form, Card } from "react-bootstrap";
import FormTextField from "../../../components/FormTextField";
import PhoneInput from "../../../components/PhoneInput";
import InputLabel from "@material-ui/core/InputLabel"


interface ProfileLocationsProps {
    control: Control
}

const ProfileLocations = ({control }: ProfileLocationsProps) => {
    const locations = useWatch({control, name: "locations", defaultValue: []});

    return (
        <>
            <InputLabel>Your Clinics</InputLabel>
            <div className="location-list">
                {locations.map((_: any, index: number) => 
                    <Form.Row key={`location_${index}`}>
                        <Form.Group as={Col} md="12">
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextField 
                                                name={`locations.${index}.clinicName`}
                                                control={control}
                                                label="Clinic Name"
                                                rules={{required: {value: true, message: "Clinic name is required"}}}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <PhoneInput
                                                name={`locations.${index}.clinicPhone`}
                                                control={control}
                                                label="Clinic Phone Number"
                                                rules={{required: {value: true, message: "Clinic phone number is required"}}}
                                            />
                                        </Form.Group>                     
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <PhoneInput
                                                name={`locations.${index}.fax`}
                                                control={control}
                                                label="Fax Number"
                                                rules={{required: {value: true, message: "Fax number is required"}}}
                                            />
                                        </Form.Group>      
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextField 
                                                name={`locations.${index}.city`}
                                                control={control}
                                                label="City"
                                                disabled
                                            />
                                        </Form.Group>      
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextField 
                                                name={`locations.${index}.province`}
                                                control={control}
                                                label="Province"
                                                disabled
                                            />
                                        </Form.Group>      
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Form.Row>
                )}
            </div>
        </>
        
    );
}

export default ProfileLocations;