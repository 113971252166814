import React from "react";
import { Controller, Control } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import { Col, Form } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";

interface ReferralRadioButtonProps {
    name: string,
    control: Control,
    row: string,
    columns: string[]
}

const ReferralRadioButton = ({ name, control, row, columns}: ReferralRadioButtonProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={""}
            render={({field}) => {
                return (
                    <>
                        <Form.Group as={Col} md="6">
                            <InputLabel className="mt-3">{row}</InputLabel>
                        </Form.Group>
                        {columns.map((colName: string) => {
                            return (
                                <Form.Group as={Col} md="3" key={`${name}_${colName}`}>
                                    <Checkbox 
                                        color="primary"
                                        {...field}
                                        disableRipple  
                                        onClick={() => {
                                            if(field.value !== colName)//Toggle from not checked to checked
                                                field.onChange(colName);
                                            else//Toggle from checked to not checked
                                                field.onChange("");
                                        }}
                                        onChange={() => {}}//Override default onChange behaviour from field
                                        checked={field.value === colName}
                                    />
                                </Form.Group>
                            );

                        })}
                    </>
                );
            }}
        />
    );
}

export default ReferralRadioButton;