import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import  { ControlledInputProps } from "../shared/types";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>{
    const shared = sharedStyles(theme);
    return {
        ...shared
    }
});

interface FormTextFieldProps extends ControlledInputProps {
    rules?: any,
    disabled?: boolean
}

const FormTextField = ( props: FormTextFieldProps) => {
    const { name, control, defaultValue, rules, label, disabled, ...rest } = props;
    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={rules}
            render={({field, fieldState}) => 
                <TextField 
                    {...field}
                    {...rest}
                    disabled={disabled}
                    className={classes.canDisable}
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        disableAnimation: true
                    }}
                />
            }
        />
    );
}

export default FormTextField

