export function formatTimeShort (date: Date): string {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    if(date.getTime() >= today.getTime()) {
        return `${date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`
    }
    else {
        return `${date.toLocaleString('en-US', { month: 'short', day: 'numeric'})}`
    }
}

export function formatTimeLong(date: Date) {
    return `${date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric'})} on ${date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})}`
}
