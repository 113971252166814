import React from "react";
import { Controller, Control, useWatch } from "react-hook-form";
import { ProcessStatus, ProcessState }  from '../../Global/components/processStatus';
import { Col, Form } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";

import FormCheckbox from "../../../components/FormCheckbox";
import EmailInput from "../../../components/EmailInput";
import PHNInputLookup from "../../../components/PHNInputLookup";


import RemovePatientButton from "./RemovePatientButton";


import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
    return {
        delayCheckbox: {
            marginTop: "5px",
            marginLeft: "10px",
        },
        emailStatus: {
            marginTop: "12px"
        }
    }  
    
});

interface PatientRowProps {
    control: Control,
    index: number,
    setValue: (name: string, value: any, options?: any) => void,
    remove: (index?: number | number[] | undefined) => void,
    disabled: boolean
}

const PatientRow = ({control, index, setValue, remove, disabled}: PatientRowProps) =>  {
    const classes = useStyles();

    return (
        <Form.Row>
            <Form.Group as={Col} md="4">
                <PHNInputLookup 
                    name={`patients.${index}.patientInfo`}
                    control={control}
                    label="PHN"
                    required
                    setValue={(patientInfo) => {
                        setValue(`patients.${index}.email`, patientInfo.email, {shouldValidate: true} );
                    }}
                    skipServerWarmup={index !== 0}
                    disabled={disabled}
                />
            </Form.Group>

            <Form.Group as={Col} md="4">
                <EmailInput  
                    name={`patients.${index}.email`}
                    control={control}
                    label="Patient Email"
                    rules={{required: {value: true, message: "Email is required"}}}
                />
            </Form.Group>

            {/* Delay email checkbox */}
            <Tooltip title="Delay sending the email until the next day at 7:00 AM MST"
            placement="right">
                {/* //Wrap in fragment to stop error about refs from being thrown */}
                <div className={classes.delayCheckbox}>
                    <FormCheckbox
                        name={`patients.${index}.delay`}
                        control={control}
                        label="Delay Sending"
                    />
                </div>
            </Tooltip>
            {/* Remove row button */}
            <Form.Group as={Col} md="auto" controlId={`${index}_remove`}>
                <RemovePatientButton
                    index={index}
                    remove={remove}
                    disabled={disabled}
                />
            </Form.Group>
        </Form.Row>
    );
}

export default PatientRow