import React, { useContext, useEffect, useState } from "react";
import { fbFirestore, logAnalyticsEvent, fbStorage } from "../../../firebase";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AuthContext } from "../../../AuthProvider";
import { Econsult, Message, RecentMessage} from "../../../../shared/types";
import { aletheaMDCrypto, getDocumentData } from "@alethea-medical/utilities";
import firebase from "firebase";
import { Activity } from "./types";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import LeavePagePrompt from "../../../components/LeavePagePrompt";

import useProcessState from "../../../components/useProcessState";
import { InputContext } from "./InputProvider";
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";
import useFileDrop from '../../../components/useFileDrop';
import { generateRandomString } from "@alethea-medical/utilities";
import { FileDropReturnTypes } from "../../../components/useFileDrop/useFileDrop";
import PaperModal from "../../../components/PaperModal";
import useGallery from "../../../components/useGallery";
import useSizeManager from "../../../components/useSizeManager";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        messageInputContainer: {
            height: "10%",
        },
        messageInputBox: {
            [`& fieldset`]: {
                borderRadius: "15px",
            }
        },
        modalContainer: {
            padding: theme.spacing(1),
            overflowY: "auto",
            width: "100%",
            height: "100%"
        },
        galleryContainer: {
            padding: theme.spacing(1),
            height: "75vh",
            overflowY: "auto",
            overflowX: "hidden"
        },
        iconButton: {
            marginRight: theme.spacing(1)
        }
    }),
);

interface MessageInputProps {
    activity: Activity
    updateSize: () => void,
}

const MessageInput = ({ activity, updateSize }: MessageInputProps) => {
    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const inputContext = useContext(InputContext);

    const {processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();

    const { sizeRef: modalSizeRef, height: modalHeight, updateSize: updateModalSize } = useSizeManager();

    const { files, removeFiles, createFileList, createFileThumbs, createDropzone }: FileDropReturnTypes = useFileDrop({});
    const { selectedFiles, renderGallery, unselectAll, unselectFiles, refresh, loadMore, createSelectedFileThumbs, createModal } = useGallery({});



    const [showUploadModal, setShowUploadModal] = useState(false);
    


    
    const onSendPressed = () => {
        logAnalyticsEvent("secure_message_send_start");
        inputContext.setValue("");

        return sendMessage(inputContext.value)
        .then((newMessage) => {
            //If user typed a message, set that as recent message
            return updateRecentMessage(newMessage)
        })
        .then(() => {
            logAnalyticsEvent("secure_message_send_success");
            inputContext.onMessageSent();
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error sending message. Please try again", 
                analyticsLog: "secure_message_send_failed"
            });
        })   
    }

    const uploadFileHandler = () => {
        setProcessState(ProcessState.running);
        logAnalyticsEvent("secure_message_upload_files_start");

        const numToUpload = Object.keys(files).length + Object.keys(selectedFiles).length;
        
        const uploadURIs: string[] = [];
        const uploadFilenames: string[] = []
        const successFileUploadKeys: string[] = []
        const successGalleryUploadKeys: string[] = []
        
        
        Promise.all(Object.keys(files).map((key) => {
            const file = files[key];
            return file.file.arrayBuffer()
            .then((fileContents) => {
                return fbStorage.ref(`activityMedia/${generateRandomString(16)}_${file.file.name}`).put(fileContents, {contentType: file.file.type})  
            })
            .then((res) => {
                uploadURIs.push(res.ref.fullPath);
                uploadFilenames.push(file.filename);
                successFileUploadKeys.push(key);
            })
            .catch((error: Error) => {
                console.error(error);
            })
        }))
        .then(() => {
            return Promise.all(Object.keys(selectedFiles).map((key) => {
                const selectedFile = selectedFiles[key];
                return fetch(selectedFile.src).then(r => r.blob())
                .then((blob) => {
                    return fbStorage.ref(`activityMedia/${generateRandomString(16)}_${selectedFile.filename}`).put(blob, {contentType: blob.type})  
                })
                .then((res) => {
                    uploadURIs.push(res.ref.fullPath);
                    uploadFilenames.push(selectedFile.filename);
                    successGalleryUploadKeys.push(key);
                })
                .catch((error: Error) => {
                    console.error(error);
                })
            }))       
        })
        .then(() => {

            return activity.econsult.get().then(getDocumentData)
            .then((econsult: Econsult) => {
                const mediaURIs = econsult.referralMediaURIs;
                uploadURIs.forEach((uri) => { mediaURIs.push(uri) });
                return activity.econsult.update({
                    referralMediaURIs: mediaURIs
                })
            })     
        })
        .then(() => {
            return sendMessage(`Uploaded:\n${uploadFilenames.join('\n')}`)
        })
        .then((newMessage) => {
            //If user typed a message, set that as recent message
            return updateRecentMessage(newMessage)
        })
        .then(() => {
            console.log(files);
            removeFiles(successFileUploadKeys);
            unselectFiles(successGalleryUploadKeys);
            const numUploaded = successFileUploadKeys.length + successGalleryUploadKeys.length
            if(numUploaded !== numToUpload) {
                const errorMessage = `${numToUpload - numUploaded} out of ${numToUpload} files failed to upload. Please try uploading these files again.`
                errorHandler({
                    error: new Error(errorMessage), 
                    userMessage: errorMessage, 
                    analyticsLog: "secure_message_upload_files_failed", 
                    hideErrorMessage: true
                });
            }
            else {
                logAnalyticsEvent("secure_message_upload_files_success");
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 2000)
            }    
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error uploading files. Please try again", 
                analyticsLog: "secure_message_upload_files_failed"
            });
        })
    }

    
    const sendMessage = (messageString: string) => {

        const timestamp = firebase.firestore.Timestamp.now();
        const newMessage: Message = {
            sentBy: authContext.uid,
            sentAt: timestamp,
            message: messageString
        }

        //Create the message
        return aletheaMDCrypto.encryptDecryptMessages([newMessage], fbFirestore.collection("system").doc("keystore").collection("keys").doc("firestoreData"), {encrypt: true})
        .then(() => {
            return fbFirestore.collection("activities").doc(activity.id).collection("messages").add(newMessage)
        })
        .then(() => {
            return newMessage;
        })
    }

    const updateRecentMessage = (newMessage: Message) => {

        //Update the recent message object in the activity
        const recentMessage: RecentMessage = {
            message: newMessage.message,//Encrypted
            sentAt: newMessage.sentAt,
            sentBy: newMessage.sentBy,
            readBy: [authContext.uid]
        }
        const inInbox = [];
        const inArchive = [];
        
        //Put all other users in inbox
        activity.users.forEach((uid) => {
            if(uid === authContext.uid)
                return;
            inInbox.push(uid);
        })

        //Put current user where it originally was.
        if(activity.inInbox.includes(authContext.uid))
            inInbox.push(authContext.uid);
        if(activity.inArchive.includes(authContext.uid))
            inArchive.push(authContext.uid);
        
        return fbFirestore.collection("activities").doc(activity.id).update({recentMessage: recentMessage, inInbox: inInbox, inArchive: inArchive});
    }

    const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if(e.key === "Enter" && e.ctrlKey) {
            onSendPressed();
        }
    }
    
    const isSendDisabled = () => {
        return (inputContext.value.length === 0 && Object.keys(files).length === 0) || processState === ProcessState.running;
    }
    const isTextDisabled = () => {
        return processState === ProcessState.running;
    }

    useEffect(() => {
        updateSize();
    }, [inputContext.value])

    return (
        <>
            <div className={classes.root}>
                <Grid container alignItems={"center"} className={classes.messageInputContainer}>
                    <Grid item xs={1}>
                        <IconButton onClick={() => {setShowUploadModal(true)}}>
                            <AttachFileIcon color="primary"/>
                        </IconButton>
                    </Grid>
                    
                    <Grid item xs={11}>
                        <TextField
                            className={classes.messageInputBox}
                            value={inputContext.value}
                            onChange={(e) => inputContext.setValue(e.target.value)}
                            onKeyDown={keydownHandler}
                            variant="outlined"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Type a message"
                            disabled={isTextDisabled()}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Ctrl + Enter to send"
                                            children={
                                                <span>
                                                    <Button color="primary" disabled={inputContext.value.length === 0 || isSendDisabled()} onClick={onSendPressed}><SendIcon/></Button>
                                                </span>
                                            }
                                        />
                                    </InputAdornment>  
                            }}
                        />
                    </Grid>

                </Grid>
            </div>

            <PaperModal show={showUploadModal} setShow={setShowUploadModal} sizeRef={modalSizeRef} updateSize={updateModalSize}>
                <Box height={modalHeight}>
                    <Grid container justifyContent="flex-start" spacing={2} className={classes.modalContainer}>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        Upload from your Computer
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {createDropzone("Click Here or Drag and Drop to Upload")}
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                From your Computer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {createFileList()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {createFileThumbs()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                From your Gallery
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {createSelectedFileThumbs()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" disabled={Object.keys(files).length + Object.keys(selectedFiles).length === 0}
                                        onClick={uploadFileHandler}><AttachFileIcon className={classes.iconButton}/>Upload Files</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} successMessage="Files Uploaded Successfully."/>      
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-start" spacing={2}>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Upload from your Gallery
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box 
                                        className={classes.galleryContainer}
                                    >
                                        {renderGallery()}
                                    </Box> 
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => {loadMore(7)}} variant="outlined" color="primary">Load More</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => {refresh()}} variant="outlined" color="primary">Refresh</Button>
                                </Grid>
                                <Grid item>
                                    {Object.keys(selectedFiles).length > 0 && ( 
                                        <Button onClick={() => {unselectAll()}} variant="outlined" color="primary">Unselect All</Button>    
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

            </PaperModal>
            {createModal()}
            <LeavePagePrompt isDirty={inputContext.value.length > 0}/>
        </>
    );
}

export default MessageInput;