import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Maintenance from "../../Pages/Maintenance";
// import Signup from "../../Pages/Signup";
// import { Login } from "../components";

export const AuthRoutes = () => {

    useEffect(() => {
        //Forward to alethea-medical if aletheamd.com sends back to entid-9c711
        window.location.href = "http://alethea-medical.web.app"
    }, [])

    return (    
        <Switch>
            <Route path="/" component={Maintenance}/>
            {/* <Route path="/login" component={Login} /> */}
            {/* <Route path="/signup" component={Signup}/> */}

            {/* Redirect from old links */}
            {/* <Redirect to="/login" from="/auth/login" /> */}
            {/* <Redirect to="/signup" from="/auth/signup" /> */}

            {/* Redirect to login for any other invalid link */}
            {/* <Redirect to="/login" from="/" /> */}
        </Switch>
    );
};