import React, { useEffect, useState } from "react";
import {fbAuth, fbAnalytics} from "./firebase";
import { UserProfile } from "../shared/types";
import getProfile from "./models/getProfile";
import firebase from "firebase";

interface ContextProps {
    user?: firebase.User,
    uid: string,
    profile?: UserProfile,
    authenticated: boolean,
    setUser: (user: firebase.User) => void,
};

export const AuthContext = React.createContext<ContextProps>({
    uid: "",
    authenticated: false,
    setUser: (user: firebase.User) => {console.log("setUser is not yet initialized")}
});

export const AuthProvider = (props: any) => {
    const [user, setUser] = useState<firebase.User>();
    const [uid, setUid] = useState<string>("");
    const [profile, setProfile] = useState<UserProfile>();

    useEffect(() => {
        fbAuth.onAuthStateChanged((newUser: firebase.User | null) => {
            if(newUser !== null)
            {
                console.log("Logged in");

                //Make sure uid is set before settings user, so it is available when authenticated is set to true (when user is not undefined)
                setUid(newUser.uid);
                setUser(newUser);
                fbAnalytics.setUserId(newUser.uid);

                getProfile(newUser.uid)
                .then((result: UserProfile | undefined) => {
                    if(result !== undefined){
                        
                        fbAnalytics.setUserProperties({name: `${result?.firstName} ${result?.lastName}`});
                        setProfile(result);
                    }           
                })
                
            }
            else {
                console.log("Logged out");
                setUser(undefined);
                setProfile(undefined);
                console.log("Cleared profile");
            }
        });
    }, []);



    return (
        <AuthContext.Provider 
            value={{
                user,
                profile,
                uid,
                authenticated: user !== undefined,
                setUser,
            }}>
                {props.children}
        </AuthContext.Provider>
    );
}