import React from 'react';
import {Route, Switch } from "react-router-dom";
import LinkAccount from './LinkAccount';

const AvaRoutes = () => {

    return (
        <Switch>
            <Route path="/ava/link-account" component={LinkAccount} />
        </Switch>
    );
}

export default AvaRoutes;