import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { thinScrollbar } from '../../styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { logAnalyticsEvent } from '../../firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        container: {
            position: "relative"
        },
        viewer: {
            overflowY: "scroll",
        },
        border: {
            borderColor: theme.palette.grey[300],
        },
        buttonFloat: {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10
        },
        loadingFloat: {
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 10
        },
        alert: {
            margin: theme.spacing(1)
        },
        noData: {
            margin: theme.spacing(3)
        }
    }),
);

interface PDFViewerProps {
    pdfURL?: string,
    noDataText?: string | React.ReactChild,
    height?: number,
    width?: number
    disableAnalytics?: boolean
    initialZoomMultiplier?: number
}


const PDFViewer = ({ pdfURL, noDataText, height, width, disableAnalytics = false, initialZoomMultiplier = 1.0}: PDFViewerProps) => {
    const classes = useStyles();

    const [numPages, setNumPages] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(initialZoomMultiplier);//Mult by width
    

    const loadSuccessHandler = (pdf: any) => {
        setNumPages(pdf.numPages);
    }

    const pdfOpened = () => {

        if (!disableAnalytics){
            logAnalyticsEvent("message_pdf_open");}
    }


    return (
        <>
            <div className={classes.container}>

                {(pdfURL !== undefined) &&
                    <div className={classes.buttonFloat}>
                        <Grid container direction="column">
                            <Grid item>
                                <a href={pdfURL} target="_blank" rel="noopener noreferrer">
                                    <IconButton onClick={pdfOpened}><OpenInNewIcon color="primary"/></IconButton>
                                </a>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {setZoomLevel(Math.max(zoomLevel - 0.25, 0.25))}}><ZoomOutIcon color="primary"/></IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {setZoomLevel(zoomLevel + 0.25)}}><ZoomInIcon color="primary"/></IconButton>
                            </Grid>
                        </Grid>
                    </div>
                }

                <Box 
                    className={`${classes.viewer} ${classes.thinScrollbar} ${classes.border}`}
                    border={1}
                    height={height}

                >
                    <Document 
                        file={pdfURL}
                        noData={
                            <div className={classes.noData}>
                                {noDataText ? noDataText : ""}
                            </div>}
                        onLoadSuccess={loadSuccessHandler}
                        loading={<LinearProgress/>}
                        error={<MuiAlert className={classes.alert} severity="error">Error loading PDF</MuiAlert>}
                    >
                        {
                            [...Array(numPages)].map((_, index) => 
                                <Page
                                    key={`page_${index}`}
                                    pageIndex={index}
                                    width={width}
                                    scale={zoomLevel}
                                />
                            )
                        }
                    </Document>
                </Box>
            </div>

        </>
    );
}

export default PDFViewer;