import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ArchiveIcon from '@material-ui/icons/Archive';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { PatientMessageFolder } from '../../../../shared/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            marginRight: theme.spacing(1)
        }
    }),
);

interface PatientMessageToolbarProps {
    folder: PatientMessageFolder,
    atLeastOneSelected: boolean,
    markAsUnread: () => void,
    moveFolder: (folder: PatientMessageFolder) => void,
}

const PatientMessageToolbar = ({ folder, atLeastOneSelected, markAsUnread, moveFolder }: PatientMessageToolbarProps) => {
    const classes = useStyles();

    return (
        <>
            <Collapse in={atLeastOneSelected} unmountOnExit>
                <ButtonGroup>
                    <Tooltip
                        title="Mark as unread"
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={markAsUnread}
                                disabled={!atLeastOneSelected}
                            >
                                <MarkunreadMailboxIcon className={classes.iconButton}/>
                                Unread
                            </Button>      
                        }
                    />      
                    <Tooltip
                        title={"Move to Inbox"}
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {moveFolder("inbox")}}
                                disabled={folder === "inbox" || !atLeastOneSelected}
                            >
                                {<MoveToInboxIcon className={classes.iconButton}/>}
                                Inbox
                            </Button>      
                        }
                    />   
                    <Tooltip
                        title={"Move to Sent"}
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {moveFolder("sent")}}
                                disabled={folder === "sent" || !atLeastOneSelected}
                            >
                                {<SendIcon className={classes.iconButton}/>}
                                Sent
                            </Button>      
                        }
                    />   
                    <Tooltip
                        title={"Move to Archive"}
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {moveFolder("archive")}}
                                disabled={folder === "archive" || !atLeastOneSelected}
                            >
                                {<ArchiveIcon className={classes.iconButton}/>}
                                Archive
                            </Button>      
                        }
                    />
                </ButtonGroup>     

            </Collapse>

        </>
    );
}

export default PatientMessageToolbar;