import React, { useState, useEffect, useCallback } from "react";
import GalleryModal from "./GalleryModal";
import { ImageSrc } from "./BaseGallery";
import MessagingRenderer from "./MessagingRenderer";
import Gallery from "react-photo-gallery";
import { fbStorage } from "../../../firebase";

import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";
import { StringDict } from "../../../../shared/types";
import useAITranslation from "../../../components/useAITranslation";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        linkMedia: {
            marginBottom: theme.spacing(2)
        }
	}),
);


interface MessagingGalleryProps {
    referralMediaURIs: string[],
    econsultImageLabels?: StringDict<string[]>
}

export const MessagingGallery = ( { referralMediaURIs, econsultImageLabels }: MessagingGalleryProps ) => {
    const classes = useStyles();
    const [displayMedia, setDisplayMedia] = useState<ImageSrc[]>([]);
    const [linkMedia, setLinkMedia] = useState<ImageSrc[]>([]);
    const [modalImage, setModalImage] = useState<ImageSrc>();
    const [showImageModal, setShowImageModal] = useState(false);

    const { aiToSp } = useAITranslation({ useSpToAi: true})

    const handleShowModal = (image: ImageSrc) => {
        setShowImageModal(true);
        setModalImage(image);
    }

    useEffect(() => {
        Promise.all(referralMediaURIs.map((uri) => {
            const ref = fbStorage.ref(uri);
            return ref.getDownloadURL()
            .then((src) => {
                const media: ImageSrc = {
                    fullPath: ref.fullPath,
                    name: ref.name,
                    src: src,
                    height: 1,
                    width: 1
                }
                return media;
            })
        }))
        .then((media: ImageSrc[]) => {
            const newDisplayMedia: ImageSrc[] = [];
            const newLinkMedia: ImageSrc[] = [];
            media.forEach((file) => {
                const fileType = file.name.split('.')[1];
                if(isImage(fileType) || isVideo(fileType))  {
                    newDisplayMedia.push(file);
                }
                else {
                    newLinkMedia.push(file);
                }
            })

            setDisplayMedia(newDisplayMedia);
            setLinkMedia(newLinkMedia);
        })
    }, [referralMediaURIs])

    const formatFileName = (filename: string): string => {
        const name = filename.replace(/^[0-9a-fA-F]{16}_/, '');//Remove random string appended to beginning of file name
        return name;
    }
    
    const imageRenderer = useCallback(
        ({ index, key, photo }) => (
          <MessagingRenderer
            key={`${key}_${index}`}
            margin={"2px"}
            index={index}
            photo={photo}
            handleShowModal={handleShowModal}
            labels={getLabels(photo)}
          />
        ),
        [aiToSp]
    );

    const getLabels = (imageSrc: ImageSrc): string[] | undefined => {
        return econsultImageLabels !== undefined && econsultImageLabels[imageSrc.fullPath] !== undefined ? econsultImageLabels[imageSrc.fullPath].map((label) => aiToSp(label, true)) : undefined
    }

    return (
        <>
            <div className="gallery-container">
                <Box className={classes.linkMedia}>
                    {linkMedia.map((file) => 
                        <div key={`econsult_file_${file.name}`} >
                            <a href={file.src} target="_blank" rel="noopener noreferrer">{formatFileName(file.name)}</a>
                        </div>
                    )}
                </Box>
                <Gallery photos={displayMedia} renderImage={imageRenderer}/>
                {modalImage !== undefined && <GalleryModal show={showImageModal} image={modalImage} handleClose={() => setShowImageModal(false)} formatFileName={formatFileName} 
                    doctorLabels={getLabels(modalImage)}/>}
            </div>
        </>
    );
}

export default MessagingGallery
