import React, { useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { Symptom, Specialties, FormField } from "./ReferralForm";
import { Col, Form } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import ReferralFormCard from "./ReferralFormCard";
import FormTextArea from "../../../components/FormTextArea";
import FormCheck from "../../../components/FormCheckbox";
import FormNumberField from "../../../components/FormNumberField";
import ReferralRadioButton from "./ReferralRadioButton";

interface ReferralFormSymptomsProps {
    control: Control,
    specialties: Specialties,
    fields: FormField,
    setFields: (newFields: FormField) => void,
    unregister: (name: string | string[]) => void,
    disabled: boolean
}

const baseName = "symptoms"

const ReferralFormSymptoms  = ({control, specialties, fields, setFields, unregister, disabled}: ReferralFormSymptomsProps) => {
    const specialty = useWatch({control, name: "specialty", defaultValue: undefined});
    const subsite = useWatch({control, name: "subsite", defaultValue: undefined});

    
    const [symptomsJsx, setSymptomsJsx] = useState<JSX.Element[]>([]);

    useEffect(() => {
        unregister(Object.keys(fields));
        setFields({});
    }, [specialty, subsite])

    useEffect(() => {
        setSymptomsJsx(generateSymptomFields());
    }, [specialty, subsite, disabled])




    const createCheckboxes = (header: string, values: string[], fieldRef: FormField) => {
        return (
            <>
                <Form.Row key={`header_${header}`}>
                    <Form.Group as={Col} md="6">
                        <InputLabel style={{whiteSpace: "pre-wrap"}}>{header}</InputLabel>
                    </Form.Group>
                </Form.Row>
                {values.map((_, i) => {
                    if(i % 2 === 0 && (i+1) < values.length){
                        return (
                            <Form.Row key={`row_${header}_${i}`}>
                                {createCheckbox(header, values[i], fieldRef)}
                                {createCheckbox(header, values[i+1], fieldRef)}
                            </Form.Row>
                        );
                    }
                    else if(i % 2 === 0 && i === values.length - 1) {
                        return (
                            <Form.Row key={`row_${header}_${i}`}>
                                {createCheckbox(header, values[i], fieldRef)}
                            </Form.Row>
                        );
                    }
                })}
            </>
        );
    }

    const createCheckbox = (header: string, value: string, fieldRef: FormField) => {

        //react hook form doesn't like its input names having commas and some other special characters
        //Use uri encoding to get around this
        const fieldName = `${baseName}.${formSafeEncode(header)}.${formSafeEncode(value)}`;
        fieldRef[fieldName] = false;
        return (
            <Form.Group as={Col} md="6" key={`checkbox_${header}_${value}`}>
                <FormCheck
                    name={fieldName}
                    control={control}
                    label={value}
                />
            </Form.Group>
        );
    }

    const createRadioButtons = (header: string, rows: string[], columns: string[], fieldRef: FormField) => {
        return (
            <>
                <Form.Row key={`header_${header}`}>
                    <Form.Group as={Col} md="6">
                        <InputLabel>{header}</InputLabel>
                    </Form.Group>
                </Form.Row>
            
                <Form.Row key={`columns_${header}`}>
                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <InputLabel>{columns[0]}</InputLabel>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <InputLabel>{columns[1]}</InputLabel>
                    </Form.Group>
                </Form.Row>
                {rows.map((row: string) => {
                    return createRadioButton(header as string, row, columns as string[], fieldRef);
                })}
            </>
        );
            
    }

    const createRadioButton = (header: string, row: string, columns: string[], fieldRef: FormField) => {
        const fieldName = `${baseName}.${formSafeEncode(header)}.${formSafeEncode(row)}`;
        fieldRef[fieldName] = '';
        return (
            <Form.Row key={`radio_${header}_${row}`}>
                <ReferralRadioButton
                    name={fieldName}
                    control={control}
                    row={row}
                    columns={columns}
                />
            </Form.Row>
        );
    }

    const createTextField = (header: string, fieldRef: FormField) => {
        const fieldName = `${baseName}.${formSafeEncode(header)}`;
        fieldRef[fieldName] = '';
        return (
            <Form.Row key={`textfield_${header}`}>
                <Form.Group as={Col} md="12" controlId={`textfield_${header}`}>
                    <InputLabel>{header}</InputLabel>
                    <FormTextArea
                        name={fieldName}
                        control={control}
                        initRows={1}
                        disabled={disabled}
                    />
                </Form.Group>
            </Form.Row>
        );
    }

    const createNumberField = (header: string, fieldRef: FormField) => {
        const fieldName = `${baseName}.${formSafeEncode(header)}`;
        fieldRef[fieldName] = '';
        return (
            <Form.Row key={`number_${header}`}>
                <Form.Group as={Col} md="12" controlId={`number_${header}`}>
                    <InputLabel>{header}</InputLabel>
                    <FormNumberField
                        name={fieldName}
                        control={control}
                    />
                </Form.Group>
            </Form.Row>
        );
    }

    const formSafeEncode = (str: string): string => {
        return btoa(str);//encode base64 since react hook form doesn't like special characters in variable names
    }

    const generateSymptomFields = () => {
        let symptomsJsx: JSX.Element[] = [];
        const symptomFieldNames: FormField[] = [];
        if(specialties !== undefined && specialties[specialty] !== undefined && specialties[specialty][subsite] !== undefined) {
            symptomsJsx = specialties[specialty][subsite].map((symptom: Symptom) => {
                let jsx: JSX.Element = <></>;
                switch (symptom.inputType) {
                    case "Checkboxes":
                        if(symptom?.header && symptom?.values) {
                            jsx = createCheckboxes(symptom.header, symptom.values, symptomFieldNames);
                        }
                        break;
                    case "Textfield":
                        if(symptom?.header){
                            jsx = createTextField(symptom.header, symptomFieldNames);
                        }
                        break;
                    case "Radio":
                        if(symptom?.header && symptom?.columns && symptom?.rows) {
                            jsx = createRadioButtons(symptom.header, symptom.rows, symptom.columns, symptomFieldNames);
                        }
                        break;
                    case "Number":
                        if(symptom?.header){
                            jsx = createNumberField(symptom.header, symptomFieldNames);
                        }
                        break;
                }   
                return (
                    <ReferralFormCard key={`card_${symptom.header}`}>
                        {jsx}
                    </ReferralFormCard>
                )
            });
        }
        setFields(symptomFieldNames);
        return symptomsJsx;
    }

    return (
        <>
            {symptomsJsx}
        </>
    );
}
export default ReferralFormSymptoms;