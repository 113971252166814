import React, { useContext, useEffect, useState } from 'react';
import PaperModal from '../../../../components/PaperModal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { SpecialistContext } from '../SpecialistProvider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { EconsultContext } from '../Econsult/EconsultProvider';
import useProcessState from '../../../../components/useProcessState';
import { ProcessState, ProcessStatus } from '@alethea-medical/react-components';
import { fbFirestore, logAnalyticsEvent } from '../../../../firebase';
import { Activity, Econsult } from '../../../../../shared/types';
import { InputContext } from '../InputProvider';
import FloatingButton from '../../../../components/FloatingButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        }
    }),
);

interface EconsultOutcomesProps {
    activityId: string,
    updateOutcome: (specialistResponse: {outcome: string, diagnosis: string}, activityId: string) => void
}

export const defaultOutcome = "Not applicable"

const outcomes = [
    "No treatment required",
    "Patient to see Specialist",
    "Management or treatment suggested",
    "Treatment suggested prior to Specialist visit",
    "Further investigations suggested",
    "Refer to different specialty",
    "Unknown",
    defaultOutcome
]


const EconsultOutcomes = ({ activityId, updateOutcome }: EconsultOutcomesProps) => {
    const classes = useStyles();
    const { specialist } = useContext(SpecialistContext);
    const inputContext = useContext(InputContext);

    const [enable, setEnable] = useState(false);
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);


    const [selectedOutcome, setSelectedOutcome] = useState<string>(defaultOutcome);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<string>("");

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();

    const { econsult, ref } = useContext(EconsultContext);

    useEffect(() => {
        if(econsult.specialistResponse?.outcome === undefined)
            setSelectedOutcome(inputContext.outcome)
    }, [inputContext.outcome])

    useEffect(() => {
        if(econsult.specialistResponse?.diagnosis === undefined)
            setSelectedDiagnosis(inputContext.diagnosis)
    }, [inputContext.diagnosis])

    useEffect(() => {
        if(econsult.specialistResponse !== undefined) {
            setShowButton(true);
            setSelectedOutcome(econsult.specialistResponse.outcome);
            setSelectedDiagnosis(econsult.specialistResponse.diagnosis);
        }
        else {
            setShowButton(false);
        }
    }, [econsult])

    useEffect(() => {
        if(specialist !== undefined && specialist.enableEconsultDataCollection === true) {
            setEnable(true);
        }
        else {
            setEnable(false);
        }
    }, [specialist])

    useEffect(() => {
        //Turned on and off when message is sent
        if(inputContext.messageSent === true){
            //Only show if we haven't recorded a specialist response yet
            if(econsult.specialistResponse === undefined) {
                setShow(true);
            }
        }
    }, [inputContext.messageSent])

    const handleSubmitOutcomes = () => {
        setProcessState(ProcessState.running);
        logAnalyticsEvent("econsult_outcome_start", specialist?.specialty);
        //Add to econsult
        if(ref !== undefined) {
            const econsultUpdate: Partial<Econsult> = {
                specialistResponse: {
                    outcome: selectedOutcome,
                    diagnosis: selectedDiagnosis
                }
            }
            const activityUpdate: Partial<Activity> = {
                exposedEconsultData: econsultUpdate
            }
            const batch = fbFirestore.batch();

            //Duplicate data to make it easier to read from activity list for display purposes
            //It should belong in the eConsult though, so we put it there too.
            batch.update(ref, econsultUpdate);//Update econsult
            batch.update(fbFirestore.collection("activities").doc(activityId), activityUpdate);//Update activity
            batch.commit()
            .then(() => {
                logAnalyticsEvent("econsult_outcome_success", specialist?.specialty);
                setProcessState(ProcessState.idle);
                setShow(false);
                updateOutcome({outcome: selectedOutcome, diagnosis: selectedDiagnosis}, activityId);
            })
            .catch((error: Error) => {
                errorHandler({
                    userMessage: "Unable to log outcome in eConsult. Please try again or skip this step.",
                    error: error,
                    hideErrorMessage: true,
                    analyticsLog: "econsult_outcome_failed",
                    analyticsLogObj: specialist?.specialty
                });
            })
        }
        else {
            setProcessState(ProcessState.error);
            errorHandler({
                userMessage: "Unable to log outcome in eConsult. Please try again or skip this step."
            });
        }
    }

    return (
        <>
            {enable && (
                <>
                    {showButton && (
                        <FloatingButton show={show} setShow={setShow} textShow="Edit Outcomes" textHide="Hide Outcomes" top={70} right={0}/>
                    )}
                    <PaperModal show={show} setShow={setShow} width="50vw" flexHeight>
                        <Grid container className={classes.container} spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Please confirm the eConsult outcome and diagnosis are correct</Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography>Outcome</Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Select
                                    onChange={(e) => {setSelectedOutcome(e.target.value as string)}}
                                    value={selectedOutcome}
                                    placeholder='Please enter the outcome'
                                    fullWidth
                                    margin="dense"
                                >
                                    {outcomes.map((outcome) => 
                                        <MenuItem value={outcome}>{outcome}</MenuItem>
                                    )}
                                </Select>
                                
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography>Diagnosis</Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField 
                                    onChange={(e) => {setSelectedDiagnosis(e.target.value as string)}}
                                    onKeyDown={(e) => {
                                        if(e.key === "Enter") {
                                            handleSubmitOutcomes();
                                        }
                                    }}
                                    value={selectedDiagnosis}
                                    placeholder='Please enter the diagnosis'
                                    fullWidth
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {setShow(false)}}
                                >Skip</Button>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmitOutcomes}
                                >Save and Close</Button>
                            </Grid>
                        </Grid>
                    </PaperModal>
                </>
            )}
        </>
    );
}

export default EconsultOutcomes;