import React, { useState, useEffect, useContext, useCallback } from "react";
import firebase from "firebase";
import { fbStorage } from "../../firebase";
import { AuthContext } from "../../AuthProvider";

import Gallery from "react-photo-gallery";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import GalleryRenderer from "./GalleryRenderer";
import { BinnedFileRefs, BinnedFiles, GalleryFile, SelectedFiles } from "./galleryTypes";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";
import { FileType } from "../../../shared/types";
import GalleryModal from "./GalleryModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileThumb:  {
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            margin: "auto",
        },
        fileThumbContainer: {
            position: "relative",

        },
        thumbnailRemoveButton: {
            position: "absolute",
            top: 0,
            right: 0,
            padding: theme.spacing(0.5)
        },
    }),
);

interface UseGalleryProps {
    enableSelect?: boolean,
    enableEdit?: boolean,
}

function useGallery( { enableSelect, enableEdit }: UseGalleryProps ) {
    const authContext = useContext(AuthContext);
    const classes = useStyles();

    //image refs binned by date where the timestamp is the key
    const [binnedFileRefs, setBinnedFileRefs] = useState<BinnedFileRefs>();

    //Result of getting download URLs for the image refs in binnedImages
    //Only download URLs for images that occured less than maxDays ago are fetched
    const [binnedFiles, setBinnedFiles] = useState<BinnedFiles>({});

    //Key is URI
    const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({});

    const [modalFile, setModalFile] = useState<GalleryFile>();
    const [showModal, setShowModal] = useState(false);

    //By default load the past 7 days that images exist
    const [maxDays, setMaxDays] = useState<number>(7);
    

    const fetchFiles = (folder: string, uid: string): Promise<firebase.storage.Reference[]> => {
        const storageRef = fbStorage.ref(`${folder}/${uid}`)
    
        return storageRef.listAll()
        .then((result) => {
            const files = result.items;
    
            //Images are fetched in order starting from oldest to newest (since their filename is the timestamp)
            //Need to reverse the order
            return files.reverse();
        })
    }
    
    const tryParseTimestamp = (timestampString: string): Promise<Date> => {
        return new Promise<Date>((resolve, reject) => {
            try {
                //Find the day
                let unixTimestamp = parseInt(timestampString);
                let timestamp = new Date(unixTimestamp);
                resolve(timestamp);
            }
            catch {
                reject();
            }
        })
    }
    
    const getFileType = (filename: string): FileType => {
        if(/.*\.jpg|.*\.png|.*.jpeg/.test(filename)) {
            return "image"
        }
        else if(/.*\.mp4/.test(filename)) {
            return "video";
        }
        else {
            return "other";
        }
    }

    const getBinnedFileRefs = (folder: string, uid: string): Promise<BinnedFileRefs> => {
        return fetchFiles(folder, uid)
        .then((files: firebase.storage.Reference[]) => {
            const binnedFileRefs: BinnedFileRefs = {};
            return Promise.all(files.map((file: firebase.storage.Reference) => {
                //Bin each image based on the day it was taken
                return tryParseTimestamp(file.name.split('.')[0])
                .catch(() => {
                    return file.getMetadata()
                    .then((metadata) => {
                        return tryParseTimestamp(metadata.timeCreated)
                    })
                })
                .then((timestamp) => {
                    timestamp.setHours(0);
                    timestamp.setMinutes(0);
                    timestamp.setSeconds(0);
                    timestamp.setMilliseconds(0);
                    
                    //If we have a bin for that day, add the image to that bin, otherwise create a new bin.
                    if(binnedFileRefs[timestamp.getTime()]) {
                        binnedFileRefs[timestamp.getTime()].push(file);
                    }
                    else {
                        binnedFileRefs[timestamp.getTime()] = [file];
                    }
                })
                .catch(() => {
                    console.log(`Unable to parse timestamp for file ${file.name}`);
                    return;
                })
    
            }))
            .then(() => {
                return Promise.resolve(binnedFileRefs);
            })
        })
    }

    const getBinnedImageAndVideoRefs = (uid: string): Promise<BinnedFileRefs> => {
        let newBinnedFileRefs: BinnedFileRefs;
        return getBinnedFileRefs('images', uid)
        .then((binnedImageRefs: BinnedFileRefs) => {
            newBinnedFileRefs = binnedImageRefs;

            return getBinnedFileRefs('videos', uid)
        })
        .then((binnedVideoRefs: BinnedFileRefs) => {
            Object.keys(binnedVideoRefs).forEach((timestamp) => {
                if(newBinnedFileRefs[timestamp] !== undefined) { //If bin exists on images, concat videos 
                    newBinnedFileRefs[timestamp] = newBinnedFileRefs[timestamp].concat(binnedVideoRefs[timestamp]);
                }
                else { //Otherwise create a new bin
                    newBinnedFileRefs[timestamp] = binnedVideoRefs[timestamp];
                }
            })
            return newBinnedFileRefs;
        });
    }

    const downloadBinnedFileRefs = (): Promise<BinnedFiles> => {
        if(binnedFileRefs !== undefined) {
            //Sort by datetime from newest to oldest, and then filter
            return Promise.all(Object.keys(binnedFileRefs).sort().reverse().filter((timestamp: string, index: number) => {
                //Only fetch images that were taken less than max days in the past, and that we haven't fetched before
                return index < maxDays && binnedFiles[timestamp] === undefined;
            })
            .map((timestamp: string) => {
                return Promise.all(binnedFileRefs[timestamp].map((fileRef) => {
                    const galleryFile: GalleryFile = {
                        uri: fileRef.fullPath,//URI in firebase storage
                        src: "",//Download URL to display
                        filename: fileRef.name,
                        fileType: getFileType(fileRef.name)  
                    }
                    return fileRef.getDownloadURL()
                    .then((src: string) => {
                        galleryFile.src = src;
                        return galleryFile;
                    })
                    .catch(() => {
                        return galleryFile;
                    })

                    
                }))
                .then((galleryFiles: GalleryFile[]) => {
                    return {
                        galleryFiles: galleryFiles,
                        timestamp: timestamp
                    }
                })
            }))
            .then((result: {galleryFiles: GalleryFile[], timestamp: string}[]) => {
                const newBinnedFiles = {...binnedFiles}
                result.forEach((newBin) => {
                    newBinnedFiles[newBin.timestamp] = newBin.galleryFiles;
                })
                return newBinnedFiles;
            })
        }
        else {
            return Promise.resolve({});
        }
    }

    const refresh = () => {
        if(authContext.uid !== undefined) {
            setBinnedFileRefs({});
            getBinnedImageAndVideoRefs(authContext.uid)
            .then((newBinnedFileRefs) => {
                setBinnedFileRefs(newBinnedFileRefs);
            })
        }
    }

    const loadMore = (amountToLoad: number) => {
        setMaxDays(maxDays + amountToLoad);
    }

    const selectFile = (file: GalleryFile) => {
        const newSelectedFiles = {...selectedFiles};
        newSelectedFiles[file.uri] = file
        setSelectedFiles(newSelectedFiles);
        
    }

    const unselectFile = (key: string) => {
        const newSelectedFiles = {...selectedFiles};
        if(newSelectedFiles[key] !== undefined) {//Make sure it exists
            delete newSelectedFiles[key];
            setSelectedFiles(newSelectedFiles);
        }
    }

    const unselectFiles = (keys: string[]) => {
        const newSelectedFiles = {...selectedFiles};
        keys.forEach((key) => {
            if(newSelectedFiles[key] !== undefined) {//Make sure it exists
                delete newSelectedFiles[key];
            }
        })
        setSelectedFiles(newSelectedFiles);

    }

    const unselectAll = () => {
        setSelectedFiles({});
    }

    const handleShowModal = (file: GalleryFile) => {
        setModalFile(file);
        setShowModal(true);
    }

    const imageRenderer = useCallback(
        ({ index, key, photo }) => (
            <GalleryRenderer
                key={key}
                //React photo gallery props
                file={photo}
                //Custom props
                selected={selectedFiles[photo.uri] !== undefined}
                selectFile={selectFile}
                unselectFile={unselectFile}
                handleShowModal={handleShowModal}
            />
        ),
        [selectedFiles]
    );

    const renderGallery = () => {
        if(binnedFileRefs === undefined) {
            return (
                <Typography variant="subtitle1">
                    Loading images...
                </Typography>
            );
        }
        else if(Object.keys(binnedFileRefs).length === 0) {
            return (
                <Typography variant="subtitle1">
                    You have no images yet.
                </Typography>
            );
        }   
        else if(Object.keys(binnedFiles).length > 0) {
            return (
                <Grid container spacing={2}>
                    {Object.keys(binnedFiles).map((timestamp) => 
                        <Grid item xs={12} key={`gallery_${timestamp}`}>
                            <Typography variant="subtitle1">
                                {`${new Date(parseInt(timestamp)).toDateString()}`}
                            </Typography>
                            <Gallery photos={binnedFiles[timestamp].map((file) => {
                                return {
                                    ...file,
                                    width: 1,
                                    height: 1
                                }
                            })} renderImage={imageRenderer}/>
                            <Divider/>
                        </Grid>
                    )}
                </Grid>
            );
        }
    }

    const createSelectedFileThumbs = () => ( <ThumbnailGrid files={selectedFiles} handleRemove={unselectFile}/>)

    const createModal = () => {
        if(modalFile !== undefined) {
            return (<GalleryModal file={modalFile} show={showModal} setShow={setShowModal}/>)
        }
        else {
            return null;
        }
    } 

    useEffect(() => {
        refresh();
    }, [authContext.uid]);

    useEffect(() => {
        downloadBinnedFileRefs()
        .then((newBinnedFiles) => {
            setBinnedFiles(newBinnedFiles);
        })
    }, [binnedFileRefs, maxDays])

    return {
        selectedFiles, selectFile, unselectFile, unselectFiles, unselectAll, renderGallery, refresh, loadMore, createSelectedFileThumbs, createModal
    }
}

export default useGallery;