import React, { useState } from "react";
import ReferralFormCard from "../Referral/ReferralFormCard";
import { SelectedImages, ImageSrc, BaseGallery } from "./BaseGallery";
import ReferralGalleryImage from "./ReferralRenderer";
import GalleryModal from "./GalleryModal";
import Button from '@material-ui/core/Button';
import { Form, Col } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import "./ReferralGallery.css";

type ReferralImageGalleryProps = {
    selectedImages: SelectedImages,
    setSelectedImages: (selectedImages: SelectedImages) => void
}  

const ReferralGallery = (props: ReferralImageGalleryProps) => {
    const { selectedImages, setSelectedImages } = props;

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState<ImageSrc | undefined>();
    const [refresh, setRefresh] = useState(false);

    const handleShowModal = (image: ImageSrc) => {
        setModalImage(image);
        setShowModal(true);
    }

    const refreshHandler  = () => {
        setRefresh(!refresh);
    }

    return (
        <ReferralFormCard className="rg-sticky" title="Click images to add or remove">

            <Form.Row>
                <Form.Group as={Col} md="auto">
                    <Form.Row>
                        <Form.Group as={Col} md="8">
                            <Form.Label className="mr-3 mt-2 text-muted">{Object.keys(selectedImages).length} images selected</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Button variant="contained" color="primary" onClick={refreshHandler}>Refresh</Button>
                        </Form.Group>
                    </Form.Row>

                    {Object.keys(selectedImages).length > 0 && 
                        <button className="button-border" onClick={(event: any) => {
                            event.preventDefault(); 
                            setSelectedImages({});
                        }}>
                            Unselect All
                        </button>
                    }
                    
                </Form.Group>
            </Form.Row>
            <Divider className="mb-3"/>
            <div className="rg-gallery-container">
                <BaseGallery selectedImages={selectedImages} editing={true} setSelectedImages={setSelectedImages} GalleryRenderComponent={ReferralGalleryImage} handleShowModal={handleShowModal} refresh={refresh}/>
                {modalImage !== undefined ? <GalleryModal show={showModal} image={modalImage} handleClose={() => setShowModal(false)}/> : <></>}
            </div>
        </ReferralFormCard>
    );
}

export default ReferralGallery;