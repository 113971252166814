import React, { useState, useEffect } from "react";
import { Control, useWatch } from "react-hook-form";

import { Specialties } from "./ReferralForm";
import FormSelect from "../../../components/FormSelect";


interface SubsiteSelectProps {
    control: Control,
    specialties: Specialties | undefined,
    setValue: (name: string, value: any) => void,
    disabled: boolean
}

const SubsiteSelect = ({control, specialties, setValue, disabled}: SubsiteSelectProps) => {
    const specialty = useWatch({control, name: "specialty", defaultValue: undefined})
    const [subsites, setSubsites] = useState<string[]>([]);


    useEffect(() => {
        if(specialties !== undefined && specialty !== undefined && specialties[specialty] !== undefined){
            const subsiteList = Object.keys(specialties[specialty]).sort();
            setValue("subsite", subsiteList[0]);
            setSubsites(subsiteList);
        }
    }, [specialties, specialty])

    
    return (
        <FormSelect
            name="subsite"
            control={control}
            label="Subsite"
            options={subsites}
            disabled={disabled}
        />
    );
}

export default SubsiteSelect;