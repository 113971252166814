import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SearchOption, SearchParam } from "./SearchBar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            paddingTop: theme.spacing(0.5)
        }   
    }),
);



interface SearchItemProps {
    index: number,
    searchParam: SearchParam,
    searchOptions: SearchOption[],
    setSearchField: (field: string, index: number) => void,
    setSearchValue: (value: string, index: number) => void,
    removeItem: (index: number) => void
}

const SearchItem = ({ index, searchParam, searchOptions, setSearchField, setSearchValue, removeItem }: SearchItemProps) => {
    const classes = useStyles();

    const [internalValue, setInternalValue] = useState(searchParam.value);
    const [selectedOption, setSelectedOption] = useState<SearchOption>();
    
    useEffect(() => {
        const newSelectedOption = searchOptions.find((o) => {return o.field === searchParam.field});
        if(newSelectedOption !== undefined)
            setSelectedOption(newSelectedOption)
    }, [searchParam])

    const getDisplayName = (text: string) => {
        const result = text.replace( /([A-Z])/g, " $1" );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const updateValue = () => {
        setSearchValue(internalValue, index);
    }


    return (
           <Grid container spacing={2}>

                <Grid item xs={5}>
                    <Select
                        value={searchParam.field}
                        onChange={(e) => {
                            setSearchField(e.target.value as string, index);
                            setInternalValue("");
                        }}
                        fullWidth
                    >
                        {searchOptions.map((option) => 
                            <MenuItem key={`field_${option.field}_${index}`} value={option.field}>{option.display ? option.display : getDisplayName(option.field)}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={5}>
                    {selectedOption?.options !== undefined ? (
                        <Select
                            value={searchParam.value}
                            onChange={(e) => {
                                setInternalValue(e.target.value as string);
                                setSearchValue(e.target.value as string, index);
                            }}
                            fullWidth
                        >
                            {selectedOption.options.map((option) => 
                                <MenuItem key={`value_${option.value}_${index}`} value={option.value}>{option.display ? option.display : getDisplayName(option.value)}</MenuItem>
                            )}
                        </Select>
                    ) : (
                        <TextField 
                            value={internalValue}
                            onChange={(e) => {
                                setInternalValue(e.target.value as string);
                            }}
                            onKeyPress={(e) => {
                                if(e.key === "Enter") {
                                    updateValue();
                                }
                            }}
                            onBlur={updateValue}
                            fullWidth
                        />
                    )}
                </Grid>
                <Grid item xs={2}>
                   <IconButton className={classes.iconButton} color="primary" onClick={() => {removeItem(index)}}><RemoveCircleOutlineIcon/></IconButton>  
               </Grid>
            </Grid>
    );
}

export default SearchItem;