
import React, { useCallback, useEffect, useState } from 'react';
import { OtoscopeCamera } from './OtoscopeCamera';
import OtoscopeCard from './OtoscopeCard';
import { CameraButtons } from './CameraButtons'
import useUploadMediaFile from './useUploadMediaFile';
import useProcessState from '../../../components/useProcessState';
import useSizeManager from '../../../components/useSizeManager';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cameraButtons: {
            marginTop: theme.spacing(1)
        }
    }),
);

const CameraContainer = ({ autoRefresh, setAutoRefresh, paperHeight }: any) => {
    const classes = useStyles();
    const [image, setImage] = useState('');
    const [uploadingImage, setuploadingImage] = useState(false)
    const [uploadingVideo, setuploadingVideo] = useState(false)
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [uploadBlob, setuploadBlob] = useState<Blob>()

    const [errorMsg, seterrorMsg] = useState()

    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);

    const webcamRef = React.useRef() as React.MutableRefObject<any>
    const mediaRecorderRef = React.useRef() as React.MutableRefObject<any>

    const [recordingModeActive, setrecordingModeActive] = useState(false)
    const [capturing, setCapturing] = React.useState(false);

    const { processState, processErrorMessage, setProcessState, setProcessErrorMessage, errorHandler } = useProcessState()

    //gets the currently connected devices 
    const handleDevices = useCallback(mediaDevices => {
        const newDevices = mediaDevices.filter(({ kind }: any) => kind === "videoinput");
        setDevices(newDevices)
        if (newDevices.length > 0)
            setDeviceId(newDevices[0]);
    }, [setDevices]);

    //gets the currently connected devices
    useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );


    //default constraints for the camera. height and width define resolution
    const defaultVideoConstraints = {
        facingMode: "user"
    }


    //set which camera to use, by default it gets set to the first device in the list of devices
    const [videoConstraints, setvideoConstraints] = useState({
        ...defaultVideoConstraints,
        deviceId: devices[0]

    })

    //take a single frame image
    const capture = () => {
        if (webcamRef.current != undefined && webcamRef.current.getScreenshot() != null) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
        }
    }


    //begin recording
    const handleStartRecordClick = () => {
        if (mediaRecorderRef != undefined) {

            try {

                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                    mimeType: 'video/webm; codecs=vp8'
                });

                ;
                mediaRecorderRef.current.addEventListener(
                    "dataavailable",
                    handleDataAvailable
                );
                if (mediaRecorderRef.current.stream.active) {
                    setCapturing(true)
                    mediaRecorderRef.current.start();
                }
            } catch (error) {
                setCapturing(false)
                console.log(error)
            }



        }
    }


    //get the stream data from the camera
    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            setRecordedChunks([])
            if (data.size > 0) {
                setRecordedChunks(prev => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );


    //stop recordding 
    const handleStopRecordClick = () => {
        if (mediaRecorderRef != undefined) {
            if (mediaRecorderRef.current.stream.active) {
                mediaRecorderRef.current.stop();
                setCapturing(false);
            }
        }
    }


    //begin file upload when recording stops
    useEffect(() => {
        if (recordedChunks.length && !uploadingVideo && mediaRecorderRef != undefined) {
            const blob = new Blob(recordedChunks, {
                type: 'video/webm; codecs=vp8'
            });
            setuploadBlob(blob)
            setuploadingVideo(!uploadingVideo)
        }
    }, [recordedChunks])


    useUploadMediaFile({ image, uploadingImage, setuploadingImage, setImage, setProcessState, autoRefresh, setAutoRefresh, uploadBlob, setuploadBlob, uploadingVideo, setuploadingVideo, errorHandler })


    const camerabuttonProps = {
        setProcessState, processState, processErrorMessage,
        image, webcamRef, setDevices, uploadingImage, setuploadingImage, capture, recordingModeActive,
        setrecordingModeActive, capturing, handleStopRecordClick, handleStartRecordClick, devices, setDeviceId, deviceId, handleDevices
    }

    const { sizeRef: buttonSizeRef, height: buttonHeight } = useSizeManager();

    const otoscopeProps = { image, webcamRef, devices, deviceId, videoConstraints, setvideoConstraints, cameraHeight: paperHeight - buttonHeight }

    return (
        <>
            <OtoscopeCard paperHeight={paperHeight}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            height={paperHeight - buttonHeight}
                        >
                            <OtoscopeCamera {...otoscopeProps} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div ref={buttonSizeRef}>
                            <div className={classes.cameraButtons}>
                                <CameraButtons {...camerabuttonProps} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </OtoscopeCard>
        </>
    )
}
export default CameraContainer

