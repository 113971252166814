
const serverAPIString = "serverV4"
//Function to handle sending authorized requests to the backend. Handles https errors and server errors for you, and returns as standard promise errors.
export default function serverRequest(user: firebase.default.User | null | undefined, headers: any, body: any, url: string) : Promise<any>{
    return new Promise<string | undefined>((resolve, reject) => {
        if(user !== null && user !== undefined)
        {
            user.getIdToken(true).then((token) => { resolve(token) })
            .catch((error: Error) => { reject(error)} )
        }
        else
        {
            resolve(undefined);
        }
    })
    .then((token: string | undefined) => {
        if(token !== undefined)
            headers.Authentication = `Bearer ${token}`
            
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body
        };
        return fetch(`/${serverAPIString}/${url}`, requestOptions)
    })
    .then(response => {
        console.log("Server response status: ", response.status);

        if(response.status === 404)
            return Promise.reject(new Error(response.status + " " + response.statusText + ": Server may be down, or check your internet connection."));
        else if(response.status < 400){
            return response.clone().json()
            .catch(() => {
                return response.clone().text()
            })
        }
        else if(response.status === 500){
            return response.clone().json()
            .catch(() => {
                console.error("Error parsing JSON, falling back on text")
                return response.clone().text()
            })
            .then((error) => {
                return Promise.reject(new Error(error?.error ? error.error : JSON.stringify(error)));
            })
        }
        else
        {
            return Promise.reject(new Error(response.status + " " + response.statusText));
        }
    })
    .catch((error: Error) => {
        console.error("Authorized Server Request: Error caught => ", error)
        return Promise.reject(error);
    });    
}

