import serverRequest from "../../../models/serverRequest";

function exportPDF(activityId: string, user: firebase.default.User | undefined): Promise<void> {
    const body = {
        activityId: activityId
    }

    return serverRequest(user, {'Content-Type': 'application/json'}, JSON.stringify(body), 'secure-messaging-export-pdf')
    .then((result) => {
        downloadBlob(result.data, 'econsult_export.pdf', 'application/pdf')
    })
}



function downloadBlob (dataBase64: string, fileName: string, mimeType: string) {
    const data = base64ToArrayBuffer(dataBase64);
    const blob = new Blob([data], {
        type: mimeType
    });
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName);
    setTimeout(() => {
        return window.URL.revokeObjectURL(url);
    }, 1000);
}

function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

function downloadURL (data: string, fileName: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export default exportPDF;