import React from "react"

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        container: {
            height: "100%",
            padding: theme.spacing(1)
        },
        content: {
            color: theme.palette.text.secondary,
            whiteSpace: 'pre-line',//Show newlines
            wordWrap: 'break-word',//break lines on word to fit,           
        }
	}),
);



interface EconsultCardProps {
    title: string
}

const EconsultCard = ({ title, children }: React.PropsWithChildren<EconsultCardProps>) => {
    const classes = useStyles();

    return (
        <Paper
            variant="outlined"
            className={classes.container}
        >
            <Typography gutterBottom>
                {title}
            </Typography>
            <div className={classes.content}>
                {children}
            </div>
        </Paper>
    );
}

export default EconsultCard;