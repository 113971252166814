import React, { useEffect } from "react";
import { Control, useWatch, UseFormGetValues, FieldValues } from "react-hook-form";

import Grid from '@material-ui/core/Grid';
import FormTextField from "../FormTextField";
import FormSelect from "../FormSelect";
import { OOPData } from "@alethea-medical/admin-types";
import FormDatePicker from "../FormDatePicker";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        helperText: {
            fontSize: "0.75em"
        }
    }),
);

interface FormOOPInputProps {
    oop: boolean,
    control: Control,
    getValues: UseFormGetValues<FieldValues>,
    setValue: (name: string, value: any, options?: any) => void,
    disabled?: boolean
}
// export interface OOPData {
//     surname: string,
//     middleName?: string,
//     firstName: string,
//     birthDate: string,
//     genderCode: string,
//     addressLine1: string,
//     addressLine2?: string,
//     addressLine3?: string,
//     cityName: string,
//     postalCode: string,
//     provinceCode: string,
// }


function getStringLengthValidator (maxLen: number) {
    return (str: string) => {
        return str.length > maxLen ? `This field must be no more than ${maxLen} characters long.` : undefined;
    }
}

const FormOOPInput = ({ oop, control, getValues, setValue, disabled }: FormOOPInputProps) => {
    const classes = useStyles();

    useEffect(() => {
        if(oop === true) {
            const patientFirstName = getValues("patientFirstName");
            const patientLastName = getValues("patientLastName");
            const oopData: OOPData = getValues("oopData");

            if(oopData.firstName === undefined || oopData.firstName === "")
                setValue("oopData.firstName", patientFirstName, { shouldValidate: true });
            if(oopData.surname === undefined || oopData.surname === "")
                setValue("oopData.surname", patientLastName, { shouldValidate: true });
        }

    }, [oop])

    return (
        <>
            <Grid container spacing={1}>
                {/* firstName */}
                <Grid item xs={6}>
                    <FormTextField control={control} name="oopData.firstName" label="Patient First Name" disabled={disabled}
                        rules={{required: {value: true, message: "Patient first name is required."}, 
                                validate: {valid: getStringLengthValidator(12)}
                            }}
                    />
                </Grid>
                {/* surname */}
                <Grid item xs={6}>
                    <FormTextField control={control} name="oopData.surname"  label="Patient Last Name" disabled={disabled}
                        rules={{required: {value: true, message: "Patient last name is required."}, 
                            validate: {valid: getStringLengthValidator(30)}
                        }}
                    />
                </Grid>
                {/* genderCode */}
                <Grid item xs={6}>
                    <FormSelect control={control} name="oopData.genderCode" label="Gender" disabled={disabled}
                        options={["M", "F"]} 
                        defaultEmpty
                        rules={{required: {value: true, message: "Gender is required."}}}
                    />
                </Grid>

                {/* birthDate */}
                <Grid item xs={12}>
                    <FormDatePicker control={control} name="oopData.birthDate" label="Birth Date" labelAsPlaceHolder
                        rules={{required: {value: true, message: "Birth date is required."}}}/>
                </Grid>


                {/* addressLine1 */}
                <Grid item xs={12}>
                    <FormTextField control={control} name="oopData.addressLine1" label="Address Line 1" disabled={disabled}
                        rules={{required: {value: true, message: "Address line 1 is required."}, 
                            validate: {valid: getStringLengthValidator(25)}
                        }}
                    />    
                    <Typography className={classes.helperText} variant="subtitle1">Address line 1 should contain non-address data (e.g. company
                name) if applicable, otherwise the street or mailing
                address should be here. The apartment or unit
                number is to be placed at the end of the street
                address. No symbols (#,-) are to be placed before
                the number.</Typography>
                </Grid>
                {/* addressLine2 */}
                <Grid item xs={12}>
                    <FormTextField control={control} name="oopData.addressLine2" label="Address Line 2" disabled={disabled}/>              
                    <Typography className={classes.helperText} variant="subtitle1">If address line 1 is used for non-address data, address line 2 should contain street or mailing address. Otherwise address line 2 is optional.</Typography>  
                </Grid>
                {/* addressLine3 */}
                <Grid item xs={12}>
                    <FormTextField control={control} name="oopData.addressLine3" label="Address Line 3" disabled={disabled}/>                
                    <Typography className={classes.helperText} variant="subtitle1">Address line 3 is optional.</Typography>  
                </Grid>
                {/* cityName */}
                <Grid item xs={12}>
                    <FormTextField control={control} name="oopData.cityName" label="City Name" disabled={disabled}
                        rules={{required: {value: true, message: "City name is required."}, 
                            validate: {valid: getStringLengthValidator(30)}
                        }}
                    />                
                </Grid>
                {/* postalCode */}
                <Grid item xs={12}>
                    <FormTextField control={control} name="oopData.postalCode" label="Postal Code" disabled={disabled}
                        rules={{required: {value: true, message: "Postal code is required."}, 
                            validate: {valid: (str: string) => {
                                return /^[A-Z]\d[A-Z]\d[A-Z]\d$/.test(str) ? undefined : "Postal is invalid. Do not include any spaces, and use capital letters (ex. A1A1A1).";
                            }}
                        }}
                    />                
                </Grid>
                {/* provinceCode */}
                <Grid item xs={12}>
                    <FormSelect control={control} name="oopData.provinceCode" label="Province" disabled={disabled}
                        options={["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]} 
                        defaultValue={"BC"}
                        rules={{required: {value: true, message: "Province is required."}, 
                            validate: {valid: (pr: string) => {
                                return pr === "QC" || pr === "AB" ? "Patients from Alberta or Quebec cannot be billed as out of province patients." : undefined;
                            }}
                        }}
                    />
                </Grid>
                
            </Grid>
        </>
    );
}


export default FormOOPInput;