import React, { useContext, useState, useEffect, useRef  } from "react";
import { fbFirestore } from "../../../firebase";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { AuthContext } from "../../../AuthProvider";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { Message } from "../../../../shared/types";
import { Activity } from "./types";
import { aletheaMDCrypto } from "@alethea-medical/utilities";
import { formatTimeLong, formatTimeShort } from "../../../models/formatTime";
import AddLinks from "../../../components/AddLinks";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    message: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '15px',
        whiteSpace: 'pre-line',//Show newlines
        wordWrap: 'break-word'//break lines on word to fit
    },
    myMessage: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.secondary
    },
    yourMessage: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.secondary
    },
    readStatus: {
        fontSize: "small",
        color: theme.palette.primary.main
    },
    messageText: {
        zIndex: 100,
        position: 'relative'
    }
  }),
);

interface MessagesProps {
    activityId: string
}


const Messages = ({ activityId }: MessagesProps) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const [messages, setMessages] = useState<Message[]>([]);
    const [otherUserRead, setOtherUserRead] = useState<boolean>(false);

    useEffect(() => {

        //Subscribe to new messages
        const unsubscribeMessages = fbFirestore.collection("activities").doc(activityId).collection("messages").orderBy("sentAt", "asc").onSnapshot((snapshot) => {
            const newMessages = snapshot.docs.map((doc) => {
                return doc.data() as Message;
            });
            aletheaMDCrypto.encryptDecryptMessages(newMessages, fbFirestore.collection("system").doc("keystore").collection("keys").doc("firestoreData"), {decrypt: true})
            .then(() => {
                setMessages(newMessages);
            })
        });
        
        //Subscribe to changes in the readBy status on the activity
        const unsubscribeReadby = fbFirestore.collection("activities").doc(activityId).onSnapshot((snapshot) => {
            if(snapshot.exists) {
                const activity = snapshot.data() as Activity;
            
                let otherUserUid: string = "";
                if(activity.users[0] !== authContext.uid) {
                    otherUserUid = activity.users[0];
                }
                else if(activity.users[1] !== authContext.uid) {
                    otherUserUid = activity.users[1];
                }
                setOtherUserRead(activity.recentMessage.readBy.includes(otherUserUid));
            }
        }) 

        return () => {unsubscribeMessages(); unsubscribeReadby()}//Unsubscribe from msesage stream when activity changes
    }, [activityId])

    const scrollToBottom = () => {
        if(messagesEndRef.current !== null)
            messagesEndRef.current.scrollIntoView();
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            {messages.map((message, index) => {
                
                const mine = message.sentBy === authContext.uid;
                return (
                    <div key={`message_${index}`} ref={index == messages.length - 1 ? messagesEndRef : null}>
                        <Grid container spacing={5} justifyContent={mine ? "flex-end" : "flex-start"}>
                            <Grid item xs={8}>
                                <Paper className={`${classes.message} ${mine ? classes.myMessage : classes.yourMessage}`} elevation={0}>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.messageText}>
                                            <AddLinks text={message.message}/>
                                        </Grid>
                                        <Grid item xs={12} className={classes.readStatus}>
                                            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={10}>
                                                <Grid item className={classes.readStatus}>
                                                    {formatTimeLong(message.sentAt.toDate())}
                                                </Grid>
                                                <Grid item className={classes.readStatus}>
                                                    {(!otherUserRead && mine && index === messages.length - 1) && "Unread"}
                                                    {(otherUserRead && mine && index === messages.length - 1) && "Read"}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </>
    );
}



export default React.memo(Messages);