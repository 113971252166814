import React, { useContext, useEffect, useState } from 'react';
import ImageAnnotator from '../../../../components/ImageAnnotator';
import { DisplayImage } from '../../../../components/ImageAnnotator/ImageAnnotator';
import { fbFirestore, fbFunctions, fbStorage } from '../../../../firebase';
import { AuthContext } from '../../../../AuthProvider';
import firebase from "firebase";
import path from "path"
import { AISpecialty, UnlabelledImage, Econsult, StringDict, SpecialistProfile } from '../../../../../shared/types';
import isOfTypeAISpecialty from '../../../../models/isOfTypeAISpecialty';
import isImage from '../../../../models/isImage';
import useProcessState from '../../../../components/useProcessState';
import { ProcessState } from '@alethea-medical/react-components';
import { EconsultContext } from '../Econsult/EconsultProvider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import isOfTypeAIExcludeSubsite from '../../../../models/isOfTypeAIExcludeSubsite';
import { SpecialistContext } from '../SpecialistProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: "absolute",
            top: 5,
            right: 5
        }
    }),
);


interface EconsultImageAnnotationProps {
    paperHeight: number,
    boxClass: string,
    show: boolean,
    setShow: (show: boolean) => void
}

const EconsultImageAnnotation = ({ paperHeight, boxClass, show, setShow }: EconsultImageAnnotationProps) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const specialistContext = useContext(SpecialistContext);

    const { econsult, ref } = useContext(EconsultContext);

    const predictImage = fbFunctions.httpsCallable("ai-predictImage");

    const [imagesToShow, setImagesToShow] = useState<DisplayImage[]>([]);
    const [pointer, setPointer] = useState<number>(0);
    const [userSpecialty, setUserSpecialty] = useState<AISpecialty>();

    const { processState: loadImageState, setProcessState: setLoadImageState, processErrorMessage: loadImageError } = useProcessState();
    const { processState: loadAIState, setProcessState: setLoadAIState, processErrorMessage: loadAIError, setProcessErrorMessage: setLoadAIError } = useProcessState();
    const [loadAIMessage, setLoadAIMessage] = useState<string>();

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {//Only run once
            if (specialistContext.specialist !== undefined && authContext?.profile?.isSpecialist === true && specialistContext.specialist.enableEconsultDataCollection === true) {
                const specialty = econsult.specialty
                const subsite = econsult.subsite
                if (isOfTypeAISpecialty(specialty) && !isOfTypeAIExcludeSubsite(subsite)) {

                    //Initialize images
                    const newImagesToShow: DisplayImage[] = econsult.referralMediaURIs.filter((uri) => {
                        //Filter out images that have already been labelled
                        return econsult.imageLabels === undefined || econsult.imageLabels[uri] === undefined;
                    }).map((uri) => {
                        const filenameStripped = path.basename(uri).replace(/^[0-9a-fA-F]{16}_/, '');
                        if (isImage(filenameStripped.split('.')[1])) {
                            const displayImage: DisplayImage = {
                                aiPrediction: [],
                                aiVersion: "N/A",
                                created: firebase.firestore.Timestamp.now(),
                                filename: filenameStripped,
                                fileUri: uri,
                                src: "",
                                uid: "",
                                doctorLabels: [],
                                specialty: specialty,
                            }
                            return displayImage;
                        }
                    }).filter((i): i is DisplayImage => i !== undefined)

                    if (newImagesToShow.length > 0) {
                        setImagesToShow(newImagesToShow);
                        setShow(true);
                    }
                    setInitialized(true);
                }
            }
        }
    }, [specialistContext.specialist, econsult])

    useEffect(() => {
        if (show) {
            //Initialize first image
            getNextImage(-1)
                .then(() => {
                    //Get next image afterwards since we don't have it yet. After this, each next press will automatically fetch the next next image
                    if(imagesToShow.length > 1)
                        getNextImage(0);
                })
        }
    }, [show])

    useEffect(() => {
        if (imagesToShow[pointer]?.src === undefined || imagesToShow[pointer]?.src === "") {
            setLoadImageState(ProcessState.running);
        }
        else {
            setLoadImageState(ProcessState.idle);
        }
    }, [imagesToShow[pointer]?.src])

    useEffect(() => {
        if (imagesToShow[pointer]?.aiPrediction === undefined || imagesToShow[pointer].aiPrediction.length === 0) {
            //If current image we are looking at has no ai prediction, and an error is detected, show error
            if (loadAIError !== "") {
                setLoadAIState(ProcessState.error);
            }
            else {
                setLoadAIState(ProcessState.running);
            }
        }
        else {
            setLoadAIState(ProcessState.idle);
        }
    }, [imagesToShow[pointer]?.aiPrediction, loadAIError])


    //Pre-fetch next image
    const getNextImage = (newPointer: number) => {
        const nextPointer = newPointer + 1

        if (nextPointer < imagesToShow.length) {

            const displayImage = imagesToShow[nextPointer];
            return fetchImageSrc(displayImage.fileUri)
                .then((src) => {
                    displayImage.src = src;
                    return fetchAIPrediction(displayImage.filename, displayImage.fileUri)
                        .then(({ aiPrediction, aiVersion, created }) => {
                            displayImage.aiPrediction = aiPrediction
                            displayImage.aiVersion = aiVersion
                            displayImage.created = created
                        })
                        .catch((error: Error) => {
                            console.error(error);
                            setLoadAIError("Error loading AI prediction.");
                        })
                        .finally(() => {
                            handleUpdateImage(displayImage, nextPointer);
                        })
                })

        }
        return Promise.resolve();
    }

    const fetchImageSrc = (fileUri: string) => {
        const storageRef = fbStorage.ref(fileUri);
        return storageRef.getDownloadURL()
            .catch((error: Error) => {
                console.error(error);
            })
    }


    const fetchAIPrediction = (filename: string, fileUri: string): Promise<{
        aiPrediction: string[],
        aiVersion: string,
        created: firebase.firestore.Timestamp
    }> => {
        setLoadAIMessage("Loading AI Prediction...");
        return fbFirestore.collection("unlabelled_images").doc(econsult.specialty).collection("images").where("filename", "==", filename).get()
            .then((snapshot) => {
                if (snapshot.size > 0) {
                    console.log(`Using preexisting unlabelled image data for ${filename}`)

                    const data = snapshot.docs[0].data() as UnlabelledImage;
                    if (data !== undefined) {
                        if(data.aiPrediction.length > 0) {//If AI returned predictions
                            return {
                                aiPrediction: data.aiPrediction,
                                aiVersion: data.aiVersion,
                                created: data.created
                            }
                        }
                        //If no predictions, fall back to deployed AI
                    }
                }

                //If unlabelled image doesn't exist, fall back to deployed AI
                console.log(`Fetching new AI prediction for ${filename}`)
                setLoadAIMessage("Creating new AI prediction...");
                return predictImage({ uri: fileUri, scoreThreshold: 0.5 })
                    .then((result: { data: {
                        aiVersion: string,
                        predictions: any[]
                    } }) => {
                        console.log("Received AI prediction")
                        if(result.data.predictions.length > 0) {//If AI returned predictions
                            return {
                                aiPrediction: result.data.predictions.map((p) => p.aiLabel as string),
                                aiVersion: result.data.aiVersion,
                                created: firebase.firestore.Timestamp.now()
                            }
                        }
                        else {
                            return Promise.reject(new Error("AI could not predict this image."))
                        }
    
                    })
            })

    }


    const handleUpdateImage = (newDisplayImage: DisplayImage, pointer: number) => {
        const newImagesToShow = [...imagesToShow]
        newImagesToShow[pointer] = newDisplayImage;
        setImagesToShow(newImagesToShow);
    }

    const onFinishHandler = () => {
        setShow(false);
    }

    const onLabelImageHandler = (fileUri: string, doctorLabels: string[]) => {
        if (ref !== undefined) {
            const imageLabels: StringDict<string[]> = econsult.imageLabels !== undefined ? econsult.imageLabels : {};
            imageLabels[fileUri] = doctorLabels;
            const updateLabels: Partial<Econsult> = {
                imageLabels: imageLabels
            }
            ref.update(updateLabels)
        }
    }

    const handleClose = () => {
        setShow(false);
    }


    return (
        <>
            {show && (
                <>
                    <Grid item xs={6}>
                        <Box
                            className={boxClass}
                            borderLeft={1}
                            borderBottom={1}
                            height={paperHeight}
                        >
                              <IconButton className={classes.closeButton} onClick={handleClose}><CloseIcon/></IconButton>

                            <ImageAnnotator imagesToShow={imagesToShow} setImagesToShow={setImagesToShow} pointer={pointer} setPointer={setPointer}
                                userSpecialty={userSpecialty} setUserSpecialty={setUserSpecialty}
                                loadImageState={loadImageState} loadImageError={loadImageError}
                                loadAIState={loadAIState} loadAIError={loadAIError} loadAIMessage={loadAIMessage}
                                onNext={getNextImage} onFinish={onFinishHandler} onLabelImage={onLabelImageHandler}
                                layout="vertical" 
                                addPreviousLabels
                            />
                        </Box>
                    </Grid>
                </>
            )}
        </>

    );
}

export default EconsultImageAnnotation;