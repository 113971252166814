import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { fbFunctions, logAnalyticsEvent } from "../../firebase";
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import ReactPlayer from 'react-player'
import { GalleryFile } from "./galleryTypes";
import Label from "../Label";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalContainer: {
			zIndex: 2000
		}
	}),
);



const PREDICTION_THRESHOLD = 0.5;

interface GalleryModalProps {
    show: boolean,
	setShow: (show: boolean) => void,
    file: GalleryFile,
    footer?: JSX.Element,
	formatFileName?: (filename: string) => string,
	doctorLabels?: string[]
}

const DEFAULT_PREDICTION_TEXT = ["Getting prediction..."];
const VIDEO_PREDICTION_TEXT = ["AI prediction is not yet available for video files."];

const GalleryModal = ( { show, file, footer, setShow, formatFileName, doctorLabels }: GalleryModalProps) => {
	const classes = useStyles();
	const [prediction, setPrediction] = useState<string[]>(DEFAULT_PREDICTION_TEXT);
	const [labelType, setLabelType] = useState<"Doctor" | "AI">("AI");

	const predictImage = fbFunctions.httpsCallable("ai-predictImage");
	
	useEffect(() => {
		if(file.fileType === "image") {//Only show prediction for images
			if(show) {
				if(doctorLabels === undefined) {
					setPrediction(DEFAULT_PREDICTION_TEXT);		
					setLabelType("AI");
					getImagePrediction();
				}
				else {
					setPrediction(doctorLabels);
					setLabelType("Doctor");
				}
			}
		}
		else if(file.fileType === "video") {
			setPrediction(VIDEO_PREDICTION_TEXT);
		}

		if(show) {
			if(file.fileType === "image") {
				logAnalyticsEvent(`portal_gallery_photo_selected`);
			}
			else if(file.fileType === "video"){
				logAnalyticsEvent(`portal_gallery_video_selected`);
			}
		}

	}, [show])

    const getImageDateString = (filename: string): string => {
        try {
            let unixTimestamp = parseInt(filename.split('.')[0]);
            let timestamp = new Date(unixTimestamp);
            return `${timestamp.toLocaleDateString()} at ${timestamp.toLocaleTimeString()}`;
        }
        catch(error) {
            return "Unable to get timestamp";
        }   
	}

	const getImagePrediction = () => {
		logAnalyticsEvent(`portal_ai_predict_start`);

		return predictImage({uri: file.uri, scoreThreshold: PREDICTION_THRESHOLD})
		.then((result: {data: {predictions: any[]}}) => {
			const predictions = result.data.predictions;
			if(predictions.length > 0) {

				if(predictions[0].gpLabel === "I can't diagnose this") {
					setPrediction(["I can't diagnose this"]);
					logAnalyticsEvent(`portal_ai_predict_cant_diagnose`);
				}
				else {
					setPrediction(createPredictionText(predictions));
					logAnalyticsEvent(`portal_ai_predict_success`);
				}

			}
			else {
				setPrediction(["I can't diagnose this"]);
				logAnalyticsEvent(`portal_ai_predict_cant_diagnose`);
			}


		})
		.catch((error: Error) => {
			console.error(error);
			setPrediction(["Error predicting image"]);
			logAnalyticsEvent(`portal_ai_predict_failed`);
		})
	}

	const createPredictionText = (predictions: any[]) => {
		return predictions.map((prediction: {gpLabel: string, score: number}) => {
			if(prediction.gpLabel !== "I can't diagnose this")
				return `${prediction.gpLabel}: ${(prediction.score*100).toFixed(2)}%`
			else
				return '';
		});
	}



	return (
		<>
			<Modal show={show} onHide={() => {setShow(false)}} className={classes.modalContainer}>
				<Modal.Header closeButton>
					<Modal.Title className="text-muted">{formatFileName !== undefined ? formatFileName(file.filename) : getImageDateString(file.filename)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
				<div className="mt-3 text-center g-modal-prediction">
						<Grid container spacing={1}>	
					{file.fileType === "video" && 
						<ReactPlayer 
							className={`g-modal-video`}
							url={file.src}
							width='100%'
							height='100%'
							controls
							playing={show}
						/>
					}{file.fileType === "image" && 
						<img className="g-image"
							src={file.src}
						/>
					}
					
					
							<Grid item xs={12}>
								{labelType === "AI" && "AI Prediction"}
								{labelType === "Doctor" && "Specialist Labels"}
							</Grid>
							{prediction.map((prediction: string) => {
								if(prediction !== '') {
									return (
										<Grid item xs={12} key={`ai_label_${prediction}`}>
											<Label text={prediction} color={labelType === "AI" ? "orange" : "dark"}/>
										</Grid>
									)
								}
								else {
									return null;
								}
		
							})}
							<Grid item xs={12}>
								{file.fileType === "image" && labelType === "AI" &&
									<Tooltip title="This is not a diagnosis. Our AI is a visual prediction tool intended for educational purposes only.">
										<div className="tooltip-hover"><InfoIcon/></div>
									</Tooltip>
								}
							</Grid>
						</Grid>

					</div>


				</Modal.Body>
				<Modal.Footer>
					<button className="button-border mr-auto" onClick={() => {setShow(false)}}>
						Close
					</button>
					{footer}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default GalleryModal;