import React  from "react";
import { Controller } from "react-hook-form";

import { ControlledInputProps } from "../shared/types";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import "react-datepicker/dist/react-datepicker.css"

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";


const useStyles = makeStyles((theme) => {
  const shared = sharedStyles(theme);
  return {
    ...shared
  }
});

interface FormDatePickerProps extends ControlledInputProps {
  rules?: any
  label?: string,
  labelAsPlaceHolder?: boolean,
  errors?:any
  required?:boolean
  
}

  const FormDatePicker =(props: FormDatePickerProps) => {
  const { name, control, defaultValue, rules, label, labelAsPlaceHolder, ...rest } = props;
 
  return (

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller     
        control={control}     
        defaultValue={defaultValue ? defaultValue : ""}
        name={name}
        rules={rules}       
        render={({ field, fieldState}) => (
          <KeyboardDatePicker
            placeholder={labelAsPlaceHolder && label ? label : "Select a date"}
            openTo="year"
            autoOk          
            variant="inline"
            inputVariant="outlined"       
            format="MM/dd/yyyy"          
            error={fieldState.error !== undefined}
            helperText={fieldState.error?.message}
            value= {field.value}
            onChange={field.onChange}
          />
        )}
        />
        </MuiPickersUtilsProvider>     

  );
}

export default FormDatePicker


