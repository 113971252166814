import React from "react";
import { SelectedImages, ImageSrc } from "./BaseGallery";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReactPlayer from 'react-player'
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";


type ReferralImageThumbsProps = {
    selectedImages: SelectedImages,
    handleRemove: (image: ImageSrc) => void
}

const ReferralThumbs = (props: ReferralImageThumbsProps) => {
    
    //Show thumbnails
    const thumbs = Object.values(props.selectedImages).map((image) => {

        const fileType = image.name.split('.')[1];

        return (
            <div className="thumb" key={"thumbnail_" + image.fullPath}>

                <div className="thumb-inner">
                    {isImage(fileType) && 
                        <img
                            src={image.src}
                            className="thumb-img"
                            alt={`Referral ${image.name}`}
                        />
					}
					{isVideo(fileType) && 
						<ReactPlayer 
							className="thumb-video"
							url={image.src}
                            width='100%'
                            height='100%'
						/>
					}

                    <button className="thumb-img-remove-button button-material" onClick={(event: any) => {
                        event.preventDefault();
                        props.handleRemove(image);    
                    }}>
                        <svg  width="25px" height="25px">
                            {/* Circle */}
                            <circle className="rg-checkmark-circle-inner" cx="12.5" cy="12.2" r="9"/>
                            <HighlightOffIcon className="button-material"/>
                        </svg>
                </button>
                </div>
            </div>
        );
        
    });


    return (
        <>
            <div className="thumbs-container">
                {thumbs}
            </div>
        </>
    );
}

export default ReferralThumbs;