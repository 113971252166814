import React, { useEffect, useState } from "react";
import EconsultCard from "./EconsultCard";
import { fbFirestore } from "../../../../firebase";

import { Econsult, ReferralFormInputField, ReferralFormSpecialty } from "../../../../../shared/types";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getDocumentData } from "@alethea-medical/utilities";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        checkTypographyOrRadio: {
            paddingLeft: theme.spacing(2)
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        section: {
            marginTop: theme.spacing(1)
        },
        header: {
            color: theme.palette.grey[700]
        },
        content: {
            paddingLeft: theme.spacing(3)
        }
	}),
);

interface EconsultInfoProps {
    econsult: Econsult
}

const EconsultInfo = ({ econsult }: EconsultInfoProps) => {
    const classes = useStyles();
    const bullet = <span className={classes.bullet}>•</span>;
    
    const [sortedSymptoms, setSortedSymptoms] = useState<{header: string, value: any}[]>([]);

    const getSymptomIndex = (header: string, subsite: ReferralFormInputField[]) => {
        return subsite.map((field: ReferralFormInputField) => field.header).indexOf(header);
    }

    useEffect(() => {
        if(econsult.specialty !== "" && econsult.specialty !== undefined) {
            fbFirestore.collection("specialties").doc(econsult.specialty).get().then(getDocumentData)
            .then((specialty: ReferralFormSpecialty) => {
                const subsite: ReferralFormInputField[] = specialty[econsult.subsite];
                const headers = Object.keys(econsult.symptoms).sort((a: string, b: string) => {
                    const indexA = getSymptomIndex(a, subsite);
                    const indexB = getSymptomIndex(b, subsite);
                    return indexA < indexB ? -1 : 1;
                })
                
                setSortedSymptoms(headers.map((header: string) => {
                    return {
                        header: header,
                        value: econsult.symptoms[header]
                    }
                }));
            })            
        }

    }, [econsult])

    return (
        <>
            <Grid item xs={12}>
                <EconsultCard title="Referral Information">
                    <Grid container spacing={1}>
                        {sortedSymptoms.map((keyValue: any) => {
                            const key = keyValue.header;
                            const value = keyValue.value;
                            if(typeof value ===  "string") {//Textfield
                                return (
                                    <Grid item xs={12} key={`textfield_${key}`}>
                                        <Typography className={classes.header}>
                                            {`${key}`}
                                        </Typography>
                                        <Typography className={classes.content}>
                                            {value}
                                        </Typography>
                                    </Grid>
                                );
                            }
                            else {
                                return (
                                    <Grid item xs={12} key={`header_${key}`}>
                                        <Typography className={classes.header}>
                                            {`${key}:`}
                                        </Typography>
                                        {Object.keys(value).map((entry: string) => {
                                            if(typeof value[entry] === "boolean" && value[entry]) {//Checkboxes
                                                return (
                                                    <Typography key={`checkbox_${entry}`} className={classes.content}>
                                                        {bullet}{entry}
                                                    </Typography>
                                                );
                                            }
                                            else if(typeof value[entry] === "string" && value[entry] !== '') {//Radio
                                                return (
                                                    <Typography key={`radio_${entry}`} className={classes.content}>
                                                        {bullet}{entry}: {value[entry]}
                                                    </Typography>
                                                );
                                            }
                                        })}
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                </EconsultCard>           
            </Grid>
            <Grid item xs={12}>
                <EconsultCard title="Description">
                    <Typography className={classes.section}>
                        {econsult.information.description}
                    </Typography>
                </EconsultCard>
            </Grid>
            <Grid item xs={12}>
                <EconsultCard title="Medical History">
                    <Typography className={classes.section}>
                        {econsult.information.medicalHistory}
                    </Typography>
                </EconsultCard>
            </Grid>
            <Grid item xs={12}>
                <EconsultCard title="Questions for Consultant">
                    <Typography className={classes.section}>
                        {econsult.information.questionsForConsultant}
                    </Typography>
                </EconsultCard>
            </Grid>
        </>
    );

}
export default EconsultInfo;