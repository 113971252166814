import React from "react";
import DoneIcon from '@material-ui/icons/Done';

export enum ProcessState {
    idle,
    running,
    success,
    error
}
type ProcessStatusProps = {
    processState: ProcessState
    errorMessage: string,
    children?: any
}

export const ProcessStatus = (props: ProcessStatusProps) => {
    const getInnerComponents = () => {
        if(props.children) {
            return (
                <>{props.children}</>
            );
        }
        else {
            return (
                <DoneIcon/>
            );
        }
    }

    return ( 
        <>
            {props.processState === ProcessState.running && <div className="spinner-grow" role="status"></div>}
            {props.processState === ProcessState.success && <>{getInnerComponents()}</>}
            {(props.processState === ProcessState.error && props.errorMessage !== "") &&
                    <div className="alert alert-danger process-error">{props.errorMessage}</div>}
        </>
    );
}