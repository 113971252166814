import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { app, logAnalyticsEvent } from "../../../firebase";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import { ProcessState, ProcessStatus, PHNInput } from "@alethea-medical/react-components";

import Grid from "@material-ui/core/Grid";
import { GridSize } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper'

import InputLabel from "@material-ui/core/InputLabel";
import EmailInput from "../../../components/EmailInput";
import FormCheck from "../../../components/FormCheckbox";
import FormDatePicker from '../../../components/FormDatePicker';
import FormTextArea from "../../../components/FormTextArea";
import FormTextField from "../../../components/FormTextField";

import { Location } from "../../../../shared/types";

import * as QueryString from "query-string"
import { withRouter } from 'react-router-dom';
import PhoneInput from '../../../components/PhoneInput';
import FormSelect from '../../../components/FormSelect';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			margin: '0 auto',
			marginTop: '50px',
			marginBottom: '50px',
			width: '80%',
			padding: '30px 50px',
			minHeight: "80vh"
		},
		gridItem: {
			textAlign: 'center'
		},
		gridInfo: {
			textAlign: 'right'
		},

		gridTitle: {
			textAlign: 'left'
		},
		paper: {
			margin: theme.spacing(1),
			padding: theme.spacing(4),
		},

		header: {
			marginLeft: theme.spacing(2)
		},
		saveButton: {
			textAlign: 'center',
			margin: theme.spacing(1, 0)
		},
		checkbox: {
			marginLeft: theme.spacing(5)
		},
		sectionFields: {
			marginLeft: theme.spacing(4)
		},
		patientEmail:{
			padding: theme.spacing(0, 1, 0, 0)
		},
		patientCC: {
			padding: theme.spacing(2, 0, 0, 4)
		}
		
	}),
);


interface FormTemplate {
	name: string,
	instructions: string,
	sections: any[],
	comments: string
}

const CareForm = withRouter(({ location }) => {
	const classes = useStyles();

	const renderForm = app.functions().httpsCallable("forms-renderForm");
	const submitForm = app.functions().httpsCallable("forms-submitForm");
	const previewForm = app.functions().httpsCallable("forms-previewForm");

	const { handleSubmit, control } = useForm({ mode: "onTouched" });

	const [status, setStatus] = useState(ProcessState.idle);

	const [formLoadStatus, setFormLoadStatus] = useState(ProcessState.idle);
	const [formLoadError, setFormLoadError] = useState("");

	const [errorMessage, setErrorMessage] = useState('');

	const [formTemplate, setFormTemplate] = useState<FormTemplate>();
	const [physician, setPhysician] = useState({
		firstName: "",
		lastName: ""
	});
	const [clinic, setClinic] = useState<Location>();

	const [isPreview, setIsPreview] = useState<boolean>(false);

	useEffect(() => {
		const params = QueryString.parse(location.search);
		setFormLoadStatus(ProcessState.running);
		if(params.code) {
			renderForm({ jwt: params.code })
			.then((result) => {
				const formInfo = result.data as {
					formTemplate: FormTemplate,
					patientMessageId: string,
					physicianInfo: {
						firstName: string,
						lastName: string,
						location: Location,
						uid: string
					}
				}

				setFormTemplate(formInfo.formTemplate);
				setPhysician({
					firstName: formInfo.physicianInfo.firstName,
					lastName: formInfo.physicianInfo.lastName
				});
				setClinic(formInfo.physicianInfo.location);
				setIsPreview(false);

			})
			.catch((error: Error) => {
				setFormLoadStatus(ProcessState.error);
				setFormLoadError(error.message);
			});
		}
		else if(params.preview === "true" && params.formId && params.locationIdx) {
			previewForm({ formId: params.formId, locationIdx: params.locationIdx })
			.then((result) => {
				const formInfo = result.data as {
					formTemplate: FormTemplate,
					physicianInfo: {
						firstName: string,
						lastName: string,
						location: Location,
						uid: string
					}
				}

				setFormTemplate(formInfo.formTemplate);
				setPhysician({
					firstName: formInfo.physicianInfo.firstName,
					lastName: formInfo.physicianInfo.lastName
				});
				setClinic(formInfo.physicianInfo.location);
				setIsPreview(true);
			})
			.catch((error: Error) => {
				setFormLoadStatus(ProcessState.error);
				setFormLoadError(error.message);
			});
		}
		else {
			setFormLoadStatus(ProcessState.error);
			setFormLoadError("Invalid form");
		}
	}, []);


	const onSubmit = (data: any) => {
		const params = QueryString.parse(location.search);

		setStatus(ProcessState.running);
		setErrorMessage("");
		const fields = data !== undefined ? decodeObject(data).fields : {};

		if(fields.ccPatient === true) {
			logAnalyticsEvent("forms_cc_patient");
		}

		logAnalyticsEvent("forms_submit_start");

		submitForm({
			jwt: params.code,//Pass JWT to validate request and get form and physician information
			formData: fields,
			patientInfo: {
				firstName: fields.firstName ? fields.firstName : "",
				lastName: fields.lastName ? fields.lastName : "",
				phn: fields.phn ? fields.phn : "",
				email: fields.patientEmail ? fields.patientEmail : ""
			},
			ccPatient: fields.ccPatient,
		})
			.then(() => {
				logAnalyticsEvent("forms_submit_success");
				
				setStatus(ProcessState.success);
			})
			.catch((error: Error) => {
				logAnalyticsEvent("forms_submit_failed");

				console.log(error)
				setErrorMessage(error.message);
				setStatus(ProcessState.error);
			})
	}


	const decodeObject = (subObj: any) => {
		//Is object?
		if (subObj.constructor === ({}).constructor) {
			const newObj: any = {}
			Object.keys(subObj).forEach((key) => {
				const newKey = atob(key);//decode base64
				newObj[newKey] = decodeObject(subObj[key]);
			});
			return newObj;
		}//Otherwise its a value, just return
		else {
			//Check if date, if it is, return the time value rather than the date object
			if (typeof subObj.getTime === 'function') {
				return (subObj.getTime())
			}
			return subObj;
		}
	}

	const onError = () => {
		setErrorMessage("Check form for errors.");
		setStatus(ProcessState.error);
	}


	const isDisabled = () => {
		return status === ProcessState.running || status === ProcessState.success;
	}


	const formSafeEncode = (str: string): string => {
		return btoa(str);//encode base64 since react hook form doesn't like special characters in variable names
	}

	const baseName = formSafeEncode("fields")

	const createTextField = (header: string, name: string) => {

		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		if (name === "phn") {
			return (
				<Grid item xs={6}>
					<label>{formatString(header)}</label>
					<PHNInput
						control={control}
						name={fieldName}
						rules={{ required: { value: true, message: "PHN is required" } }}
					/>
				</Grid>
			);
		}
		else {
			return (
				<Grid item xs={6}>
					<label>{formatString(header)}</label>
					<FormTextField
						control={control}
						name={fieldName}
					/> 
				</Grid>
			);

			
		}
	}

	const formatString = (paragraph: string) => {
		const sentences = paragraph.split('<br/>');
		return (
			<Grid item xs={12}>
				{sentences.map((sentence, key) => {
					return <div key={key}>{sentence}</div>
				})}
			</Grid>
		);
	}

	const createLabel = (label: string) => {
		return (
			<Grid item xs={12}>
				<label>{formatString(label)}</label>
			</Grid>);
	}


	const createTextArea = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12}>
				<label>{formatString(header)}</label>
				<FormTextArea
					name={fieldName}
					control={control}
					initRows={3}
					disabled={isDisabled()}
				/>
			</Grid>
		);
	}

	const createEmailField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={6}>
				<EmailInput
					control={control}
					name={fieldName}
					label={header}
				/>
			</Grid>
		);
	}

	const createPatientEmail = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		const ccPatientFieldName = `${baseName}.${formSafeEncode("ccPatient")}`;
		return (
			<Grid item xs={12}>
				<Grid container alignContent="flex-start" alignItems="center">
					<Grid item xs={6} className={classes.patientEmail}>
					<label>{formatString(header)}</label>
						<EmailInput
							control={control}
							name={fieldName}
						/>
					</Grid>
					<Grid item xs={6} className={classes.patientCC}>
						<FormCheck
							name={ccPatientFieldName}
							control={control}
							label={"Send me a copy of my form"}
						/>
					</Grid>
				</Grid>
			</Grid>

		);
	}

	const createPhoneField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={6}>
				<label>{formatString(header)}</label>
				<PhoneInput
					control={control}
					name={fieldName}
				/>
			</Grid>
		);
	}

	const createCheckbox = (value: string, name: string, gridSize?: GridSize) => {

		//react hook form doesn't like its input names having commas and some other special characters
		//Use uri encoding to get around this
		const fieldName = `${baseName}.${formSafeEncode(name)}.${formSafeEncode(value)}`;;

		return (
			<>
				<Grid item xs={gridSize} className={classes.checkbox}>
					<FormCheck
						name={fieldName}
						control={control}
						label={value}
					/>
				</Grid>
			</>
		);
	}
	const createCheckboxes = (header: string, values: string[], name: string) => {
		return (
			<>
				<Grid container spacing={1} alignItems="flex-start"> 
					<Grid item xs={12}>
						<label>{formatString(header)}</label>
					</Grid>
					{values.map((_, i) => 
						createCheckbox(values[i], name, values.length === 2 ? undefined : 12)
					)}
				</Grid>
			</>
		);
	}

	const createDateField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		let dValue;
		if (name === "today") {
			dValue = new Date();
		}
		else {
			dValue = ""

		}
		return (
			<>
				<Grid item xs={6}>
					<Grid item xs={6}>
						<InputLabel>{formatString(header)}</InputLabel>
					</Grid>
					<FormDatePicker
						name={fieldName}
						control={control}
						defaultValue={dValue}
						label={header}
						rules={{ required: { value: true, message: `${header}` + " is required" } }}
					/>
				</Grid>
			</>
		);
	}

	const createBinaryField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12}>
				<FormCheck
					name={fieldName}
					control={control}
					label={header}
				/>
			</Grid>
		);
	}

	const createSelectMenu = (header: string,  values: string[],name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (

			<Grid item xs={6}>
				<label>{header}</label>
				<FormSelect
					name={fieldName}
					control={control}
					options={values}
				/>
			</Grid>


		);
	}

	return (
		<>

			<Paper className={classes.root}>
				{formTemplate === undefined && (
					<>
						<Grid container alignItems="flex-start">
							<Grid item xs={12}>
								{formLoadStatus === ProcessState.running && (
									<Typography variant="subtitle1">
										Loading form
									</Typography>
								)}
								{formLoadStatus === ProcessState.error && (
									<Typography variant="subtitle1">
										We encountered an error while loading your form. Please contact us at <a href={`mailto:${"support@aletheamedical.com"}`}>{"support@aletheamedical.com"}</a> for assistance
									</Typography>
								)}
							</Grid>
							<Grid item xs={12}>
								<ProcessStatus state={formLoadStatus} errorMessage={formLoadError} successMessage={""} />
							</Grid>
						</Grid>
					</>
				)}
				{status === ProcessState.success && (
					<>
						<Grid container alignItems="flex-start" justifyContent="center">
							<Grid item xs={6}>
								<ProcessStatus state={status} errorMessage={errorMessage} successMessage={"Form submitted successfully."} />
							</Grid>
						</Grid>
						<Grid container alignItems="flex-start" justifyContent="center">
							<Typography variant="subtitle1">
								You may now close this page.
							</Typography>
						</Grid>
					</>
				)}
				{status !== ProcessState.success && formTemplate !== undefined && (
					<>
						<Grid container direction="row" alignContent="space-between" >
							<Grid item xs={1}>
								<img src="/Alethea Logo.png" className="img-fluid" alt="Alethea Logo" width="237" height="50" />
							</Grid>
							<Grid item xs={7}>
								<div className={classes.gridTitle}>
									<Typography className={classes.header} variant="h5" color="primary">
										{formTemplate.name}{isPreview ? " - Preview" : ""}
									</Typography>
								</div>
							</Grid>
							<Grid item xs={4} className={classes.gridInfo}>
								<Grid container>
									<Grid item xs={12}>
										{`${physician.firstName} ${physician.lastName}`}
									</Grid>
									<Grid item xs={12}>
										{`${clinic?.clinicName} - ${clinic?.city}`}
									</Grid>
								</Grid>
							</Grid>

						</Grid>
						{formTemplate.instructions? formatString(formTemplate.instructions) : ""}
						<Divider /> <br />
						<form>
							<fieldset disabled={isDisabled()} >
								<Grid container alignItems="flex-start" spacing={4}>
									{formTemplate.sections?.map((section: any, i: any) =>
										<>
											<Grid key={`section_${i}`} item xs={12}>
												<h5 key={i}>{section.sectionName}</h5>
												
												<p style={{whiteSpace: 'pre-wrap', fontFamily: 'Roboto, sans-serif',fontSize: 15}}>{(section.description ? formatString(section.description) : "")}</p><br />
												<Grid container alignItems="flex-start" spacing={2} className={classes.sectionFields}>
													{
														section.fields ?
														section.fields.map((field: any) => {
															switch (field.inputType) {
																case "Text":
																	if (field?.label)
																		return (createTextField(field.label, field.fieldName))
																	return null;
																case "Checkbox":
																	if (field?.label && field?.options)
																		return (createCheckboxes(field.label, field.options, field.fieldName))
																	return (createBinaryField(field.label, field.fieldName))
																case "Date":
																	if (field?.label)
																		return (createDateField(field.label, field.fieldName))
																	return null;
																case "Email":
																	if (field?.label)
																		return (createEmailField(field.label, field.fieldName))
																	return null;
																case "PatientEmail":
																	if (field?.label)
																		return (createPatientEmail(field.label, field.fieldName))
																	return null;
																case "Phone":
																	if (field?.label)
																		return (createPhoneField(field.label, field.fieldName))
																	return null;
																case "TextArea":
																	if (field?.label)
																		return (createTextArea(field.label, field.fieldName))
																	return null;
																case "Label":
																	if (field?.label)
																		return (createLabel(field.label))
																	return null;
																case "Select":
																	if (field?.label && field?.options) 
																		return (createSelectMenu(field.label,field.options,field.fieldName))
																	return null;	
																default:
																	return null;
															}
														}) : ""}

												</Grid>
											</Grid>
										</>
									)}
								</Grid>
								<br />
								<Grid item xs={12}>
									{formTemplate.comments? formatString(formTemplate.comments) : ""}
								</Grid>
								<br />
								<Grid item xs={12} className={classes.saveButton}>
									<Button id="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit, onError)} disabled={isDisabled() || isPreview}>Submit</Button>
								</Grid>
								<Grid item xs={12}>
									<ProcessStatus state={status} errorMessage={errorMessage} successMessage={""} />
								</Grid>
							</fieldset>
						</form>
					</>
				)}
			</Paper>
		</>
	);
})

export default CareForm;








