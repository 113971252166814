import React, { useState, useEffect, useContext }from "react";
import { useForm } from "react-hook-form";
import { Body, ProcessState, ProcessStatus } from "../../Global/components"
import ProfileLocations from "./ProfileLocations";
import { Col, Form } from "react-bootstrap";
import { AuthContext } from "../../../AuthProvider";
import { fbFirestore } from "../../../firebase";
import getProfile from "../../../models/getProfile";
import { UserProfile } from "../../../../shared/types";

import Button from "@material-ui/core/Button"

import FormNumberField from "../../../components/FormNumberField";
import FormCheckbox from "../../../components/FormCheckbox";

const Profile = () => {
    const { handleSubmit, control, setValue } = useForm({mode: "onTouched"});
    const authContext = useContext(AuthContext);
    const [status, setStatus] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState('');
    
    useEffect(() => {
        if(authContext.profile !== undefined)
        {
            setValue("practiceId", authContext.profile.practiceId);
            setValue("preferences.fax.secureMessaging", authContext.profile?.preferences?.fax?.secureMessaging === true);
            setValue("preferences.fax.forms", authContext.profile?.preferences?.fax?.forms === true);
            setValue("preferences.email.secureMessaging", authContext.profile?.preferences?.email?.secureMessaging === true);
            setValue("preferences.email.failedEmails", authContext.profile?.preferences?.email?.failedEmails === true);
            if(authContext.profile?.locations !== undefined) {
                authContext.profile.locations.forEach((location, index) => {
                    setValue(`locations.${index}.clinicName`, location.clinicName);
                    setValue(`locations.${index}.clinicPhone`, location.clinicPhone);
                    setValue(`locations.${index}.fax`, location.fax);
                    setValue(`locations.${index}.city`, location.city);
                    setValue(`locations.${index}.province`, location.province);
                    setValue(`locations.${index}.billable`, location.billable);
                });
            }
        }
            
    }, [authContext.profile]);

    const onSubmit = (data: any) => {
        setStatus(ProcessState.running);

        fbFirestore.collection("users").doc(authContext.uid).update(data)
        .then(() => {
            setStatus(ProcessState.success);
            getProfile(authContext.uid)
            .then((result: UserProfile | undefined) => {
                if(result !== undefined)
                    authContext.profile = result;                  
            })
            setTimeout(() => {
                setStatus(ProcessState.idle);
            }, 2000);
        })
        .catch((error) => {
            console.log("Error updating profile: ", error);
            setStatus(ProcessState.error);
            setErrorMessage("Error updating profile.");
        });
        
    }

    const onError = () => {
        setErrorMessage("Check form for errors.");
        setStatus(ProcessState.error);
    }

    const isDisabled = () => {
        return status === ProcessState.running || status === ProcessState.success;
    }

    return (
        <Body title="Edit Profile" subtitle="">
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()} >
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Row>
                                <Form.Group as={Col} md="12" controlId="practiceId">
                                    
                                    <FormNumberField
                                        name="practiceId"
                                        control={control}
                                        label="Practice ID"
                                        rules={{required: {value: true, message: "Practice ID is required"}}}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md="6">
                                    <FormCheckbox
                                        name="preferences.fax.secureMessaging"
                                        control={control}
                                        label="Receive copies of eConsults by fax."
                                        defaultValue={true}
                                    />
                                </Form.Group>     
                                <Form.Group as={Col} md="6">
                                    <FormCheckbox
                                        name="preferences.fax.forms"
                                        control={control}
                                        label="Receive copies of patient forms by fax."
                                        defaultValue={true}
                                    />
                                </Form.Group>      
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6">
                                    <FormCheckbox
                                        name="preferences.email.secureMessaging"
                                        control={control}
                                        label="Receive email notifications for new messages."
                                    />
                                </Form.Group>      
                                <Form.Group as={Col} md="6">
                                    <FormCheckbox
                                        name="preferences.email.failedEmails"
                                        control={control}
                                        label="Receive email notifications for patient emails that have failed to send."
                                    />
                                </Form.Group>      
                            </Form.Row>
                            
                            <Form.Row>
                                <Form.Group as={Col} md="auto">
                                    <Button variant="contained" color="primary" type="submit">Save</Button>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="auto" controlId="status">
                                    <ProcessStatus processState={status} errorMessage={errorMessage}><div className="alert alert-success">Profile updated successfully.</div></ProcessStatus>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <ProfileLocations
                                control={control}
                            />
                        </Form.Group>
                    </Form.Row>

                </fieldset>
            </Form>
        </Body>
    );
}

export default Profile;