export const firebaseConfigProd = {
    apiKey: "AIzaSyCLhUC0vO5g3-KeqanVx_eTYoTLyzCC-8s",
    authDomain: "entid-9c711.firebaseapp.com",
    databaseURL: "https://entid-9c711.firebaseio.com",
    projectId: "entid-9c711",
    storageBucket: "entid-9c711.appspot.com",
    messagingSenderId: "583655158573",
    appId: "1:583655158573:web:e52affe4940852390559a4",
    measurementId: "G-J2E3BXLZN7"
};

export const firebaseConfigDev = {
    apiKey: "AIzaSyCYOBWJIP_a0lUTKqNFXog61HbPhycc12w",
    authDomain: "entid-dev-9c711.firebaseapp.com",
    databaseURL: "https://entid-dev-9c711.firebaseio.com",
    projectId: "entid-dev-9c711",
    storageBucket: "entid-dev-9c711.appspot.com",
    messagingSenderId: "138705568071",
    appId: "1:138705568071:web:c3a960c748b2419a61cc62"
};

export const firebaseConfigStaging = {
    apiKey: "AIzaSyAITjGlPXkcDwX8ZiAwIEgtBx5cXAtbmMU",
    authDomain: "entid-staging.firebaseapp.com",
    projectId: "entid-staging",
    storageBucket: "entid-staging.appspot.com",
    messagingSenderId: "1085705409173",
    appId: "1:1085705409173:web:bd220d81898720e4dbc19c",
    measurementId: "G-4P0TEWN6GK"
}

