import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TemplateOption from './TemplateOption';
import { SpecialistTemplateField, SpecialistTemplateOption } from "../../../../../shared/types";

interface TemplateFieldProps {
    field: SpecialistTemplateField,
    optionSelectedHandler: (option: SpecialistTemplateOption) => void
}

const TemplateField = ({ field, optionSelectedHandler }: TemplateFieldProps) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography>
                    {field.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {field.options.map((option) => {
                        return (
                            <Grid item xs={12} key={`template_field_${field.name}_option_${option.name}`}>
                                <TemplateOption option={option} optionSelectedHandler={optionSelectedHandler}/>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    )
    
}

export default TemplateField;