import React, { useContext } from "react";
import {Route, Switch } from "react-router-dom";
import AnonMailer from "../Pages/AnonMailer";
import FormSender from "../Pages/FormSender";
import Dashboard from './Dashboard';
import Profile from '../Pages/Profile';
import ReferralForm from '../Pages/Referral';
import Gallery from "../Pages/Gallery/Gallery";
import  ImageAnnotation from '../Pages/ImageAnnotation';
import SecureMessaging from "../Pages/SecureMessaging";
import PatientMessages from "../Pages/PatientMessages";
import { AuthContext } from "../../AuthProvider";
import OtoscopeCamera from "../Pages/Otoscope";
import BillingReport from "../Pages/BillingReport";

export const DashboardRoutes = () => {
    const authContext = useContext(AuthContext);

    return (
        <Dashboard>
            <Switch>
                <Route path="/dashboard/profile" component={Profile} />
                <Route path="/dashboard/camera-gallery" component = {OtoscopeCamera}/>
                {authContext?.profile?.isSpecialist &&
                    <Route path="/dashboard/image-annotation" component={ImageAnnotation}/>}
                {authContext?.profile?.roles?.resident !== true && (
                    <>
                        <Route path="/dashboard/billing-report" component = {BillingReport}/>
                        <Route path="/dashboard/form-sender" component={FormSender} />
                        <Route path="/dashboard/one-way-messaging" component={AnonMailer}/>
                        <Route path="/dashboard/econsult" component={ReferralForm}/>
                        <Route path="/dashboard/secure-messaging" component={SecureMessaging}/>
                        <Route path="/dashboard/patient-messages" component={PatientMessages}/>
                        
                    </>
                )}
                {/* Don't add anything below here, it won't show up */}

            </Switch>
        </Dashboard>
    );
}