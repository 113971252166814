import React, {useState, useEffect} from "react";
import { RemoveButton } from "../../views/Global/components";
import {useDropzone, FileWithPath} from "react-dropzone";
import "../../thumbs.css";


type AttachmentWithPreview = {
    file: FileWithPath,
    preview: string,
    isImage: boolean,
}

export type AttachmentList = {
    [key: string]: AttachmentWithPreview;
}

type FileDropProps = {
    reset: boolean,
    attachments: AttachmentList,
    acceptedFileTypes?: string[],
    setAttachments: (attachments: AttachmentList) => void,
}

//Key generator class for attachment hash
class KeyGen
{
    currentKey = 0;

    GetKey(): string
    {        
        let key = this.currentKey.toString();
        this.currentKey+=1;
        return key;
    }
}
export const FileDrop = (props: FileDropProps) => {
    const { reset, attachments, acceptedFileTypes, setAttachments } = props;

    const [keyGen] = useState<KeyGen>(new KeyGen())

    const handleUpload = (acceptedFiles: FileWithPath[]) => {

        let newAttachments: AttachmentList = {...attachments};

        let attachmentList: AttachmentWithPreview[] = acceptedFiles.filter((file: FileWithPath) => {
            if(acceptedFileTypes) {
                if(!acceptedFileTypes.includes(file.name.split('.')[1])) {
                    alert(`Cannot upload file: ${file.name}.\nOnly files of the following types are accepted:\n${acceptedFileTypes.join(', ')}`);
                    return false;
                }
            }
            return true;
        }).map((file: FileWithPath) => { 
            let isImage = false;
            

            if(/image\/.*/.test(file.type))
                isImage = true;


            return {
                file: file, 
                preview: URL.createObjectURL(file), 
                isImage: isImage,
            }
        });
        

        attachmentList.forEach((attachment: AttachmentWithPreview) => {
            newAttachments[keyGen.GetKey()] = attachment;
        });
        setAttachments({...newAttachments});
    }

    //Called when files are dropped into the dropzone
    const {getRootProps, getInputProps} = useDropzone({
        onDrop: handleUpload
    });

    //Handle removing file when x button is pressed
    const handleRemove = (key: string) => {
        let newAttachments: AttachmentList = {...attachments};
        URL.revokeObjectURL(newAttachments[key].preview)
        delete newAttachments[key]
        setAttachments(newAttachments);
    }


    //Show thumbnails
    const thumbs = Object.keys(attachments).map((key: string) => {
        let attachment = attachments[key];
        if(attachment.isImage) {
            return (
                <div className="thumb" key={"attachment_" + key}>
                    <div className="thumb-inner">
                        <img
                            src={attachment.preview}
                            className="thumb-img"
                        />
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    });

    //Show attachment names
    const attachmentNames = Object.keys(attachments).map((key: string) => {
        let attachment = attachments[key];
        return <div key={key}><RemoveButton handleRemove={(event: any) => {
            event.preventDefault();
            handleRemove(key);    
        }}/> {<a href={attachment.preview} target="_blank" rel="noopener noreferrer">{attachment.file.name}</a>}</div>
    })


    
    //Reset files (remove all)
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        Object.values(attachments).forEach((attachment: AttachmentWithPreview) => URL.revokeObjectURL(attachment.preview));
        setAttachments({});
    }, [reset]);


    return (
        <>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag and drop, or click to upload files from your computer</p>
            </div>
            <div>
                {attachmentNames}
            </div>
            <div className="thumbs-container">
                {thumbs}
            </div>
        </>
    );
}


