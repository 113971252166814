import React from "react";
import { Modal } from "react-bootstrap"



interface SuccessModalProps {
    text: string,    
    show: boolean
}

const SuccessModal = ({text, show}: SuccessModalProps) => {

    return (
        <Modal show={show}>
            <Modal.Body className="text-center">
                {text}
            </Modal.Body>
        </Modal>
    );
}

export default SuccessModal;
