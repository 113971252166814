import React from 'react';
import Button from "@material-ui/core/Button";
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
    return {
        button: {
            marginRight: "10px",
        }  
    }
})

interface SendButtonProps {
    disabled?: boolean
    label?: string
}
const SendButton = ({label, disabled}: SendButtonProps) => {
    
    const classes = useStyles();
    return(
        <Button type="submit" color="primary" disabled={disabled}><SendIcon className={classes.button}/>{label}</Button>
    );
}

export default SendButton;