import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) =>{
    return {
        ...sharedStyles(theme)
    }
});


interface FormSelectAutocompleteProps extends ControlledInputProps {
    options: any[],
    getOptionLabel?: (option: any) => string,
    getOptionValue?: (option: any) => any,
    disableWhenOne?: boolean, //Disable the field when only one option is available
    rules?: any,
    disabled?: boolean,
    isLoading?: boolean,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const FormSelectAutocomplete = ({ name, control, options, getOptionLabel, getOptionValue, disableWhenOne, defaultValue, label, rules, disabled,isLoading,setLoading, ...rest }: FormSelectAutocompleteProps) => {
    const classes = useStyles();
    
    
    const tryGetOptionValue = (option: any) => {
        if(option !== undefined)
            return getOptionValue ? getOptionValue(option) : option
        else
            return ''
    }

    return (
        <Controller
            name = {name}
            control = {control} 
            rules={rules}
            render = {({field, fieldState})=>(
                <Autocomplete
                    {...field}
                    {...rest}
                    options = {options}
                    getOptionLabel = {getOptionLabel}
                    groupBy = {option => option.favoritedStatus}
                    defaultValue={defaultValue ? defaultValue : tryGetOptionValue(options[0])}
                    disableClearable
                    disabled = {isLoading}
                    renderInput = {(params)=> (
                        <TextField
                            {...params}
                            label = {label}
                            className={classes.canDisable}
                            disabled={(disableWhenOne && options.length <= 1) || disabled}
                            error={fieldState.error !== undefined}
                            helperText={fieldState.error?.message}
                            margin="dense"
                            fullWidth
                            variant="outlined"  
                        >
                        {options.map((option: any, index: number) => {
                            return <MenuItem key={`${option}_${index}`} value={tryGetOptionValue(option)}>
                                {getOptionLabel ? getOptionLabel(option) : option}
                                </MenuItem>
                                     })}
                                  
                        </TextField>
                    )}
                   
                   onChange={(_, data) => field.onChange(data)}
                  
                />
             
                                
                           
            )}
            
        />    
    );
                
} 

export default FormSelectAutocomplete;
