import React, { useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FileType } from "../../../shared/types";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            padding: theme.spacing(0.5)
        },
        dragOverlay: {
            padding: theme.spacing(1),
            margin: 0,
            top: 0,
            left: 0,
            overflow: "hidden",
            position: "absolute",
            width: "100%",
            height: "100%",
            borderWidth: theme.spacing(0.2),
            borderStyle: "dashed",
            borderRadius: theme.spacing(1),
            zIndex: 200
        },
        dragOverlayActive: {
            backgroundColor: "rgba(134, 208, 200, 0.2)",
            borderColor: theme.palette.secondary.main,
        },
        dragOverlayText: { 
            height: "100%"
        },
        fileThumb:  {
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            margin: "auto",
        },
        uploadIcon: {
            paddingBottom: theme.spacing(0.5)
        },
        dragOverlayWrapper: {
            position: "relative",
            minHeight: "200px"
        },
    }),
);

interface FileWithPreview {
    file: FileWithPath,
    filename: string,
    src: string,
    fileType: FileType
}

export interface FileDict {
    [key: string]: FileWithPreview
}

interface FileDropProps {
    acceptedFileTypes?: string[],
}

export interface FileDropReturnTypes {
    files: FileDict,
    setFiles: (files: FileDict) => void,
    getRootProps: () => any, 
    getInputProps: () => any, 
    isDragActive: boolean, 
    removeFile: (key: string) => void,
    removeFiles: (keys: string[]) => void,
    resetFiles: () => void, 
    createFileList: () => JSX.Element, 
    createFileThumbs: () => JSX.Element, 
    createDropzone: (text?: string) => JSX.Element, 
    openFileDialog: () => void
}

class KeyGen
{
    currentKey = 0;

    GetKey(): string
    {        
        let key = this.currentKey.toString();
        this.currentKey+=1;
        return key;
    }
}

//See https://github.com/okonet/attr-accept for more information on accepted file types
function useFileDrop ( { acceptedFileTypes }: FileDropProps ): FileDropReturnTypes {

    const classes = useStyles();

    const [files, setFiles] = useState<FileDict>({});

    //Used to generate keys for the file dictionary so we don't have to worry about conflicting keys
    const [keyGen] = useState<KeyGen>(new KeyGen());

    const handleUpload = (acceptedFiles: FileWithPath[]) => {

        let newFiles: FileDict = {...files};

        let newFilesList: FileWithPreview[] = acceptedFiles.map((file: FileWithPath) => { 

            const fileWithPreview: FileWithPreview = {
                file: file, 
                filename: file.name,
                src: URL.createObjectURL(file), 
                fileType: getFileType(file.name)
            }

            return fileWithPreview;
        });
        
        
        newFilesList.forEach((file: FileWithPreview) => {
            newFiles[keyGen.GetKey()] = file;
        });

        setFiles({...newFiles});
    }

    const getFileType = (filename: string): FileType => {
        if(/.*\.jpg|.*\.png|.*.jpeg/.test(filename)) {
            return "image"
        }
        else if(/.*\.mp4/.test(filename)) {
            return "video";
        }
        else {
            return "other";
        }
    }

    //Called when files are dropped into the dropzone
    const {getRootProps, getInputProps, isDragActive, open: openFileDialog} = useDropzone({
        accept: acceptedFileTypes,
        noClick: true,
        onDrop: handleUpload,
    });

    const removeFile = (key: string) => {
        let newFiles: FileDict = {...files};
        URL.revokeObjectURL(newFiles[key].src);
        delete newFiles[key]
        setFiles(newFiles);
    }

    const removeFiles = (keys: string[]) => {
        let newFiles: FileDict = {...files};
        keys.forEach((key) => {
            URL.revokeObjectURL(newFiles[key].src)
            delete newFiles[key]
        });
        setFiles(newFiles);
    }

    const resetFiles = () => {
        Object.values(files).forEach((file) => URL.revokeObjectURL(file.src));
        setFiles({});
    }
    
    const createDropzone = (text?: string) => {
        return (
            <div {...getRootProps()} onClick={openFileDialog}>
                <input {...getInputProps()} />
                <div
                    className={`${classes.dragOverlayWrapper}`}
                >
                    <div className={`${classes.dragOverlay} ${isDragActive ? classes.dragOverlayActive : ""}`}>
                        <Grid container className={classes.dragOverlayText} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Typography variant="subtitle1">
                                    {text} <InsertDriveFileIcon className={classes.uploadIcon} color="primary"/>
                                </Typography>                                        
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }

    const createFileList = () => {
        return (
            <Grid container>
                {Object.keys(files).map((key: string) => {
                    const file = files[key];
                    if(file.fileType !== "other") {
                        return null;
                    }
                    else {
                        return (
                            <Grid item xs={12} key={`file_drop_${key}`}>
                                <IconButton className={classes.iconButton} onClick={(e) => {
                                    removeFile(key);    
                                }}>
                                    <HighlightOffIcon color="primary"/>
                                </IconButton>
                                <a href={file.src} target="_blank" rel="noopener noreferrer">{file.file.name}</a>
                            </Grid>
                        );
                    }
                })}
            </Grid>

        )
    }

    const createFileThumbs = () => (<ThumbnailGrid files={files} handleRemove={removeFile}/>)

    return {
        files, setFiles, getRootProps, getInputProps, isDragActive, resetFiles, removeFile, removeFiles, createFileList, createFileThumbs, createDropzone, openFileDialog
    }
}

export default useFileDrop;