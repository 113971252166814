import React from "react";
import { Econsult, ActivityUser } from "../../../../../shared/types";
import EconsultCard from "./EconsultCard";

import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({

	}),
);


interface EconsultProfileProps {
    otherUser: ActivityUser,
    econsult: Econsult
}

const EconsultProfile = ({ otherUser, econsult }: EconsultProfileProps) => {
    const classes = useStyles();
    return (
        <>
            <EconsultCard title={otherUser.isSpecialist ? "Referred to" : "Referring Physician"}>
                <Typography>
                    {otherUser.firstName} {otherUser.lastName}
                </Typography>
                <Typography>{otherUser.email}</Typography>
                <Typography>{otherUser.location.clinicName} - {otherUser.location.city}, {otherUser.location.province}</Typography>
                <Typography>Fax: {otherUser.location.fax}</Typography>
            </EconsultCard>                  
        </>
    );
}

export default EconsultProfile;