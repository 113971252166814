import React from "react";
import {Divider, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    content: {
        marginTop: theme.spacing(3)
    },
    title: {
        color: theme.palette.text.primary
    }
}));

const Body = (props: any) => {
    const classes = useStyles();

    return(
        <div className={classes.container}>
            <Typography variant="h5" className={classes.title}>
                {props.title}
            </Typography>  
            <Divider/>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    );
}

export default Body;