import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SearchItem from "./SearchItem";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1)
        },
        iconButton: {
            paddingRight: theme.spacing(1)
        }   
    }),
);


interface SearchBarProps {
    enableSearch: boolean,    
    searchOptions: SearchOption[],
    runSearch: (filter: (obj: any) => boolean) => void,
    clearSearch: () => void,
    onSizeChanged: () => void,
}

export interface SearchOption {
    field: string,
    display?: string,
    options?: {
        value: string,
        display?: string
    }[]
}

export interface SearchParam {
    field: string,
    value: string
}

export const SearchBar = ({ enableSearch, searchOptions, runSearch, clearSearch, onSizeChanged }: SearchBarProps) => {

    const classes = useStyles();
    const [searchParams, setSearchParams] = useState<SearchParam[]>([]);


    const handleField = (field: string, index: number) => {
        const newSearchParams = [...searchParams];
        newSearchParams[index] = {
            field: field,
            value: ""
        }
        setSearchParams(newSearchParams);
    }

    const handleValue = (value: string, index: number) => {
        const newSearchParams = [...searchParams];
        newSearchParams[index].value = value;
        setSearchParams(newSearchParams);
    }
    
    const addItem = () => {
        const newSearchParams = [...searchParams];
        newSearchParams.push({
            field: searchOptions[0].field,
            value: ""
        })
        setSearchParams(newSearchParams);
        onSizeChanged();
    }
    const removeItem = (index: number) => {
        const newSearchParams = [...searchParams];
        newSearchParams.splice(index, 1);
        setSearchParams(newSearchParams);
        if(newSearchParams.length === 0 && enableSearch)
            clearSearch();
        onSizeChanged();
    }

    
    const handleSearch = () => {
        const filter = (obj: any) => {
            if(obj === undefined)
                return false;
            return searchParams.every((param) => {
                if(param.field === "phn") {
                    param.value = param.value.replace(/-/g, "");
                }
                const fields = param.field.split(".");
                const objectValue = getValueFromDeepField(fields, obj);
                if(objectValue !== undefined)
                    return objectValue.toLowerCase().includes(param.value.toLowerCase());
                else
                    return false;
            })
        }
        runSearch(filter);
    }

    //Allows searching object hierarchy
    const getValueFromDeepField = (fields: string[], obj: any): string | undefined => {
        const currentField = fields[0];
        if(fields.length > 1) {
            fields.splice(0, 1);
            return getValueFromDeepField(fields, obj[currentField]);
        }
        else
            return obj[currentField];
    }

    useEffect(() => {
        if(!enableSearch) {
            setSearchParams([]);
        }
    }, [enableSearch])

    return (
        <>                
            <Grid container spacing={1}>
                {searchParams.map((param, index) => 
                    //Pass in value as part of key to handle updating the internal state when an item is removed
                    <Grid item xs={12}>    
                        <SearchItem key={`search_item_${index}_${param.value}`} index={index} 
                            searchOptions={searchOptions} 
                            searchParam={param} 
                            setSearchField={handleField} setSearchValue={handleValue} 
                            removeItem={removeItem}/>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="primary" onClick={addItem}><AddCircleOutlineIcon className={classes.iconButton}/>Add Search Term</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSearch}>Search</Button>
                        </Grid>
                        <Grid item>
                            {enableSearch && 
                                <Button variant="outlined" color="primary" onClick={clearSearch}>Clear</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}