import { useState } from "react";
import usePrevious from "../../../components/usePrevious";
import { PatientMessageFolder } from "../../../../shared/types";
import { PatientMessageDict } from "./PatientMessages";


function useUndoMoveFolder (state: PatientMessageDict, setState: (prevState: PatientMessageDict) => void, undoCallback: (prevFolder: PatientMessageFolder, changedIds: string[]) => void) {

    const prevState = usePrevious<PatientMessageDict>(state);
    const [undoIds, setUndoIds] = useState<string[]>([]);
    const [prevFolder, setPrevFolder] = useState<PatientMessageFolder>();

    const undo = () => {
        if(prevFolder !== undefined && prevState !== undefined) {
            setState(prevState);
            undoCallback(prevFolder, undoIds);
        }

    }

    return { undo, setUndoIds, setPrevFolder };
}

export default useUndoMoveFolder;