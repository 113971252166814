import React, { useEffect, useState } from "react";
import { SpecialistProfile } from "../../../../shared/types";
import { fbFirestore } from "../../../firebase";
import { getDocumentData } from "@alethea-medical/utilities";

interface ContextProps {
    specialist?: SpecialistProfile
};

export const SpecialistContext = React.createContext<ContextProps>({
    specialist: undefined
});

interface SpecialistProviderProps {
    uid?: string
}

export const SpecialistProvider: React.FunctionComponent<SpecialistProviderProps>  = ({ uid, children }) => {

    const [specialist, setSpecialist] = useState<SpecialistProfile>();

    useEffect(() => {
        if(uid !== "") {
            fbFirestore.collection("specialists").doc(uid).get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    return getDocumentData(snapshot)
                        .then((specialist: SpecialistProfile) => {
                            setSpecialist(specialist);
                        })
                }
            })
            .catch((error: Error) => {
                console.error(error);
            })
        }

    }, [uid]);



    return (
        <SpecialistContext.Provider 
            value={{
                specialist
            }}>
                {children}
        </SpecialistContext.Provider>
    );
}