import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ImageSrc } from "./BaseGallery";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";
import "./Gallery.css";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import ReactPlayer from 'react-player'
import Grid from '@material-ui/core/Grid';
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";
import Label from "../../../components/Label";

const PREDICTION_THRESHOLD = 0.5;

type ImageModalProps = {
	show: boolean,
	image: ImageSrc,
	footer?: JSX.Element,
	handleClose: () => void,
	formatFileName?: (filename: string) => string
	doctorLabels?: string[]
}

const DEFAULT_PREDICTION_TEXT = ["Getting prediction..."];
const VIDEO_PREDICTION_TEXT = ["AI prediction is not yet available for video files."];

const GalleryModal = ({ show, image, footer, handleClose, formatFileName, doctorLabels }: ImageModalProps) => {

	const predictImage = fbFunctions.httpsCallable("ai-predictImage");

	const [prediction, setPrediction] = useState<string[]>(DEFAULT_PREDICTION_TEXT);
	const [labelType, setLabelType] = useState<"Doctor" | "AI">("AI");

	const fileType = image.name.split('.')[1];

	useEffect(() => {
		if (isImage(fileType)) {//Only show prediction for images
			if (show) {
				if (doctorLabels === undefined) {
					setPrediction(DEFAULT_PREDICTION_TEXT);
					setLabelType("AI");
					getImagePrediction();
				}
				else {
					setPrediction(doctorLabels);
					setLabelType("Doctor");
				}
			}
		}
		else if (isVideo(fileType)) {
			setPrediction(VIDEO_PREDICTION_TEXT);
		}

		if (show) {
			if (isImage(fileType)) {
				logAnalyticsEvent(`portal_gallery_photo_selected`);
			}
			else if (isVideo(fileType)) {
				logAnalyticsEvent(`portal_gallery_video_selected`);
			}
		}

	}, [show])

	const getImageDateString = (filename: string): string => {
		try {
			let unixTimestamp = parseInt(filename.split('.')[0]);
			let timestamp = new Date(unixTimestamp);
			return `${timestamp.toLocaleDateString()} at ${timestamp.toLocaleTimeString()}`;
		}
		catch (error) {
			return "Unable to get timestamp";
		}
	}

	const getImagePrediction = () => {
		logAnalyticsEvent(`portal_ai_predict_start`);

		return predictImage({ uri: image.fullPath, scoreThreshold: PREDICTION_THRESHOLD })
			.then((result: { data: { predictions: any[] } }) => {
				const predictions = result.data.predictions;
				if (predictions.length > 0) {

					if (predictions[0].gpLabel === "I can't diagnose this") {
						setPrediction(["I can't diagnose this"]);
						logAnalyticsEvent(`portal_ai_predict_cant_diagnose`);
					}
					else {
						setPrediction(createPredictionText(predictions));
						logAnalyticsEvent(`portal_ai_predict_success`);
					}

				}
				else {
					setPrediction(["I can't diagnose this"]);
					logAnalyticsEvent(`portal_ai_predict_cant_diagnose`);
				}


			})
			.catch((error: Error) => {
				console.error(error);
				setPrediction(["Error predicting image"]);
				logAnalyticsEvent(`portal_ai_predict_failed`);
			})
	}

	const createPredictionText = (predictions: any[]) => {
		return predictions.map((prediction: { gpLabel: string, score: number }) => {
			if (prediction.gpLabel !== "I can't diagnose this")
				return `${prediction.gpLabel}: ${(prediction.score * 100).toFixed(2)}%`
			else
				return '';
		});
	}



	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="text-muted">{formatFileName !== undefined ? formatFileName(image.name) : getImageDateString(image.name)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mt-3 text-center g-modal-prediction">
						<Grid container spacing={1}>

							{isImage(fileType) &&
								<img className="g-image"
									src={image.src}

								/>
							}
							{isVideo(fileType) &&
								<ReactPlayer
									className={`g-modal-video`}
									url={image.src}
									width='100%'
									height='100%'
									controls
									playing={show}
								/>
							}



							<Grid item xs={12}>
								{labelType === "AI" && "AI Prediction"}
								{labelType === "Doctor" && "Specialist Labels"}
							</Grid>
							{prediction.map((prediction: string) => {
								if (prediction !== '') {
									return (
										<Grid item xs={12} key={`ai_label_${prediction}`}>
											<Label text={prediction} color={labelType === "AI" ? "orange" : "dark"} />
										</Grid>
									)
								}
								else {
									return null;
								}

							})}
							<Grid item xs={12}>
								{isImage(fileType) && labelType === "AI" &&
									<Tooltip title="This is not a diagnosis. Our AI is a visual prediction tool intended for educational purposes only.">
										<div className="tooltip-hover"><InfoIcon /></div>
									</Tooltip>
								}
							</Grid>

						</Grid>
					</div>

				</Modal.Body>

				<Modal.Footer>

					<button className="button-border mr-auto" onClick={handleClose}>
						Close
					</button>

					{footer}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default GalleryModal;