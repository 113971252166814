import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AuthProvider} from './AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<AuthProvider> <App /> </AuthProvider>, document.getElementById('root'));


