import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SpecialistTemplateOption } from '../../../../../shared/types';
import { deepOrange } from '@material-ui/core/colors';
import { logAnalyticsEvent } from '../../../../firebase';
import { thinScrollbar } from '../../../../styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        optionButton: {
            cursor: "pointer",
            backgroundColor: deepOrange[100]
        },
        optionText: {
            backgroundColor: "white",
            whiteSpace: "pre-wrap",
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "100px"
        }
    }),
);

interface TemplateOptionProps {
    option: SpecialistTemplateOption,
    optionSelectedHandler: (option: SpecialistTemplateOption) => void
}

const TemplateOption = ({ option, optionSelectedHandler }: TemplateOptionProps) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const onOptionSelect = () => {
        logAnalyticsEvent("specialist_template_insert_option", {
            name: option.name
        })
        optionSelectedHandler(option);
    }

    return (
        <>
            <Accordion expanded={hover} className={classes.optionButton} onClick={onOptionSelect} onMouseEnter={()=> {setHover(true)}} onMouseLeave={()=> {setHover(false)}}>
                <AccordionSummary>
                    <Typography>
                        {option.name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={`${classes.optionText} ${classes.thinScrollbar}`}>
                    <Typography variant="subtitle1">
                        {option.text}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default TemplateOption;