//https://www.npmjs.com/package/react-process-string
import React from 'react';
// import processString from "react-process-string";
// import validUrl from "valid-url";
import Linkify from 'linkify-react';

interface AddLinksProps {
    text: string
}

const AddLinks = ({ text }: AddLinksProps) => {
    const linkProps = {
        onClick: (event: any) => {
            if (!window.confirm(`Open this link in a new tab?\n${event.target.href}`)) {
                event.preventDefault()
            }
        }   
    };
    return (
        //Need to add react child to node_modules/linkify-react/index.d.ts manually (children: React.ReactChild)
        //Check this issue for updates: https://github.com/Soapbox/linkifyjs/issues/344
        <Linkify options={{
            attributes: linkProps,
            target: "_blank",
            rel: "noopener noreferrer"
        }}>
            {text}
        </Linkify>
    );
}

export default AddLinks;