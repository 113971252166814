import React from "react"
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1),
        },
        content: {
            color: theme.palette.text.secondary,
            whiteSpace: 'pre-line',//Show newlines
            wordWrap: 'break-word',//break lines on word to fit,           
        }
    }),
);



interface OtoscopeCardProps {
    paperHeight: number
}

const OtoscopeCard = ({  paperHeight, children }: React.PropsWithChildren<OtoscopeCardProps>) => {
    const classes = useStyles();


    return (
        <Box
            className={classes.container}
            height={paperHeight}
        >
            <div className={classes.content}>
                {children}
            </div>
        </Box>
    );
}

export default OtoscopeCard;