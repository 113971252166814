import React from "react";
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import InfoIcon from '@material-ui/icons/Info';

import { GalleryFile } from "./galleryTypes";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useSizeManager from "../useSizeManager";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mediaContainer: {
            margin: theme.spacing(0.5),
            backgroundColor: "#eee",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
            left: 0,
            top: 0,
            height: "25%",
            width: "25%",
            "&:hover": {
                outline: "4px solid",
                outlineColor: theme.palette.primary.main
            }
        },
        image: {
            width: "100%"
        },
        video: {
            height: "100%",
            width: "100%",
        },
        mediaSelected: {
            transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
        },
        mediaUnselected: {
            transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"   
        },
        moreInfo: {
            right: "4px",
            top: "4px",
            position: "absolute",
            zIndex: 1,
            color: theme.palette.primary.main
        },
        checkmark: { 
            fill: theme.palette.primary.main,
            position: "absolute",
            left: theme.spacing(0.25),
            top: theme.spacing(0.25),
            zIndex: 1
        },
        checkmarkCircleOuter: { 
            fill: theme.palette.primary.main,
            position: "absolute"
        },
        checkmarkCircleInner: { 
            fill: "white",
            position: "absolute" 
        },

    }),
);

interface CheckmarkProps {
    selected?: boolean,
    editing?: boolean,
}



interface GalleryRendererProps {
    //React photo gallery props
    file: GalleryFile,
    //Custom props
    selected: boolean,
    selectFile: (file: GalleryFile) => void
    unselectFile: (key: string) => void,
    handleShowModal: (file: GalleryFile) => void,
}


const GalleryRenderer = ({ file, selected, selectFile, unselectFile, handleShowModal }: GalleryRendererProps) => {   

    const classes = useStyles();
    //calculate x,y scale

    const selectedStyle = {
        transform: `translateZ(0px) scale3d(${0.9}, ${0.9}, 1)`
    };

    const checkmarkPosition = {
        cx: 12.2,
        cy: 12.2
    }


    const Checkmark = ({ selected }: CheckmarkProps) => (
        <div className={classes.checkmark}>
            {!selected &&
                <svg>
                    {/* Circle */}
                    <circle className={classes.checkmarkCircleOuter} {...checkmarkPosition} r="9" />
                    <circle className={classes.checkmarkCircleInner} {...checkmarkPosition} r="7.5" />
                </svg>
            }

            {selected && 
                <svg>
                    {/* Checkmark */}
                    <circle className={classes.checkmarkCircleInner} {...checkmarkPosition} r="7.5" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                </svg>
            }
        </div>
    );

    const Container: React.FunctionComponent = ({ children}) => (
        <div style={selected ? {...selectedStyle} : {}} 
            className={`${classes.mediaContainer} ${selected ? classes.mediaSelected : classes.mediaUnselected}`} onClick={handleClick}>
            <Checkmark selected={selected}/>
            {children}
        </div>
    )
    const handleClick = (e: any) => {
        if(selected) {
            unselectFile(file.uri);
        }
        else {
            selectFile(file);
        }        
    };


    const { sizeRef: videoSizeRef, height: videoHeight, updateSize: updateVideoSize } = useSizeManager();

    return (
        <>
            {file.fileType === "image" && 
                <Container>
                    <img
                        className={classes.image}
                        src={file.src}
                    />
                    <div className={classes.moreInfo}>
                        <svg width="20px" height="20px" onClick={(e) => {
                            e.stopPropagation();
                            handleShowModal(file);
                        }}>
                            <circle className={classes.checkmarkCircleInner} cx="10" cy="10" r="10" />
                            <InfoIcon/>
                        </svg>
                    </div>
                </Container>
            }
            {file.fileType === "video" && 
                <Container>
                    {/* Div with min height is required to stop container resizing whenever the video loads (which happens whenever something is selected) */}
                    <div style={{minHeight: videoHeight}}>
                        <div ref={videoSizeRef}>
                            <ReactPlayer 
                                // Update minimum size of container after video loads. If video unloads, the container won't shrink which prevents the scrollbar from moving up and down while loading
                                onReady={updateVideoSize}
                                className={classes.video}
                                url={file.src}
                                width='100%'
                                height='100%'
                            />
                        </div>
                        
                    </div>
                    <div className={classes.moreInfo}>
                        <svg width="20px" height="20px" className="rg-play" onClick={(e) => {
                            e.stopPropagation();
                            handleShowModal(file);
                        }}>
                            <circle className={classes.checkmarkCircleInner} cx="10" cy="10" r="10" />
                            <PlayCircleFilledIcon/>
                        </svg>
                    </div>
                </Container>
            }
        </>

    );
};

export default GalleryRenderer;