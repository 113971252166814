import React, { FunctionComponent }  from "react";
import * as CSS from 'csstype';
import "./Gallery.css";
import { GalleryRenderComponentProps } from "./BaseGallery";
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";



interface CheckmarkProps {
    selected: boolean,
    editing: boolean,
}

interface ContainerProps extends CheckmarkProps {
    margin: string,
    className: string,
    fileType: string,
    onClick: (e: any) => void
}


const Checkmark = ({ selected, editing }: CheckmarkProps) => (
    <div className={editing ? "g-checkmark-editing" : "g-checkmark-not-editing"}>
        {!selected &&
            <svg  width="30px" height="30px">
                {/* Circle */}
                <circle className="g-checkmark-circle-outer" cx="12.5" cy="12.2" r="9" />
                <circle className="g-checkmark-circle-inner" cx="12.5" cy="12.2" r="7.5" />
            </svg>
        }

        {selected && 
            <svg className="g-checkmark" width="30px" height="30px">
                {/* Checkmark */}
                <circle className="g-checkmark-circle-inner" cx="12.5" cy="12.2" r="7.5" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
        }
    </div>
);


const Container: FunctionComponent<ContainerProps> = (props) => {
    const { margin, className, selected, editing, fileType, onClick } = props;

    return (
        <div style={{ margin } as CSS.Properties} className={className} onClick={onClick}>
            <Checkmark selected={selected} editing={editing}/>
            {props.children}
            {isVideo(fileType) && 
                <button className="rg-play button-material" onClick={onClick}>
                    <svg width="20px" height="20px" className="rg-play">
                        <circle className="rg-checkmark-circle-inner" cx="10" cy="10" r="10" />
                        <PlayCircleFilledIcon/>
                    </svg>
                </button>
            }
        </div>
    );
}


const GalleryRenderer = (props : GalleryRenderComponentProps) => {   
    const { index, photo, margin, selected, editing, selectImage, unselectImage, handleShowModal } = props;
    const fileType = photo.name.split('.')[1];

    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    const selectedImgStyle = {
        transform: `translateZ(0px) scale3d(${sx}, ${sy}, 1)`
    };

    const handleClick = (e: any) => {
        if(editing) {
            if(selected) {
                unselectImage(photo);
            }
            else {
                selectImage(photo);
            }
        }
        else {
            handleShowModal(photo);
        }
    };

    return (
        <>
            {isImage(fileType) && 
                <Container margin={margin} className={'g-image-container'} selected={selected} editing={editing} fileType={fileType} onClick={handleClick}>
                    <img
                        className={`${editing ? "g-editing-image" : "g-not-editing-image"} g-image`}
                        style={editing ? {...selectedImgStyle } : {}}
                        src={photo.src}
                    />
                </Container>
            }


            {isVideo(fileType) && 
                <Container margin={margin} className={'g-video-container'} selected={selected} editing={editing} fileType={fileType} onClick={handleClick}>
                    <ReactPlayer 
                        className={`${editing ? "g-editing-image" : "g-not-editing-image"} g-image`}
                        style={editing ? {...selectedImgStyle } : {}}
                        url={photo.src}
                        width='100%'
                        height='100%'
                    />
                </Container>
            }
        </>

    );
};

export default GalleryRenderer;

