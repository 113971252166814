import React, { useState, createContext } from 'react';
import { defaultOutcome } from './SpecialistTools/EconsultOutcomes';


//Context for message input so that specialist template and mesasge input components can edit the same value

interface ContextProps {
    value: string,
    setValue: (inputString: string) => void,
    outcome: string,
    setOutcome: (outcome: string) => void,
    diagnosis: string,
    setDiagnosis: (diagnosis: string) => void,
    messageSent: boolean,//Set to true to signify message was sent
    onMessageSent: () => void
};

export const InputContext = createContext<ContextProps>({
    value: "",
    setValue: () => {},
    outcome: "",
    setOutcome: () => {},
    diagnosis: "",
    setDiagnosis: () => {},
    messageSent: false,
    onMessageSent: () => {}
});

interface InputProviderProps {

}

export const InputProvider: React.FunctionComponent<InputProviderProps> = ({ children }) => {

    const [value, setValue] = useState("");
    const [outcome, setOutcome] = useState(defaultOutcome);
    const [diagnosis, setDiagnosis] = useState("");
    const [messageSent, setMessageSent] = useState(false);

    //Send event
    const onMessageSent = () => {
        //Quickly toggle on and off to trigger useEffects
        setMessageSent(true);
        setTimeout(() => {
            setMessageSent(false);
        }, 1);
    }


    return (
        <InputContext.Provider value={{
            value,
            setValue,
            outcome,
            setOutcome,
            diagnosis,
            setDiagnosis,
            messageSent,
            onMessageSent
        }}>
            {children}
        </InputContext.Provider>
    );
}
