import React, { useState, useEffect, useContext } from "react";

import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withRouter, useHistory } from "react-router-dom";
import * as QueryString from "query-string";

import { fbFirestore, logAnalyticsEvent } from "../../../firebase";
import { AuthContext } from "../../../AuthProvider";

import ActivityListItem from "./ActivityListItem";
import { crypto } from "@alethea-medical/utilities";
import { getDocumentData } from "@alethea-medical/utilities";

import { Activity } from "./types";
import { Econsult } from "../../../../shared/types";

import firebase from "firebase";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ViewSecureMessage from "./ViewSecureMessage";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import PaperPage from "../../../components/PaperPage";
import { SearchBar, SearchOption } from "../../../components/SearchBar";
import Typography from '@material-ui/core/Typography';
import { thinScrollbar } from '../../../styles';
import SnackbarMessage from "../../../components/SnackbarMessage";
import Grid from '@material-ui/core/Grid';
import SecureMessagingToolbar from "./SecureMessagingToolbar";
import Checkbox from '@material-ui/core/Checkbox';

import useProcessState from "../../../components/useProcessState";
import useSizeManager from "../../../components/useSizeManager";
import { SpecialistProvider } from "./SpecialistProvider";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		...thinScrollbar,
		box: {
			borderColor: theme.palette.grey[300],
		},
		threadBox: {
		},
		threadSidebar:  {
			padding: 0,
		},
		threadListItem: {
			padding: 0,
		},
		threadList: {
			padding: 0
		},
		threadListBox: {
			overflowY: "auto",
			width: "100%"
		},
		hidden: {
			display: "none"
		},
		toolbar: {
            paddingLeft: theme.spacing(1)
        }
	}),
);

export interface ActivityDict {
	[activityId: string]: Activity
}

const searchOptions: SearchOption[] = [
	{field: "phn", display: "PHN"}, 
	{field: "patientFirstName"},
	{field: "patientLastName"},
	{field: "subsite"},
	{field: "specialty"},
]


const SecureMessaging = withRouter(({location}) => {

    const classes = useStyles();
	const authContext = useContext(AuthContext);
	const history = useHistory();

	const theme = useTheme();
	const medium = useMediaQuery(theme.breakpoints.down('md'));


	const loadMoreAmount = 10;

	const [activities, setActivities] = useState<ActivityDict>({});
	const [openActivityId, setOpenActivityId] = useState<string | undefined>(undefined);
	const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
	const [newActivityQueue, setNewActivityQueue] = useState<Activity[]>([]);

	//Used to keep track of the id of the activity in the query params
	const [queryActivityId, setQueryActivityId] = useState<string>();

    const [lastFetchedTime, setLastFetchedTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
	const [oldestActivityTime, setOldestActivityTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());

	const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);
	const [viewArchived, setViewArchived] = useState<boolean>(false);
	
	const {processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();
	const {processState: queryActivityState, 
		setProcessState: setQueryActivityState, 
		processErrorMessage: queryActivityErrMsg, 
		errorHandler: queryActivityErrorHandler } = useProcessState();



	
	const [enableSearch, setEnableSearch] = useState<boolean>(false);

	const [unsub, setUnsub] = useState<() => void>();



	//Set the activity index, mark it as read, and fetch the other user for the activity
	const openActivityHandler = (id: string) => {
		logAnalyticsEvent("secure_messaging_select_activity");
		setOpenActivityId(id);
		markAsReadHandler(id);
		history.push(`${location.pathname}${location.search}`);//Push current path so when back is pressed, user stays on current page
    }

	//Mark message as read by current user in state and in the firestore
	const markAsReadHandler = (id: string) => {
		const newActivity = {...activities[id]};
		if(!newActivity.recentMessage.readBy.includes(authContext.uid)) {
			newActivity.recentMessage.readBy.push(authContext.uid)
			fbFirestore.collection("activities").doc(id).update({
				recentMessage: newActivity.recentMessage
			})
			.then(() => {
				const newActivities = {...activities};
				newActivities[id] = newActivity;
				setActivities(newActivities);
			})
			.catch((error: Error) => {
				errorHandler({
					error: error
				});
			})
		}
	}
    
	//Mark message as unread by current user in state and in the firestore

	const markAsUnreadHandler = (activityIds: string[]) => {
        const newActivities = {...activities};
        return Promise.all(activityIds.map((id) => {
            const readByIndex = newActivities[id].recentMessage.readBy.indexOf(authContext.uid)
            if(readByIndex !== -1) {
				const newActivity = {...newActivities[id]}
				newActivity.recentMessage.readBy.splice(readByIndex, 1);
                return fbFirestore.collection("activities").doc(id).update({
                    recentMessage: newActivity.recentMessage
                })   
				.then(() => {
					newActivities[id] = newActivity;
				})
            }
			return;
        }))
        .then(() => {
			setOpenActivityId(undefined);
			setShowMarkAsUnreadSnackbar(true);
			logAnalyticsEvent("secure_message_mark_unread");
            setActivities(newActivities);            
        })
		.catch((error: Error) => {
			errorHandler({
				error: error,
				userMessage: "Error marking message as unread"
			});
		})
    }


	//Called when user presses archive button
	const toggleArchiveHandler = (activityIds: string[], archive: boolean) => {
		setShowUndoSnackbar(false);
        const newActivities = {...activities};
		const newUndoActivities: Activity[] = []
        return Promise.all(activityIds.map((id) => {
			//Make deep copy
			const newUndoActivity = {...newActivities[id]};
			newUndoActivity.inInbox = [...newUndoActivity.inInbox];
			newUndoActivity.inArchive = [...newUndoActivity.inArchive];
			newUndoActivities.push(newUndoActivity);
			

			const inInbox = newActivities[id].inInbox;
			const inArchive = newActivities[id].inArchive;

			if(!archive) {//Unarchive
				inArchive.splice(inArchive.indexOf(authContext.uid), 1);
				inInbox.push(authContext.uid);
			}
			else {//Archive
				inInbox.splice(inInbox.indexOf(authContext.uid), 1);
				inArchive.push(authContext.uid);
			}		
				
			return fbFirestore.collection("activities").doc(id).update({
				inArchive: inArchive,
				inInbox: inInbox
			})
			.then(() => {
				//Remove the activity from the list
				delete newActivities[id];
			})
        }))
		.then(() => {
			if(archive) {
				logAnalyticsEvent("secure_message_archive");
				setArchivedSnackbarAction("archive");
			}
			else {
				setArchivedSnackbarAction("inbox");
				logAnalyticsEvent("secure_message_unarchive");
			}
			setUndoActivities(newUndoActivities);
			setActivities(newActivities);
			setSelectedActivities([]);
			setShowUndoSnackbar(true);
			setOpenActivityId(undefined);
		})
		.catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: `Error ${archive ? "archiving" : "unarchiving"} message`
			});
		})
	}

	
	const undoToggleArchive = () => {
		const newActivities = {...activities}
		return Promise.all(undoActivities.map((a) => {			
			return fbFirestore.collection("activities").doc(a.id).update({
				inArchive: a.inArchive,
				inInbox: a.inInbox
			})
			.then(() => {
				newActivities[a.id] = a;
			})
        }))
        .then(() => {
			logAnalyticsEvent("secure_message_undo_archive");
			setActivities(newActivities);
            setShowUndoSnackbar(false);
        })
		.catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: `Error undoing the previous action`
			});

		})
	}

	//Undo archive
	const [undoActivities, setUndoActivities] = useState<Activity[]>([]);
	const [showUndoSnackbar, setShowUndoSnackbar] = useState<boolean>(false);
	const [showMarkedAsUnreadSnackbar, setShowMarkAsUnreadSnackbar] = useState<boolean>(false);
	const [archivedSnackbarAction, setArchivedSnackbarAction] = useState<string>("");

	//When changing tab, set the view archived boolean and reset activities list
	const tabChangeHandler = (_: any, newViewArchived: boolean) => {
		setViewArchived(newViewArchived);

		//unsubscribe from new messages on prev tab
		if(unsub !== undefined)
			unsub();

		if(enableSearch) {
			setEnableSearch(false);
		}
		
		resetActivities();
	}

	//Load more activities, with timestamp less than the oldest message we currently have
	const loadMoreHandler = () => {
		loadActivities(oldestActivityTime);
	}

	//Load activities where recent message sent at is less than the provided timestamp
	const loadActivities = (fetchLessThan: firebase.firestore.Timestamp) => {
		setProcessState(ProcessState.running);
		setDisableLoadMore(true);

		let listToUse = "inInbox";
		if(viewArchived)
			listToUse = "inArchive";
		
		fbFirestore.collection("activities").where(listToUse, "array-contains", authContext.uid)
		.where("recentMessage.sentAt", "<", fetchLessThan).orderBy("recentMessage.sentAt", "desc").limit(loadMoreAmount).get()
		.then((snapshot) => {
			if(snapshot.size > 0) {
				newMessagesHandler(snapshot)
			}
			setProcessState(ProcessState.idle);
		})
		.catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: `Error loading messages`
			});
		})
		.finally(() => {
			setDisableLoadMore(false);
		})
	}

	const resetActivities = () => {
		setOpenActivityId(undefined);
		setActivities({});
		setSelectedActivities([]);
		setOldestActivityTime(firebase.firestore.Timestamp.now());
	}

	const newMessagesHandler = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
		if(snapshot.size > 0) {
			console.log(`Retrieved ${snapshot.size} messages`);

			//Only set new activity queue here. This function is defined only when lastFetchedTime changes, 
			//so it won't be updated with any new values of activityIndex or activities
			setNewActivityQueue(snapshot.docs.map((doc) => {
				return Object.assign({}, doc.data(), { id: doc.id }) as Activity;         
			}));
			
			//Update last fetched time, so that we only fetch new messages later than this time
			//This reduces the number of calls to this function
			setLastFetchedTime(firebase.firestore.Timestamp.now());
		}
	}

	const selectAllHandler = (selectAll: boolean) => {
        if(selectAll) {
            const newSelectedActivities = [...selectedActivities];
            Object.keys(activities).forEach((id) => {
                if(!newSelectedActivities.includes(id)) {
                    newSelectedActivities.push(id);
                }
            })
            setSelectedActivities(newSelectedActivities);
        }
        else {
            setSelectedActivities([]);
        }
    }

	const activitySelectedHandler = (id: string, checked: boolean) => {
        if(checked) {
            if(!selectedActivities.includes(id)) {
                const newSelectedActivities = [...selectedActivities];
                newSelectedActivities.push(id);
                setSelectedActivities(newSelectedActivities);
            }
        }
        else {
            const idxToRemove = selectedActivities.indexOf(id);
            if(idxToRemove !== -1) {
                const newSelectedActivities = [...selectedActivities];
                newSelectedActivities.splice(idxToRemove, 1);
                setSelectedActivities(newSelectedActivities);
            }
        }
    }


	useEffect(() => {
        return history.listen(() => {
            if(history.action === 'POP') {
                setOpenActivityId(undefined);//Go back to message list instead of previous route
            }
        })
    }, [history]);

	const goBackHandler = () => {
        setOpenActivityId(undefined);
    }

	//Initial loading of messages and when view archived changes
	useEffect(() => {
		loadActivities(firebase.firestore.Timestamp.now());
	}, [viewArchived]);

	//Listen for new messages and put into newActivityQueue
	useEffect(() => {
		let listToUse = "inInbox";
		if(viewArchived)
			listToUse = "inArchive";

		const unsubscribe = fbFirestore.collection("activities").where(listToUse, "array-contains", authContext.uid)
        .where("recentMessage.sentAt", ">", lastFetchedTime).orderBy("recentMessage.sentAt", "desc").onSnapshot(newMessagesHandler);

		//pass function that returns unsubscribe so that unsub doesn't immediately get called (not really sure why I need to do this, but it works)
		setUnsub(() => unsubscribe);

		//Call unsubscribe to cleanup previous render
        return () => {unsubscribe()};
    }, [lastFetchedTime])

	//Run when new activities are put into newActivityQueue
	useEffect(() => {
		//Put the update logic into a separate function from the onSnapshot function so that it contains
		//all updated values of activityIndex and activities
		const newActivities = {...activities};
		newActivityQueue.forEach((newActivity) => {
			//When the query activity is added to the queue, don't set the oldest time (so we can fetch activities more recent than it)
			//Also reset the id, since we only need to check this the first time
			if(newActivity.id === queryActivityId) {
				setQueryActivityId(undefined);
			}
			else if(newActivity.recentMessage.sentAt < oldestActivityTime) {
				setOldestActivityTime(newActivity.recentMessage.sentAt);
			}
			newActivities[newActivity.id] = newActivity;
		})		
		setActivities(newActivities);
		
	}, [newActivityQueue])

	useEffect(() => {        
        if(selectedActivities.length > 0) {
            setAllSelected(Object.keys(activities).every((id) => {
                return selectedActivities.includes(id);
            }));
        }
        else {
            setAllSelected(false);
        }
    }, [selectedActivities])

	useEffect(() => {
		const params = QueryString.parse(location.search);
        
        if(params.econsultId) {
			setQueryActivityState(ProcessState.running);
			
			fbFirestore.collection("activities").doc(params.econsultId).get()
			.then((snapshot) => {
				if(snapshot.exists) {
					const newActivity = Object.assign({}, snapshot.data(), { id: snapshot.id }) as Activity;
					setQueryActivityId(newActivity.id);
					setNewActivityQueue([newActivity]);
					setOpenActivityId(newActivity.id);

					setQueryActivityState(ProcessState.idle);
				}
				else {
					return Promise.reject(new Error(`eConsult with ID ${params.econsultId} does not exist.`))
				}
			})
			.catch((error: Error) => {
				queryActivityErrorHandler({
					error: error, 
					userMessage: "Error loading eConsult"
				});
			})
		}
	}, [])

	const runSearch = (filter: (obj: any) => boolean) => {
		logAnalyticsEvent("secure_message_search");

		setProcessState(ProcessState.running);
		setEnableSearch(true);
		resetActivities();

		//unsubscribe from new messages while we are searching
		if(unsub !== undefined)
			unsub();

		let listToUse = "inInbox";
		if(viewArchived)
			listToUse = "inArchive";
		fbFirestore.collection("activities").where(listToUse, "array-contains", authContext.uid).orderBy("recentMessage.sentAt", "desc").get()
		.then(async (snapshot) => {
			console.log(`Retrieved ${snapshot.size} new activities for search`)
			if(snapshot.size > 0) {
				const newActivities: Activity[] = snapshot.docs.map((doc) => {
					return Object.assign({}, doc.data(), {id: doc.id}) as Activity;
				}).filter((doc) => {
					return doc.econsult !== undefined
				})

				return Promise.all(newActivities.map((activity, index) => {
					return activity.econsult.get().then(getDocumentData)
					.then((econsult: Econsult) => {
						return {
							data: econsult,
							activityIndex: index
						}
					})
				}))
				.then((econsults: {data: Econsult, activityIndex: number}[]) => {
					return Promise.all(econsults.map((econsult) => {
						return Promise.all(searchOptions.map((option) => {
							const field = option.field as keyof Econsult;
							if(econsult.data[field] !== undefined) {
								return crypto.encryptDecryptString(econsult.data[field], fbFirestore.collection("system").doc("keystore").collection("keys").doc("firestoreData"), {decrypt: true})
								.then((decrypt) => {
									assignField(decrypt, econsult.data, field);
								})
							}
							return;
						}))
					}))
					.then(() => {
						const indexesToRemove: number[] = [];

						econsults.forEach((econsult) => {
							if(!filter(econsult.data)) {
								indexesToRemove.push(econsult.activityIndex);	
							}
						});

						for (let i = indexesToRemove.length -1; i >= 0; i--)
							newActivities.splice(indexesToRemove[i], 1);

						setNewActivityQueue(newActivities);
					})
				})
			}
		})
		.then(() => {
			setProcessState(ProcessState.idle);
		})
		.catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: `Error encountered while running your search`
			});
		})
	}

	const onFocus = () => {
		if(openActivityId !== undefined)
			markAsReadHandler(openActivityId);
	};
	  
	useEffect(() => {
		window.addEventListener('focus', onFocus);
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener('focus', onFocus);
		};
	});


	function assignField<K extends keyof Econsult, V extends Econsult[K]> (value: V, econsult: Econsult, field: K) {
		econsult[field] = value;
	}

	const clearSearch = () => {
		setEnableSearch(false);
		resetActivities();
		loadActivities(firebase.firestore.Timestamp.now());
	}

	const updateOutcome = (specialistResponse: {outcome: string, diagnosis: string}, activityId: string) => {
		activities[activityId].exposedEconsultData = {
			specialistResponse: specialistResponse
		}
		setActivities(activities);
	}
	

	//#region Message Box resizing

	const { sizeRef: paperSizeRef, height: paperHeight} = useSizeManager();
	const { sizeRef: searchbarSizeRef, updateSize: updateSearchbarSize, height: searchbarHeight } = useSizeManager();
	//#endregion

    return (
        <>
			<SpecialistProvider uid={authContext.uid}>
				<div ref={paperSizeRef}>
					<PaperPage>
						<div className={openActivityId === undefined ? "" : classes.hidden}> 
							<List className={classes.threadSidebar}>
								<div ref={searchbarSizeRef}>
									<ListItem divider>
										<Typography variant="h5">
											Secure Messaging
										</Typography>
									</ListItem>
									<ListItem 
										divider
									>
										{/* dynamically change orientation */}
										<Tabs value={viewArchived ? 1 : 0} onChange={tabChangeHandler}
											orientation={medium ? "vertical" : "horizontal"}
											variant="fullWidth"
										>
											<Tab label="Inbox"/>
											<Tab label="Archived"/>
										</Tabs>
		
									</ListItem>

									<ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>
									<ProcessStatus state={queryActivityState} setState={setQueryActivityState} errorMessage={queryActivityErrMsg} useSnackbar={true} loadingMessage={"Loading eConsult..."} hideProgressBar/>

									<ListItem 
										divider
									>
										<SearchBar enableSearch={enableSearch} searchOptions={searchOptions}
											runSearch={runSearch} clearSearch={clearSearch}
											onSizeChanged={updateSearchbarSize}
										/>
									</ListItem>
									<ListItem className={classes.toolbar} divider>
										<Grid container spacing={1} justifyContent="flex-start" alignItems="center">
											<Grid item>
												<Checkbox checked={allSelected} onChange={(e) => { selectAllHandler(e.target.checked) }}/>
											</Grid>
											<Grid item>
												<SecureMessagingToolbar 
													archived={viewArchived}
													atLeastOneSelected={selectedActivities.length > 0}
													markAsUnread={() => {markAsUnreadHandler(selectedActivities)}} 
													toggleArchive={(archive) => {toggleArchiveHandler(selectedActivities, archive)}} 
												/>
											</Grid>
										</Grid>
									</ListItem>
								</div>
								<ListItem
									className={classes.threadListItem}
								>
									<Box
										className={`${classes.threadListBox} ${classes.thinScrollbar}`}
										height={paperHeight-searchbarHeight}
									>
										<List
											className={classes.threadList}
										>
											{Object.values(activities).sort((a, b) => {
												if(a.recentMessage.sentAt < b.recentMessage.sentAt)
													return 1;
												else if(a.recentMessage.sentAt > b.recentMessage.sentAt)
													return -1;
												else
													return 0;
											}).map((a) => {
												return <ActivityListItem 
															key={`activity_list_item_${a.id}`} 
															activity={a} 
															openActivity={openActivityHandler}
															selected={selectedActivities.includes(a.id)}
															setSelected={activitySelectedHandler}
														/>
											})}	
											<ListItem
												button
												onClick={loadMoreHandler}
												disabled={disableLoadMore || enableSearch}
												alignItems="center"
												divider
											>
												<ListItemIcon>
													<ArrowDownwardIcon color="primary"/>
												</ListItemIcon>
												<ListItemText
													primary={enableSearch ? "End of Search Results" : "Load More"}
												/>
											</ListItem>

										</List>	
									</Box>
								</ListItem>
							</List>
						</div>
						{(openActivityId !== undefined && activities[openActivityId] !== undefined) && (
							<ViewSecureMessage activity={activities[openActivityId]}
								markAsUnread={() => {markAsUnreadHandler([openActivityId])}} 
								toggleArchive={(archive) => {toggleArchiveHandler([openActivityId], archive)}} 
								goBack={goBackHandler} 
								updateOutcome={updateOutcome}
								paperHeight={paperHeight}/>
						)}
					</PaperPage>
				</div>
            	<SnackbarMessage message={`Moved to ${archivedSnackbarAction}`} show={showUndoSnackbar} setShow={setShowUndoSnackbar} useButton buttonText="UNDO" onClick={undoToggleArchive}/>
				<SnackbarMessage message={"Marked as unread"} show={showMarkedAsUnreadSnackbar} setShow={setShowMarkAsUnreadSnackbar}/>			
			</SpecialistProvider>
        </>
    );
})

export default SecureMessaging;