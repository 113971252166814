import React, { useState, useEffect } from 'react';


import { PatientMessage } from "./types";
import { fbStorage } from "../../../firebase";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PDFViewer from '../../../components/PDFViewer';
import AttachmentViewer from './AttachmentViewer';
import MessageSubheader from './MessageSubheader';
import PatientMessageToolbar from "./PatientMessageToolbar";
import { PatientMessageFolder } from '../../../../shared/types';
import useSizeManager from '../../../components/useSizeManager';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subject: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2)
        },
        date: {
            marginTop: theme.spacing(0),
            paddingLeft: theme.spacing(2)
        },
        errorReason: {
            marginTop: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            maxWidth: "50%"
        },
        body: {
            padding: theme.spacing(2)
        }
    }),
);

interface ViewMessageProps {
    message: PatientMessage,
    paperHeight: number,
    goBackHandler: () => void,
    markAsUnread: () => void,
    moveFolder: (folder: PatientMessageFolder) => void,
}

const pdfPadding = 30;//Padding on PDF height

const ViewMessage = ({ message, paperHeight, goBackHandler, markAsUnread, moveFolder }: ViewMessageProps) => {

    const classes = useStyles();
    const [pdfURL, setPDFURL] = useState<string | undefined>(undefined);

    
	const { sizeRef: bodySizeRef, width: bodyWidth} = useSizeManager();
	const { sizeRef: headerSizeRef, height: headerHeight } = useSizeManager();

    useEffect(() => {
        if(message.messagePDFURI !== undefined) {
            fbStorage.ref(message.messagePDFURI).getDownloadURL()
            .then((url) =>{
                setPDFURL(url);
            })
            .catch((error) => {
                console.error(error);
            })  
        }
  
    }, [message])

    const getNoDataText = () => {
        if(message.subType === "Forms" && message.messagePDFURI === undefined) {
            if(message.status === "sending") {
                return (
                    <>
                        <Typography variant="h5">
                            This form has not yet been sent.
                        </Typography>
                    </>
                );
            }
            else {
                return (
                    <>
                        <Typography variant="h5">
                            This form has no response yet.
                        </Typography>
                    </>
                );
            }

        }
        return undefined;
    }



    return (
        <>
            <div ref={headerSizeRef}>
                <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <IconButton onClick={goBackHandler}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <PatientMessageToolbar 
                            folder={message.folder}
                            atLeastOneSelected={true}
                            markAsUnread={markAsUnread} 
                            moveFolder={moveFolder} 
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid className={classes.subject} container justifyContent="flex-start" alignItems="center">
                            <Typography variant="h5">{message.subject}</Typography>
                        </Grid>
                        <Grid className={classes.date} container justifyContent="flex-start" alignItems="center">
                            <Typography variant="subtitle1"><MessageSubheader message={message}/></Typography>
                        </Grid>
                        {message.error && (
                            <Grid className={classes.errorReason} container justifyContent="flex-start" alignItems="center">
                                <Typography variant="subtitle1">
                                    {message.error}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Divider/>
            </div>
            <Grid className={classes.body} container justifyContent="flex-start">
                <Grid item xs={8}>
                    <div ref={bodySizeRef}>
                        <PDFViewer pdfURL={pdfURL} noDataText={getNoDataText()} height={paperHeight - headerHeight - pdfPadding} width={bodyWidth}/>
                    </div>  
                </Grid>
                {message.messageMediaURIs.length > 0 && (
                    <Grid item xs={4}>
                        <AttachmentViewer messageMediaURIs={message.messageMediaURIs}/>
                    </Grid>
                )}

            </Grid>
        </>
    );
}


export default ViewMessage;