import { Button, createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { ProcessState, ProcessStatus } from '@alethea-medical/react-components';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import { aletheaOrange } from '../../../styles';


interface ICameraButtonsProps {
    image: string,
    capture: any,
    setDevices: any,
    uploadingImage: boolean,
    setuploadingImage: React.Dispatch<React.SetStateAction<boolean>>,
    webcamRef: React.MutableRefObject<any>,
    recordingModeActive: boolean,
    setrecordingModeActive: React.Dispatch<React.SetStateAction<boolean>>,
    handleStopRecordClick: any,
    handleStartRecordClick: any,
    capturing: boolean,
    setProcessState: any,
    processState: any,
    processErrorMessage: any,
    devices: any[],
    setDeviceId: any,
    deviceId: any,
    handleDevices: any,

}

export const CameraButtons = ({ capture, setuploadingImage, uploadingImage, webcamRef, recordingModeActive,
    setrecordingModeActive, capturing, handleStopRecordClick, handleStartRecordClick, setProcessState, processState, processErrorMessage, devices, setDeviceId, deviceId, handleDevices }: ICameraButtonsProps) => {


    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            captureButton: {
                height: '50px',
                backgroundColor: aletheaOrange
            },
            recordButton: {
                height: '50px',
                backgroundColor: aletheaOrange
            },
            recordingButton: {
                height: '50px',
                backgroundColor: 'red'
            },
            refreshDevices: {
                height: '50px'
            },
            cameraMode: {
                height: '50px'
            }
        }),
    );
   
    const classes = useStyles()
    //prevent page scrolling by space bar on this page
    const spacebarDownListener = (e: any)=>{
            if (e.keyCode === 32) {
                e.preventDefault()
            }}

    //listen for space bar presses start and stop recording or take pictures 
    const spacebarUpListener = (e: any) => {
        if (!isDisabledCaptureAndSwitchMode()) {
            if (e.keyCode === 32) {
                e.preventDefault()
                if (recordingModeActive) {
                    if (capturing) {
                        setProcessState(ProcessState.running)
                        handleStopRecordClick()
                    } else {
                        handleStartRecordClick()
                    }
                } else {
                    if (webcamRef != undefined) {
                        if (!uploadingImage) {
                            setProcessState(ProcessState.running)
                            capture();
                            setuploadingImage(!uploadingImage)
                        }
                    }
                }
            }
        }
    };
  
    //add and remove spacebar listeners on entry/exit 
   useEffect(() => {
        
        document.body.addEventListener('keydown', spacebarDownListener)
        document.body.addEventListener('keyup', spacebarUpListener)
        return ()=>{
            document.body.removeEventListener('keydown', spacebarDownListener)
            document.body.removeEventListener('keyup', spacebarUpListener)
        }})//TODO find a way to make this get called less 
        
    
    


    //manual update devices
    const updateDevices = () => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }


    //changes from Single frame image capture to video recording
    const changeMode = () => {
        setrecordingModeActive(!recordingModeActive)
    }


    //change the camera to use when selected from the drop down menu 
    const ChangeDevice = (event: any) => {
        setDeviceId(event.target.value)
    }

    const isDisabledCaptureAndSwitchMode = () => {
        return deviceId.deviceId === undefined || isDisabled()
    }

    const isDisabledSwitchDevice = () => {
        return capturing || isDisabled();
    }

    const isDisabled = () => {
        return processState === ProcessState.running;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={8}>
                {devices.length != 0 &&
                    <FormControl fullWidth>
                        <InputLabel id="deviceListLabel">Please select camera to switch</InputLabel>
                        <Select
                            labelId="deviceListLabel"
                            id="deviceList"
                            onChange={ChangeDevice}
                            value={deviceId}
                            disabled={isDisabledSwitchDevice()}
                        >
                            {devices.map((device: any) =>
                                <MenuItem
                                    key={device.deviceId}
                                    value={device}>
                                    {device.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                } </Grid>
            <Grid item xs={4}>
                <Button
                    className={classes.refreshDevices}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={updateDevices}
                    disabled={isDisabledSwitchDevice()}
                >Refresh Devices</Button>
            </Grid>
            <Grid item xs={8}>

                {!recordingModeActive ? <Button

                    className={classes.captureButton}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                        if (webcamRef != undefined) {
                            e.preventDefault();
                            setProcessState(ProcessState.running)
                            capture();
                            setuploadingImage(!uploadingImage)
                        }
                    }}

                    disabled={isDisabledCaptureAndSwitchMode()}
                    startIcon={<PhotoCameraIcon />}

                >Capture</Button> :
                    <>  {capturing ? (
                        <Button

                            className={classes.recordingButton}
                            onClick={(e) => {
                                if (webcamRef.current != undefined) {
                                    e.preventDefault()
                                    setProcessState(ProcessState.running)
                                    handleStopRecordClick();
                                }
                            }}
                            fullWidth
                            color="primary"
                            variant="contained"
                            startIcon={<StopIcon />}
                        >Stop Recording</Button>
                    ) : (<Button

                        className={classes.recordButton}
                        onClick={(e) => {
                            if (webcamRef.current != undefined) {
                                e.preventDefault()
                                handleStartRecordClick();
                            }

                        }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        disabled={isDisabledCaptureAndSwitchMode()}
                        startIcon={<FiberManualRecordIcon />}
                    >Start Recording</Button>
                    )}</>}
            </Grid>

            <Grid item xs={4}>
                <Button
                    className={classes.cameraMode}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onMouseUp={(e) => {
                        e.preventDefault()
                        changeMode()
                    }}
                    disabled={isDisabledCaptureAndSwitchMode()}
                >Camera Mode</Button>
            </Grid>
            <Grid item xs={12}>
                <ProcessStatus
                    state={processState}
                    errorMessage={processErrorMessage}
                    setState={setProcessState}
                />
            </Grid>
            <Typography> *If the camera image is not displaying it is possible that the camera is being used by another application</Typography>
        </Grid>
    )
}

export default CameraButtons
