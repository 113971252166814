import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import GalleryModal from "./GalleryModal";
import UploadFilesModal from "./UploadFilesModal";
import { ImageSrc, BaseGallery, SelectedImages } from "./BaseGallery";
import GalleryRenderer from "./GalleryRenderer";
import { Body, ProcessState, ProcessStatus } from "../../Global/components"
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { fbStorage } from "../../../firebase";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";



//Code from https://www.npmjs.com/package/react-photo-gallery "selection using custom renderImage" example

export const Gallery = (autoRefresh?: any) => {

    const [selectedImages, setSelectedImages] = useState<SelectedImages>({});

    const [modalImage, setModalImage] = useState<ImageSrc>();

    const [showImageModal, setShowImageModal] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [editing, setEditing] = useState(false);

    const [status, setStatus] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState('');

    const [refresh, setRefresh] = useState<boolean | undefined>(undefined);


    const handleChangeEditingState = () => {
        setSelectedImages({});
        setEditing(!editing)
    }


    useEffect(() => {
        setRefresh(!refresh)
    }, [autoRefresh])

    const handleShowModal = (image: ImageSrc) => {
        setShowImageModal(true);
        setModalImage(image);
    }

    const hideDeleteModal = () => {
        setStatus(ProcessState.idle);
        setShowDeleteModal(false);
    }

    const hideImageModal = () => {
        setShowImageModal(false);
    }


    const deleteSelectedImages = () => {
        setStatus(ProcessState.running);
        const deletePromises = Object.keys(selectedImages).map((uri: string) => {
            const storageRef = fbStorage.ref(uri);
            return storageRef.delete();
        });
        Promise.all(deletePromises)
            .then(() => {
                setStatus(ProcessState.success);
                setRefresh(!refresh);
                setSelectedImages({})
                setTimeout(() => {
                    hideDeleteModal();
                    hideImageModal();
                }, 1000)
            })
            .catch((error: Error) => {
                console.log(error);
                setErrorMessage(`There was an error deleting some images: ${error.message}`);
                setStatus(ProcessState.error);
            })
    }

    const deleteModalImage = () => {
        if (modalImage) {
            const newSelectedImages: SelectedImages = {};
            newSelectedImages[modalImage.fullPath] = modalImage;
            setSelectedImages(newSelectedImages);

            setShowDeleteModal(true);
        }
    }



    return (
        <>
            <Body title="Gallery" subtitle="Manage your images taken in the Alethea mobile app.">
                <>
                    <Row>
                        <Col xs={"auto"}>
                            <button className="button-border" onClick={(event: any) => {
                                event.preventDefault();
                                setShowUploadModal(true);
                            }}>Upload Files</button>
                        </Col>
                        <Col xs={"auto"}>
                            <Tooltip title="Manage images">
                                <button className="button-material mb-3 mr-3" onClick={() => handleChangeEditingState()}>
                                    {editing ? <EditIcon /> : <EditOutlinedIcon />}
                                </button>
                            </Tooltip>
                        </Col>

                        {editing &&
                            <>
                                <Col xs={"auto"}>
                                    <button className="button-border" onClick={(event: any) => {
                                        event.preventDefault();
                                        setSelectedImages({})
                                    }}>Unselect All</button>
                                </Col>
                                {Object.keys(selectedImages).length > 0 &&
                                    <Col xs={"auto"}>
                                        <button className="button-border danger" onClick={(event: any) => {
                                            event.preventDefault();
                                            setShowDeleteModal(true);
                                        }}>Delete Selected</button>
                                    </Col>
                                }

                            </>
                        }


                    </Row>
                    <Divider className="mb-3" />
                    <div className="gallery-container">
                        <BaseGallery selectedImages={selectedImages} editing={editing} refresh={refresh} setSelectedImages={setSelectedImages} GalleryRenderComponent={GalleryRenderer} handleShowModal={handleShowModal} />

                        {modalImage !== undefined && <GalleryModal show={showImageModal} image={modalImage} handleClose={() => setShowImageModal(false)} footer={
                            <button className="button-border danger" disabled={status === ProcessState.running || status === ProcessState.success} onClick={() => deleteModalImage()}>
                                Delete
                            </button>
                        } />}
                    </div>
                </>

            </Body>

            <Modal show={showDeleteModal} onHide={() => { if (status !== ProcessState.running && status !== ProcessState.success) hideDeleteModal() }}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-muted">{`Delete ${Object.keys(selectedImages).length} selected image${Object.keys(selectedImages).length > 1 ? 's' : ''}?`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This action CANNOT be undone.
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-border mr-auto" disabled={status === ProcessState.running || status === ProcessState.success} onClick={() => hideDeleteModal()}>
                        Cancel
                    </button>
                    <ProcessStatus processState={status} errorMessage={errorMessage}><div className="alert alert-success">Delete successful</div></ProcessStatus>
                    <button className="button-border danger" disabled={status === ProcessState.running || status === ProcessState.success} onClick={() => deleteSelectedImages()}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>

            <UploadFilesModal show={showUploadModal} setShow={setShowUploadModal} submittedHandler={() => {
                setRefresh(!refresh);
            }} />
        </>
    );
}

export default Gallery
