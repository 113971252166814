import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AuthRoutes } from "../views/Auth";
import PrivateRoute from "./PrivateRoute";
import { DashboardRoutes } from "../views/Dashboard/DashboardRoutes";
import AvaRoutes from "../views/Pages/Ava";
import Analytics from "./Analytics";
import CareForms from "../views/Pages/CareForms";

const ApplicationRoutes = () => {


    return (   
        <Router>
            
            <Analytics/>
            
            <Switch>
           
                <PrivateRoute path="/dashboard" component={DashboardRoutes}/>
                <PrivateRoute path="/ava" component={AvaRoutes} />
                <Route path="/forms" component={CareForms} />  
                <Route path="/" component={AuthRoutes} />           
            </Switch>
            
        </Router>
    );
}

export default ApplicationRoutes;