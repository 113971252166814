import React, { useState, createContext, useEffect } from 'react';
import firebase from "firebase";
import { Econsult } from '../../../../../shared/types';
import { aletheaMDCrypto, getDocumentData } from '@alethea-medical/utilities';
import { fbFirestore } from '../../../../firebase';

interface ContextProps {
    econsult: Econsult,
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined
};

const initEconsult: Econsult = {
    created: firebase.firestore.Timestamp.now(),
    information: {
        description: '',
        medicalHistory: '',
        questionsForConsultant: ''
    },    
    gpUid: '',
    oop: false,
    patientEmail: '',
    patientFirstName: '',
    patientLastName: '',
    patientPhone: '',
    phn: '',
    referralMediaURIs: [],
    specialistUid: '',
    specialty: '',
    subsite: '',
    symptoms: {},
}

export const EconsultContext = createContext<ContextProps>({
    econsult: initEconsult,
    ref: undefined
});

interface EconsultProviderProps {
    econsultRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
}

export const EconsultProvider: React.FunctionComponent<EconsultProviderProps> = ({ econsultRef, children }) => {

    const [value, setValue] = useState(initEconsult);

    useEffect(() => {

        if(econsultRef !== undefined) {
            const unsubscribe = econsultRef.onSnapshot((snapshot) => {
                return getDocumentData(snapshot)
                .then((econsult: Econsult) => {
                    return aletheaMDCrypto.encryptDecryptEconsult(econsult, fbFirestore.collection("system").doc("keystore").collection("keys").doc("firestoreData"), {decrypt: true})
                    .then(() => {
                        setValue(econsult);
                    })
                })
                .catch((error: Error) => {
                    console.error(error);
                });
            })
            
            //Call unsubscribe to cleanup previous render
            return () => {unsubscribe()};
        }
    }, [])

    return (
        <EconsultContext.Provider value={{
            econsult: value,
            ref: econsultRef
        }}>
            {children}
        </EconsultContext.Provider>
    );
}

//Only update this component if the econsult id changes
function areEqual(prevProps: Readonly<EconsultProviderProps>, nextProps: Readonly<EconsultProviderProps>): boolean {
    return prevProps.econsultRef?.id === nextProps.econsultRef?.id
}

export default React.memo(EconsultProvider, areEqual);