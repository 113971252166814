import React, { useState, useContext, useEffect } from "react";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ForwardIcon from '@material-ui/icons/Forward';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { AuthContext } from "../../../AuthProvider";

import Messages from "./Messages";
import MessagesHeader from "./MessagesHeader";
import MessageInput from "./MessageInput";

import MOAMessage from "./MOAMessage";
import SecureMessagingToolbar from "./SecureMessagingToolbar";

import { Activity } from "./types";
import { ActivityUser } from "../../../../shared/types";

import EconsultView from "./Econsult/EconsultView";
import exportPDF from "./exportPDF";
import { fbFirestore, logAnalyticsEvent } from "../../../firebase";
import { getDocumentData } from "@alethea-medical/utilities";
import useProcessState from "../../../components/useProcessState";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import SpecialistTemplates from "./SpecialistTools/SpecialistTemplates";
import { InputProvider } from "./InputProvider";
import useSizeManager from "../../../components/useSizeManager";
import EconsultImageAnnotation from "./SpecialistTools/EconsultImageAnnotation";
import { EconsultProvider } from "./Econsult/EconsultProvider";
import EconsultOutcomes from "./SpecialistTools/EconsultOutcomes";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			borderColor: theme.palette.grey[300],
		},
		toolbarBox: {
			padding: theme.spacing(1)
		},
		econsultBox: {
            padding: theme.spacing(1),
			paddingTop: theme.spacing(2),
			overflowY: "auto",
			overflowX: "hidden",
		},
		messagesHeaderBox: {
			paddingLeft: theme.spacing(1)
		},
		messagesBox: {
			padding: theme.spacing(1),
			overflowY: "auto",
			overflowX: "hidden",
		},
		messageInputBox: {
			padding: theme.spacing(1)
		},
        messageFileDropBox: {
			padding: theme.spacing(1)
		},
		thinScrollbar: {
			'&::-webkit-scrollbar': {
				width: '0.4em'
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0,0,0,.1)',
				borderRadius: '5px'
			}
		},
        iconButton: {
            marginRight: theme.spacing(1)
        },

	}),
);


interface ViewSecureMessageProps {
    activity: Activity,
    markAsUnread: () => void,
    toggleArchive: (archive: boolean) => void,
    goBack: () => void
    paperHeight: number,
    updateOutcome: (specialistResponse: {outcome: string, diagnosis: string}, activityId: string) => void
}


const ViewSecureMessage = ({ activity, markAsUnread, toggleArchive, goBack, paperHeight, updateOutcome }: ViewSecureMessageProps) => {

    const classes = useStyles();
    const authContext = useContext(AuthContext);

    
	const [otherUser, setOtherUser] = useState<ActivityUser>();
	const [showEmailMessageThreadModal, setShowEmailMessageThreadModal] =  useState<boolean>(false);
    const [showTemplates, setShowTemplates] = useState<boolean>(false);
    const [showImageAnnotation, setShowImageAnnotation] = useState<boolean>(false);


    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();


    useEffect(() => {
        fetchOtherUser(activity);
    }, [activity.id])


    const exportPDFHandler = () => {
        setProcessState(ProcessState.running);
        logAnalyticsEvent("secure_messaging_export_pdf_start");
        return exportPDF(activity.id, authContext.user)
        .then(() => {
            logAnalyticsEvent("secure_messaging_export_pdf_success");
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error exporting PDF", 
                analyticsLog: "secure_messaging_export_pdf_failed"
            });
        })
	}

    //Fetch the user listed in the activity that is not the logged in user
    const fetchOtherUser = (activity: Activity | undefined) => {
        setOtherUser(undefined);
        setProcessState(ProcessState.running);
        if(activity !== undefined) {
            let profileUid: string | undefined;
            if(activity.users[0] !== authContext.uid) {
                profileUid = activity.users[0];
            }
            else if(activity.users[1] !== authContext.uid) {
                profileUid = activity.users[1];
            }
            if(profileUid !== undefined) {
                
                fbFirestore.collection("activities").doc(activity.id).collection("userInfo").doc(profileUid).get().then(getDocumentData)
                .then((otherUser: ActivityUser) => {
                    setProcessState(ProcessState.idle);
                    setOtherUser(otherUser);
                })
                .catch((error: Error) => {
                    errorHandler({
                        error: error, 
                        userMessage: "Error loading eConsult user information"
                    });
                })
            }     
        }
    }


    const { sizeRef: toolbarSizeRef, updateSize: updateToolbarSize, height: toolbarHeight } = useSizeManager();
    const { sizeRef: headerSizeRef, updateSize: updateHeaderSize, height: headerHeight } = useSizeManager();
    const { sizeRef: inputSizeRef, updateSize: updateInputSize, height: inputHeight } = useSizeManager();

    //Update sizes when templates slide out
    useEffect(() => {
        updateToolbarSize();
        updateHeaderSize();
        updateInputSize();
        window.scrollTo(0, 0);
    }, [showTemplates, showImageAnnotation])

    return (
        <>
            <EconsultProvider econsultRef={activity.econsult}>
                <Grid container>                    
                    <Grid item xs={showTemplates ? 4 : 6}>
                        <div ref={toolbarSizeRef}>
                            <Box
                                className={`${classes.toolbarBox} ${classes.box}`}
                                borderBottom={1}
                            >
                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <IconButton onClick={goBack}>
                                            <ChevronLeftIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <SecureMessagingToolbar
                                            archived={activity.inArchive.includes(authContext.uid)}
                                            atLeastOneSelected={true}
                                            markAsUnread={markAsUnread}
                                            toggleArchive={toggleArchive}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ButtonGroup>
                                            <Tooltip
                                                title="Forward econsult as a PDF"
                                                children={
                                                    <Button                                                
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={() => {setShowEmailMessageThreadModal(true)}}
                                                    >
                                                        <ForwardIcon className={classes.iconButton}/>
                                                        Forward
                                                    </Button>    
                                                }
                                            />   
                                            <Tooltip
                                                title="Export eConsult as PDF"
                                                children={
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={exportPDFHandler}
                                                    >
                                                        <PictureAsPdfIcon className={classes.iconButton}/>
                                                        Export
                                                    </Button>      
                                                }
                                            />  
                                        </ButtonGroup>    
                                    </Grid>
                                </Grid>
                            </Box>
                            <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>    
                        </div>
        
                        <Box
                            className={`${classes.econsultBox} ${classes.thinScrollbar}`}
                            height={paperHeight-toolbarHeight}
                        >
                            {otherUser !== undefined && (
                                <EconsultView econsultId={activity?.econsult?.id} otherUser={otherUser}/> 
                            )}
                        </Box>
                    </Grid>
                    <InputProvider>
                        <EconsultImageAnnotation boxClass={`${classes.messagesBox} ${classes.box} ${classes.thinScrollbar}`}
                        show={showImageAnnotation} setShow={setShowImageAnnotation} paperHeight={paperHeight}/>
                        {!showImageAnnotation && (
                            <>
                                <Grid item xs={showTemplates ? 4 : 6}>
                                    <div ref={headerSizeRef}>
                                        <Box                 
                                            borderLeft={1}
                                            borderBottom={1}
                                            className={`${classes.box} ${classes.messagesHeaderBox}`}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MessagesHeader otherUser={otherUser}/>	
                                        </Box>
                                    </div>

                                    <Box
                                        className={`${classes.messagesBox} ${classes.box} ${classes.thinScrollbar}`}       
                                        borderLeft={1}
                                        borderBottom={1}
                                        height={paperHeight - headerHeight - inputHeight}
                                    >
                                        <Messages activityId={activity.id}/>	
                                    </Box>
                
                                    <div ref={inputSizeRef}>
                                        <Box
                                            className={`${classes.box}`}
                                            borderLeft={1}
                                        >
                                            <div className={`${classes.messageInputBox}`}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <MessageInput activity={activity} updateSize={updateInputSize}/>	
                                                    </Grid>
                                                </Grid>
                                            </div> 
                                        </Box>
                                    </div>
                                </Grid>
                                <SpecialistTemplates show={showTemplates} setShow={setShowTemplates} height={paperHeight}/>    
                                <EconsultOutcomes activityId={activity.id} updateOutcome={updateOutcome}/>
                            </>
                        )}
                    </InputProvider>
                </Grid>
            </EconsultProvider>
            
            <MOAMessage
                show={showEmailMessageThreadModal}
                activity={activity}
                handleClose={() => {setShowEmailMessageThreadModal(false)}}
            />
        </>
    );
}

export default ViewSecureMessage;