import React, {useState, useEffect, useContext} from "react";
import { useForm, useFormState } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";

import { AuthContext } from "../../../AuthProvider";
import serverRequest from "../../../models/serverRequest";
import { fbFirestore, logAnalyticsEvent } from "../../../firebase";
import { OOPData } from "@alethea-medical/admin-types";

import { Body, ProcessState, ProcessStatus } from "../../Global/components"
import ReferralFormSymptoms from "./ReferralFormSymptoms";
import ReferralFormCard from "./ReferralFormCard";


import { SelectedImages} from "../Gallery/BaseGallery";
import ReferralGallery from "../Gallery/ReferralGallery";
import ReferralThumbs from "../Gallery/ReferralThumbs";

import FileDrop from "../../../components/FileDrop";

import { PatientInfo } from "../../../../shared/types";

import NoReadWriteTextField from "../../../components/NoReadWriteTextField";
import LocationDropdown from "../../../components/LocationDropdown";
import PhoneInput from "../../../components/PhoneInput";
import FormCheckbox from "../../../components/FormCheckbox";
import FormTextField from "../../../components/FormTextField";
import FormSelect from "../../../components/FormSelect";
import FormTextArea from "../../../components/FormTextArea";
import SuccessModal from "../../../components/SuccessModal";

import SubsiteSelect from "./SubsiteSelect";
import SpecialistSelect from "./SpecialistSelect";
import { ReferralPHN, ReferralEmail, ReferralEmailPatient } from "./IsolatedComponents";

import { makeStyles } from "@material-ui/styles";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import AVAEconsultEndpoint from "./AVAEconsultEndpoint";
import FormOOPInput from "../../../components/FormOOPInput";
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => {
    return {
        checkbox: {
            marginTop: "7px",
        }  
    }
})

export type Specialties = {
    [key: string]: any
}

type InputType = "Checkboxes" | "Textfield" | "Radio" | "Number";

export type Symptom = {
    inputType: InputType,
    header?: string,
    label?: string,
    values?: string[],
    columns?: string[],
    rows?: string[]
}


export interface FormField {
    [name: string]: any
}

interface BaseFields {
    oop: boolean,
    oopData?: OOPData,
    urgent: boolean,
    locationIdx: number,    
    patientFirstName: string,
    patientLastName: string,
    patientPhone: string,
    patientEmail: string,
    emailPatient: boolean,
    specialty: string,
    subsite: string,
    specialistUid: string,
    description: string,
    questionsForConsultant: string,
    medicalHistory: string
}

interface ReferralInputFields extends BaseFields {
    phn: string
}

interface ResetFields extends BaseFields {
    patientInfo: PatientInfo,
}

export const ReferralForm = () => {
    const { unregister, handleSubmit, control, getValues, setValue, trigger, reset, clearErrors, watch } = useForm({mode: "onTouched"});
    const { isDirty } = useFormState({control: control});

    const subsite = watch("subsite");
    const specialty = watch("specialty");
    const oop = watch("oop");

    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const [specialties, setSpecialties] = useState<Specialties>({});
    const [symptomFields, setSymptomFields] = useState<FormField>({});
    
    const [resetToggle, setResetToggle] = useState(false);//Toggle to reset filedrop
    const [processState, setProcessState] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState('');

    //Generic file attachments
    const [attachments, setAttachments] = useState<FileDrop.AttachmentList>({});

    //Images
    const [selectedImages, setSelectedImages] = useState<SelectedImages>({});

    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);
        setErrorMessage("");
        
        logAnalyticsEvent("portal_referral_start");

        const form = new FormData();
        const inputFields: ReferralInputFields = {
            phn: data.patientInfo.phn,
            oop: data.oop,
            urgent: data.urgent,
            locationIdx: data.locationIdx,    
            patientFirstName: data.patientFirstName,
            patientLastName: data.patientLastName,
            patientPhone: data.patientPhone,
            patientEmail: data.patientEmail,
            emailPatient: data.emailPatient,
            specialty: data.specialty,
            subsite: data.subsite,
            specialistUid: data.specialistUid,
            description: data.description,
            questionsForConsultant: data.questionsForConsultant,
            medicalHistory: data.medicalHistory
        }

        if(inputFields.oop === true) {
            //Format as YYYYMMDD
            data.oopData.birthDate = (data.oopData.birthDate as Date).toISOString().split('T')[0].replace(/-/g, "");
            inputFields.oopData = data.oopData;
        }        
    
        const symptoms = data.symptoms !== undefined ? decodeObject(data.symptoms) : {};

        form.append("inputFields", JSON.stringify(inputFields));
        form.append("symptoms", JSON.stringify(symptoms));
        form.append("physicianUid", authContext.uid);
        form.append("referralImageURIs", JSON.stringify(Object.values(selectedImages).map((image) => {
            return image.fullPath;
        })));

        if(data.avaUuid !== undefined && data.avaConsultId !== undefined) {
            form.append("avaData", JSON.stringify({
                avaUuid: data.avaUuid,
                avaConsultId: data.avaConsultId
            }));
        }

        Object.values(attachments).forEach((attachmentWithPreview) => {
            form.append("attachment", attachmentWithPreview.file, attachmentWithPreview.file.name);
        });
        
        serverRequest(authContext.user, {}, form, 'econsult')
        .then((res: any) => {
            
            logAnalyticsEvent("portal_referral_success");
            
            setProcessState(ProcessState.success);

            resetForm();

            //This sucks but I can't figure out a better way to stop PHN from showing its required after the form is submitted
            setTimeout(() => {
                clearErrors("patientInfo");
            }, 1);

            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 2000)
        })
        .catch((error: Error) => {
            logAnalyticsEvent("portal_referral_failed");

            console.log(error);
            setErrorMessage(error.message);
            setProcessState(ProcessState.error);
        });         
        
    }
    
    const onError = () => {
        setErrorMessage("Check form for errors.");
        setProcessState(ProcessState.error);
    }

    const resetForm = () => {
        const resetFields: ResetFields = {
            locationIdx: getValues("locationIdx"),
            patientInfo: {
                phn: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            oop: false,
            oopData: {
                firstName: '',
                surname: '',
                birthDate: '',
                genderCode: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                cityName: '',
                postalCode: '',
                provinceCode: ''
            },
            urgent: false,
            patientFirstName: '',
            patientLastName: '',
            patientPhone: '',
            patientEmail: '',
            emailPatient: false,
            specialty: getValues("specialty"),
            subsite: getValues("subsite"),
            specialistUid: getValues("specialistUid"),
            description: '',
            questionsForConsultant: '',
            medicalHistory: '',
        }
        reset({
            ...resetFields,
            ...symptomFields
            
        });
        setResetToggle(!resetToggle);
        setSelectedImages({});
    }

    

    const decodeObject = (subObj: any) => {
        if(subObj.constructor === ({}).constructor) {
            const newObj: any = {}
            Object.keys(subObj).forEach((key) => {
                const newKey = atob(key);//decode base64
                newObj[newKey] = decodeObject(subObj[key]);
            });
            return newObj;
        }
        else {
            return subObj;
        }
    }

    const fetchSpecialties = (): Promise<any> => {
        return fbFirestore.collection("specialties").get()
        .then((querySnapshot) => {
            let specialtiesObj: any = {}
            querySnapshot.forEach((doc) => {
                specialtiesObj[doc.id] = doc.data();
            });
          
            return Promise.resolve(specialtiesObj);
        })
        .catch((error: Error) => {
            console.log(error);
        })
    }


    useEffect(() => {
        setValue("urgent", false);
    }, [specialty])


    useEffect(() => {
        fetchSpecialties()
        .then((specialtiesObj: any) => {
            setValue("specialty", Object.keys(specialtiesObj).sort()[0])
            setSpecialties(specialtiesObj);
        })
    }, []);

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }


    return (
        <>
            <Body title="eConsult" subtitle="">
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <fieldset disabled={isDisabled()} >
                        <Row>
                            {/* Referral form */}
                            <Col xs={7}>
                                
                                <AVAEconsultEndpoint setValue={setValue}/>
                                
                                
                                <ReferralFormCard title="Patient Information">
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="phn">
                                            <ReferralPHN
                                                control={control}
                                                trigger={trigger}
                                                setValue={(newPatientInfo: PatientInfo) => {
                                                    if(newPatientInfo.firstName !== "")
                                                        setValue("patientFirstName", newPatientInfo.firstName, { shouldValidate: true })
                                                    if(newPatientInfo.lastName !== "")
                                                        setValue("patientLastName", newPatientInfo.lastName, { shouldValidate: true })
                                                    if(newPatientInfo.email !== "")
                                                        setValue("patientEmail", newPatientInfo.email, { shouldValidate: true })
                                                    if(newPatientInfo.phone !== "")
                                                        setValue("patientPhone", newPatientInfo.phone, { shouldValidate: true })
                                                }}
                                                getValues={getValues}
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="outOfProvince">
                                            <div className={classes.checkbox}>
                                                <FormCheckbox
                                                    name="oop"
                                                    control={control}
                                                    label="Out of Province"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="patientFirstName">
                                            <FormTextField
                                                name="patientFirstName"
                                                control={control}
                                                label="Patient First Name"
                                                rules={{required: {value: true, message: "Patient first name is required"}}}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="patientLastName">
                                            <FormTextField
                                                name="patientLastName"
                                                control={control}
                                                label="Patient Last Name"
                                                rules={{required: {value: true, message: "Patient last name is required"}}}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="patientPhone">
                                            <PhoneInput
                                                name="patientPhone"
                                                control={control}
                                                label="Patient Phone Number"
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="patientEmail">
                                            <ReferralEmail
                                                control={control}
                                                trigger={trigger}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="emailPatient">
                                            
                                            <ReferralEmailPatient
                                                control={control}
                                                setValue={setValue}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </ReferralFormCard>
                                <Collapse in={oop} unmountOnExit>
                                    <ReferralFormCard title="Out of Province Data">
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <FormOOPInput oop={oop} control={control} getValues={getValues} setValue={setValue} disabled={isDisabled()}/>
                                            </Form.Group>
                                        </Form.Row>
                                    </ReferralFormCard>
                                </Collapse>
                                {/* Referral Information */}
                                <ReferralFormCard title="Referring Physician">
                                    {/* Doctor's Info */}
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="physicianName">
                                            <NoReadWriteTextField
                                                label="Physician"
                                                value={`${authContext.profile ? authContext.profile.firstName : ""} ${authContext.profile ? authContext.profile.lastName : ""}`}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="practiceId">
                                            <NoReadWriteTextField
                                                label="Practice ID"
                                                value={authContext.profile ? authContext.profile.practiceId : ""}  
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="location">
                                            <LocationDropdown
                                                name="locationIdx"
                                                control={control}
                                                label="Clinic"
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="date">
                                            <NoReadWriteTextField
                                                label="Date"
                                                value={new Date().toDateString()}  
                                            />
                                        </Form.Group>

                                    </Form.Row>
                                </ReferralFormCard>


                                {/* Specialties and Subsites*/}
                                <ReferralFormCard>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="specialty">
                                            <FormSelect
                                                name="specialty"
                                                control={control}
                                                label="Specialty"
                                                options={Object.keys(specialties).sort()}
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="subsite">
                                            <SubsiteSelect
                                                control={control}
                                                specialties={specialties}
                                                setValue={setValue}
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <SpecialistSelect 
                                        control={control}
                                        setValue={setValue}
                                        disabled={isDisabled()}
                                    />
                                </ReferralFormCard>{/* Specialties and Subsites*/}            
                                                    
                                {specialty ===  "Cardiology" ? 
                                    <ReferralFormCard title="Priority">
                                            <Form.Group as={Col} md="6">
                                                <div className={classes.checkbox}>
                                                    <FormCheckbox
                                                        name="urgent"
                                                        control={control}
                                                        label="Urgent, patient is having anginal chest pains"
                                                    />
                                                </div>
                                            </Form.Group>
                                    </ReferralFormCard>
                                 : <></>}

                                {/* Symptoms */}
                                <ReferralFormSymptoms
                                    control={control}
                                    specialties={specialties}
                                    fields={symptomFields}
                                    setFields={setSymptomFields}
                                    unregister={unregister}
                                    disabled={isDisabled()}
                                />
                                

                                {/* Other information */}
                                <ReferralFormCard title="Other Information">

          

                                    {/* Freeform Text */}
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextArea
                                                name="description"
                                                control={control}
                                                initRows={1}
                                                label="Description"
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextArea
                                                name="questionsForConsultant"
                                                control={control}
                                                initRows={1}
                                                label="Questions for Consultant"
                                                disabled={isDisabled()}
                                                rules={{required: {value: true, message: "Questions for consultant is required"}}}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <FormTextArea
                                                name="medicalHistory"
                                                control={control}
                                                initRows={1}
                                                label="Medical History"
                                                disabled={isDisabled()}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </ReferralFormCard>{/* Other information */}
                                
                                
                                {/* Image thumbnails */}
                                <ReferralFormCard title="Attached Images (Click an image on the right to add or remove it)">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="attachedImages">
                                            <ReferralThumbs selectedImages={selectedImages} handleRemove={(image) => {
                                                let newSelectedImages = {...selectedImages};
                                                if(newSelectedImages[image.fullPath] !== undefined) {//Make sure it exists
                                                    delete newSelectedImages[image.fullPath];
                                                    setSelectedImages(newSelectedImages);
                                                }
                                            }}/>
                                        </Form.Group>
                                    </Form.Row>
                                </ReferralFormCard>{/* Image thumbnails */}
                                
                                {/* Generic file upload */}
                                <ReferralFormCard title="Other Attachments">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="otherAttachments">
                                            <FileDrop.FileDrop reset={resetToggle} attachments={attachments} setAttachments={setAttachments}/>
                                        </Form.Group>
                                    </Form.Row>
                                </ReferralFormCard>{/* Generic file upload */}

                                {/* Submit */}
                                <Form.Row>
                                    <Form.Group as={Col} md="auto" controlId="submit">
                                        <button type="submit"className="button-border">Submit</button>
                                    </Form.Group>
                                    <Form.Group as={Col} md="auto" controlId="status">
                                        <ProcessStatus processState={processState} errorMessage={errorMessage}><div className="alert alert-success">eConsult Sent Successfully</div></ProcessStatus>
                                    </Form.Group>
                                </Form.Row>{/* Submit */}
                            </Col>{/* Referral form */}

                            {/* Images */}
                            <Col xs={5}>
                                <ReferralGallery selectedImages={selectedImages} setSelectedImages={setSelectedImages}/>
                            </Col>{/* Images */}
                            
                        </Row>
                    </fieldset>
                </Form>
            </Body>
            <SuccessModal
                text={"eConsult sent successfully."}
                show={processState === ProcessState.success}
            />
            <LeavePagePrompt isDirty={isDirty}/>
        </>

    );
}
