import React from "react";
import { Control, useWatch } from "react-hook-form";
import SendButton from "../../../components/SendButton";

interface FormSendButtonProps {
    control: Control,
    disabled: boolean
}


const FormSendButton = ({control, disabled}: FormSendButtonProps) => {
    const patients = useWatch({control, name: "patients", defaultValue: []});

    return (
        <SendButton
            label="Send"
            disabled={disabled || patients === undefined || patients.length === 0}
        />
    );
}

export default FormSendButton;
