
import React from 'react';
import firebase from "firebase";
import { dateTimeHelp } from "@alethea-medical/utilities";
import { formatTimeLong } from "../../../models/formatTime";
import { PatientMessage } from "./types";




interface MessageSubheaderProps {
    message: PatientMessage
}

const MessageSubheader = ({ message }: MessageSubheaderProps) => {

    const isDelayedMessageSent = (created: firebase.firestore.Timestamp, delayed?: boolean): boolean => {
        if(delayed) {

            const date = new Date();

            //Created yesterday?
            if(date.getDate() != created.toDate().getDate()) {
                const timeDiff = firebase.firestore.Timestamp.now().toMillis() - created.toMillis()
                const minDiff = 7 * 60 * 60 * 1000;//7 hours in milliseconds

                //If it was created yesterday, check if time diff is more than 7 hours. If both are true, then it has been sent
                if(timeDiff >= minDiff) {
                    return true
                }
            }
        }
        return false;
    }

    const getDelayedMessageSendTime = (message: PatientMessage) => {
        const date = message.created.toDate();
        const scheduledDay = dateTimeHelp.addDays(date, 1);
        scheduledDay.setHours(7, 0, 0, 0);
        return scheduledDay;
    }

    const getMessageTime = (message: PatientMessage) => {
        return `Created at ${formatTimeLong(message.created.toDate())}`
    }

    const getMessageTimeLegacy = (message: PatientMessage) => {
        if(message.delay) {
            const date = message.created.toDate();
            const scheduledDay = dateTimeHelp.addDays(date, 1);
            scheduledDay.setHours(7, 0, 0, 0);
            return `Created at ${formatTimeLong(message.created.toDate())} - ${isDelayedMessageSent(message.created, message.delay) ? "Sent at" : "Scheduled to send at" } ${formatTimeLong(scheduledDay)}`;
        }
        return `${formatTimeLong(message.created.toDate())}`
    }

    const getMessageRecipient = (message: PatientMessage) => {
        return `${message.patientInfo.email}, PHN: ${message.patientInfo.phn}`
    }

    const getMessageStatusTime = (message: PatientMessage) => {
        if(message.status === "sending" && message.delay) {
            const scheduledDay = getDelayedMessageSendTime(message);

            return (
                <>
                    Scheduled to send at {formatTimeLong(scheduledDay)}
                </>
            );
        }
        else {
            return (
                <>
                    {formatTimeLong(message.statusUpdatedAt.toDate())}
                </>
            );

        }
    }


    const getMessageStatus = (message: PatientMessage) => {
        if(message.subType === "Forms" && message.messagePDFURI !== undefined) {
            return (
                <>
                    <b>Response Received</b>
                </>
            );
        }
        switch(message.status) {
            case "delivered":
                return (
                    <>
                        <b>Delivered</b>
                    </>
                );
            case "failed":
                return (
                    <>
                        <b>Failed</b>
                    </>
                );
            case "sending":
                if(message.delay)
                    return (
                        <>
                            <b>Scheduled</b>
                        </>
                    );
                return (
                    <>
                        <b>Sending</b>
                    </>
                );
            case undefined:
                return (
                    <>
                    </>
                )
        }
    }

    const getMessageType = (message: PatientMessage) => {
        switch(message.subType){
            case "Forms":
                return "Form";
            case "OneWayPatient":
                return "One-Way Message";
            case "PatientCC":
                return "Patient CC";
            case "MOAMessage":
                return "MOA Message";
            default:
                return "";
        }
    }

    return (
        <>
            <div>
                {getMessageRecipient(message)}
            </div>
            {(message.status !== undefined && message.statusUpdatedAt !== undefined) ?
                <>
                    <div>
                        {getMessageTime(message)}
                    </div>
                    <div>
                        {getMessageStatus(message)} - {getMessageStatusTime(message)}
                    </div>
                </>
                :
                <>
                    {getMessageTimeLegacy(message)}
                </>
            }
            <div>
                Message Type: {getMessageType(message)}
            </div>

        </>
    );
}

export default MessageSubheader;
