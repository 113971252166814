import React from "react";
import { Col, Form, Card } from "react-bootstrap";

type ReferralFormCardProps = {
    title?: string,
    md?: string,
    children: JSX.Element[] | JSX.Element,
    className?: string
}


const ReferralFormCard = (props: ReferralFormCardProps) => {

    const renderChildren = () => {
        if(Array.isArray(props.children)){
            return [...props.children]
        }
        else {
            return props.children
        }
    }
    return (
        <Form.Row className={props.className}>
            <Form.Group as={Col} md={props.md ? props.md : "12"} controlId={props.title}>
                <Card>
                    <Card.Body>
                        {props.title && <Form.Label><div className="text-muted">{props.title}</div></Form.Label>}
                        {renderChildren()}
                    </Card.Body>                
                </Card>
            </Form.Group>
        </Form.Row>
    );
}

export default ReferralFormCard;