import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import useSizeManager from '../../../components/useSizeManager';
import CameraContainer from './CameraContainer'
import GalleryContainer from './GalleryContainer';
import PaperPage from "../../../components/PaperPage";
import Box from '@material-ui/core/Box';

const OtoscopeCamera = withRouter(({ location }) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            containers: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            },
            rgSticky: {
                position: 'sticky',
                top: '5vh'
            }
        }),
    );
    const classes = useStyles()

    //refreshes gallary when switched 
    const [autoRefresh, setAutoRefresh] = useState(false)
    const props = { autoRefresh, setAutoRefresh }

    const { sizeRef, height } = useSizeManager();

    return (
        <div ref={sizeRef}>
            <PaperPage>
                {/* Set height of page explicitly to stop page from infinitely growing when resizing */}
                <Box
                    height={height}
                >
                    <Grid container
                        spacing={1}
                        alignItems='stretch'>
                        <Grid item xs={12} lg={6}>
                            <CameraContainer {...props} paperHeight={height}/>
                        </Grid>
                        <Grid item xs={12} lg={6} className={classes.rgSticky}>
                            <GalleryContainer {...props} paperHeight={height}/>
                        </Grid>
                    </Grid>
                </Box>
            </PaperPage>
        </div>

    );


})

export default OtoscopeCamera
