import React, { useState } from "react";
import { useWatch, Control } from "react-hook-form";
import FormSelectAutocomplete from "../../../components/FormSelectAutocomplete";
import Typography from "@material-ui/core/Typography";
import AddFavoriteButton from "./AddFavoriteButton";
import {useAvailableForms} from "./useAvailableForms";
import Grid from "@material-ui/core/Grid";

export interface FormListItem {
    uid: string,
    name: string,
    favoritedStatus: string
    
}


interface FormSelectProps {
    control: Control,
    setValue: (name: string, value: any) => void,
    disabled?: boolean
}

export const FormsSelect = ({control, setValue, disabled}: FormSelectProps) => {
    const selectedForm = useWatch({control, name: "selectedForm", defaultValue: undefined});
    const locationIdx = useWatch({control, name: "locationIdx", defaultValue: 0})

    const [formList, setFormList] = useState<FormListItem[]>([]);
    const [isLoading, setLoading] = useState(false);


    //retrieve all forms possible given possible admin defined constraints
    useAvailableForms(setValue, selectedForm, setFormList)

    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <FormSelectAutocomplete
                        name="selectedForm"
                        label="Selected Form"
                        control={control}
                        options={formList}
                        getOptionLabel={(form: FormListItem) => form.name}
                        rules={{ required: { value: true, message: "Please select a form" } }}
                        disabled={disabled}
                        isLoading = {isLoading}
                        setLoading = {setLoading}
                    /> 
                </Grid>
                <Grid item md={6} xs={12}>
                    <AddFavoriteButton 
                        control={control}
                        disabled = {disabled}
                        formList = {formList}
                        setFormList = {setFormList}
                        isLoading = {isLoading}
                        setLoading = {setLoading}
                    />
                </Grid>
                <Grid item>
                    {selectedForm !== undefined && (
                        <Typography><a href={`/forms?preview=true&formId=${selectedForm.uid}&locationIdx=${locationIdx}`} target="_blank" rel="noopener noreferrer">Preview your selected form</a></Typography>
                    )}
                    {selectedForm === undefined && (
                        <Typography>Select a form to see a preview</Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

