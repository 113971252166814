import { UserProfile } from "../../shared/types";

//Build signoff on frontend so that we can show it to the user when they use the one way email sender
export default function buildSignoff (profile: UserProfile | undefined, locationIdx: number): string {
    if(profile !== undefined)
    {
        let signoff = "--"
        if(profile.firstName && profile.lastName)
            signoff += `\r\nDr. ${profile.firstName} ${profile.lastName}`;
        if(profile?.locations && profile?.locations[locationIdx]) {
            signoff += `\r\n\r\n${profile.locations[locationIdx].clinicName}`;
            if(profile.locations[locationIdx].clinicPhone)
                signoff += `\r\nPhone: ${profile.locations[locationIdx].clinicPhone}`;
            signoff += `\r\nFax: ${profile.locations[locationIdx].fax}`;
        }

        return signoff;
    }
    else
    {
        return "Error loading user profile";
    }


}