import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";
import ApplicationRoutes from './config/ApplicationRoutes';
import { createTheme, ThemeProvider, makeStyles, Theme } from '@material-ui/core/styles';
import VersionCheck from "./config/VersionCheck";
import ServiceWorkerWrapper from './config/ServiceWorkerWrapper';

const theme = createTheme({
    palette: {
        primary: {
            main: "#05707d",
        },
        secondary: {
            main: "#86d0c8",
            light: "#e3f3ef"
        },
        text: {

        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        subtitle1: {
            color: "#888",
            fontSize: "1.0em"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.8em"
            }
        }
    },
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        padding: 0
    }
}));

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <ServiceWorkerWrapper />
            <Container fluid className={classes.container}>
                <VersionCheck />
                <div className={classes.root}>
                    <ApplicationRoutes />
                </div>
            </Container>
        </ThemeProvider>
    );
}

export default App;
