import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: 0,
        minHeight: "95vh",
        width: "99%"//Stop horizontal size from overflowing
    },
    lockHeight: {
        height: "95vh",
        overflow: "hidden",
    }
}));

interface PaperPageProps {
    className?: string,
    lockHeight?: boolean
    children: React.ReactNode
}

const PaperPage = ({ className, lockHeight, children }: PaperPageProps) => {
    const classes = useStyles();

    return (
        <Paper
            elevation={1}
            variant="outlined"
            className={`${className ? className : ""} ${classes.root} ${lockHeight ? classes.lockHeight: ""}`} 
        >
            {children}
        </Paper>
    );
}

export default PaperPage;