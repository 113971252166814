import React, { useState, useContext } from "react";
import FileDrop from "../../../components/FileDrop";
import { Modal } from "react-bootstrap";
import { ProcessState, ProcessStatus } from "../../Global/components";
import { fbStorage } from "../../../firebase";
import { AuthContext } from "../../../AuthProvider";



const acceptedFileTypes = [
    'jpg',
    'JPG',
    'jpeg',
    'JPEG',
    'png',
    'PNG',
    'mp4'
]

interface UploadFilesModalProps {
    show: boolean,
    setShow: (show: boolean) => void,
    submittedHandler?: () => void
}

const UploadFilesModal = (props: UploadFilesModalProps) => {
    const authContext = useContext(AuthContext);

    const { show, setShow, submittedHandler } = props;
    const [filesToUpload, setFilesToUpload] = useState<FileDrop.AttachmentList>({});
    const [resetFiles, setResetFiles] = useState(false);
    const [processState, setProcessState] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState("");



    const handleClose = (event: any) => {
        if(processState !== ProcessState.running) {
            setResetFiles(!resetFiles);
            setShow(false);
            setErrorMessage("");
            setProcessState(ProcessState.idle);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setErrorMessage("");
        setProcessState(ProcessState.running);
        let timestamp = (new Date()).getTime();//Name file with millisecond timestamp
        if(Object.keys(filesToUpload).length > 0) {
            const uploadPromises = Object.keys(filesToUpload).map((key: string) => {
                timestamp += 1;
                const file = filesToUpload[key].file;
                let folderName = "images";
                let fileType = "jpg";

                //We only allow mp4 videos to be uploaded, so anything else will be an image
                if(file.name.split('.')[1] === 'mp4') {
                    folderName = "videos"
                    fileType = "mp4";
                }
                    
                return fbStorage.ref(`${folderName}/${authContext.uid}/${timestamp}.${fileType}`).put(file)
                .then(() => {
                    return {
                        name: file.path,
                        key: key,
                        success: true
                    };
                })
                .catch((error: Error) => {
                    console.error(error);
                    return {
                        name: file.path, 
                        key: key,
                        success: false
                    };
                })
            });
            Promise.all(uploadPromises)
            .then((results) => {
                let failedUploads: string[] = []
                const newFilesToUpload: FileDrop.AttachmentList = {};
                let oneFailed = false;
                results.forEach((result) => {
                    if(!result.success) {
                        oneFailed = true;
                        newFilesToUpload[result.key] = filesToUpload[result.key];
                        failedUploads.push(result.name);
                    }
                    else {
                        URL.revokeObjectURL(filesToUpload[result.key].preview)
                    }
                });
                setFilesToUpload(newFilesToUpload);
                if(submittedHandler)
                    submittedHandler();

                if(oneFailed) {
                    setErrorMessage(`The remaining files failed to upload.`);
                    setProcessState(ProcessState.error);
                }
                else {
                    setProcessState(ProcessState.success);
                    setTimeout(() => {
                        setProcessState(ProcessState.idle);
                    }, 1000);
                }


            })
        }
        else {
            setErrorMessage("Select at least one file");
            setProcessState(ProcessState.error);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
                }, 1000);
        }

        


    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-muted">Upload files</Modal.Title>
            </Modal.Header>
            <fieldset disabled={processState === ProcessState.running} >
                <Modal.Body>
                    <FileDrop.FileDrop reset={resetFiles} attachments={filesToUpload} acceptedFileTypes={acceptedFileTypes} setAttachments={setFilesToUpload}/>
                    <ProcessStatus processState={processState} errorMessage={errorMessage}><div className="alert alert-success mr-auto">Upload successful.</div></ProcessStatus>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button-border mr-auto" onClick={handleSubmit}>
                        Submit
                    </button>
                    <button className="button-border" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </fieldset>

        </Modal>
    );
}

export default UploadFilesModal;