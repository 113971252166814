import React, { FunctionComponent, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(5)
        },
        paper: {
            position: "relative",
        },
        closeButton: {
            position: "absolute",
            top: 0,
            right: 0
        }
    }),
);


interface PaperModalProps {
    show: boolean,
    setShow?: (show: boolean) => void,
    sizeRef?: React.MutableRefObject<HTMLDivElement | null>,
    updateSize?: () => void,
    width?: number | string,
    height?: number | string,
    flexWidth?: boolean,
    flexHeight?: boolean
}
    

const PaperModal:FunctionComponent<PaperModalProps> = ({ show, setShow, sizeRef, updateSize, width="90vw", height="90vh", flexWidth, flexHeight, children }) => {

    const classes = useStyles();

    const handleClose = () => {
        if(setShow !== undefined)
            setShow(false);
    }

    useEffect(() => {
        if(updateSize)
            updateSize()
    })

    return (
        <>
            <Modal className={classes.container} open={show} onClose={handleClose}>
                <div ref={sizeRef}>
                    <Paper className={classes.paper} style={{
                        width: flexWidth ? undefined : width,
                        height: flexHeight ? undefined : height
                    }} elevation={0}>
                        {setShow !== undefined && (
                            <IconButton className={classes.closeButton} onClick={handleClose}><CloseIcon/></IconButton>
                        )}
                        {children}
                    </Paper>
                </div>
            </Modal>
        </>
    );
}

export default PaperModal;