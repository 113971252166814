import React, {useCallback, useState, useEffect, useContext} from "react";
import Gallery from "react-photo-gallery";
import { BinnedImages, fetchAndBinFiles } from "../../../models/fetchFiles";
import { AuthContext } from "../../../AuthProvider";
import { Form, Col } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import "./Gallery.css";


 //Code from https://www.npmjs.com/package/react-photo-gallery "selection using custom renderImage" example

 export interface ImageSrc {
    fullPath: string,
    name: string,
    width: number,
    height: number,
    src: string
}

export type SelectedImages = {
    [uri: string]: ImageSrc
}

export interface BinnedImgsToShow {
    [timestampMilli: string]: ImageSrc[]
}

export interface GalleryRenderComponentProps {
    index: number,
    photo: ImageSrc,
    margin: string,
    selected: boolean,
    editing: boolean,
    selectImage: (image: ImageSrc) => void
    unselectImage: (image: ImageSrc) => void,
    handleShowModal: (image: ImageSrc) => void,
}

type BaseGalleryProps = {
    selectedImages: SelectedImages,
    editing: boolean,
    refresh?: boolean
    setSelectedImages: (selectedImages: SelectedImages) => void,
    GalleryRenderComponent: ( props: GalleryRenderComponentProps) => JSX.Element,
    handleShowModal: (image: ImageSrc) => void
}  

export const BaseGallery = (props: BaseGalleryProps) => {

    const { selectedImages, editing, refresh, setSelectedImages, GalleryRenderComponent, handleShowModal } = props;
    const authContext = useContext(AuthContext);

    //Amount to increase the max days by when load more is pressed
    const loadMoreAmount = 7;
    //By default load the past 7 days that images exist
    const [maxDays, setMaxDays] = useState<number>(7);

    //image refs binned by date where the timestamp is the key
    const [binnedImages, setBinnedImages] = useState<BinnedImages>();

    //Result of getting download URLs for the image refs in binnedImages
    //Only download URLs for images that occured less than maxDays ago are fetched
    const [binnedImgsToShow, setBinnedImgsToShow] = useState<BinnedImgsToShow>({});

    //Get all images when refresh requested
    useEffect(() => {
        if(refresh !== undefined) {
            getImages();
        }
    }, [refresh]);

    //Will get on page load
    useEffect(() => {
        if(authContext.profile !== undefined) {
            getImages();
        }
    }, [authContext.profile]);

    useEffect(() => {
        renderGallery();
    }, [binnedImages, maxDays])

    const getImages = () => {
        setBinnedImgsToShow({});
        let newBinnedImages: BinnedImages = {};
        fetchAndBinFiles('images', authContext.uid)
        .then((images: BinnedImages) => {
            newBinnedImages = images;

            return fetchAndBinFiles('videos', authContext.uid)
        })
        .then((videos: BinnedImages) => {
            Object.keys(videos).forEach((timestampMilli) => {
                if(newBinnedImages[timestampMilli] !== undefined) { //If bin exists on images, concat videos 
                    newBinnedImages[timestampMilli] = newBinnedImages[timestampMilli].concat(videos[timestampMilli]);
                }
                else { //Otherwise create a new bin
                    newBinnedImages[timestampMilli] = videos[timestampMilli];
                }
            })
            setBinnedImages(newBinnedImages);
        });
    }

    const selectImage = (image: ImageSrc) => {
        let newSelectedImages = {...selectedImages};
        newSelectedImages[image.fullPath] = image
        setSelectedImages(newSelectedImages);
        
    }
    const unselectImage = (image: ImageSrc) => {
        let newSelectedImages = {...selectedImages};
        if(newSelectedImages[image.fullPath] !== undefined) {//Make sure it exists
            delete newSelectedImages[image.fullPath];
            setSelectedImages(newSelectedImages);
        }
    }

    

    const imageRenderer = useCallback(
        ({ index, key, photo }) => (
          <GalleryRenderComponent
            selected={selectedImages[photo.fullPath] !== undefined}
            key={key}
            margin={"2px"}
            index={index}
            photo={photo}
            editing={editing}
            selectImage={selectImage}
            unselectImage={unselectImage}
            handleShowModal={handleShowModal}
          />
        ),
        [selectedImages]
    );


    const renderGallery = () => {
        if(binnedImages !== undefined) {
            //Sort by datetime from newest to oldest, and then filter
            Promise.all(Object.keys(binnedImages).sort().reverse().filter((timestampMilli: string, index: number) => {
                //Only fetch images that were taken less than max days in the past, and that we haven't fetched before
                return index < maxDays && binnedImgsToShow[timestampMilli] === undefined;
            })
            .map((timestampMilli: string) => {
                return Promise.all(binnedImages[timestampMilli].map((imageRef) => {
                    return imageRef.getDownloadURL()
                    .then((src) => {
                        return {
                            fullPath: imageRef.fullPath,
                            name: imageRef.name,
                            src: src,
                            height: 1,
                            width: 1
                        }
                    })
                    .catch(() => {
                        return {
                            fullPath: imageRef.fullPath,
                            name: imageRef.name,
                            src: "",
                            height: 1,
                            width: 1
                        }
                    })
                }))
                .then((images: ImageSrc[]) => {
                    return {
                        images: images,
                        timestampMilli: timestampMilli
                    }
                })
            }))
            .then((result) => {
                const newbinnedImgsToShow = {...binnedImgsToShow}
                result.forEach((newBin) => {
                    newbinnedImgsToShow[newBin.timestampMilli] = newBin.images;
                })
    
                setBinnedImgsToShow(newbinnedImgsToShow);
            })
    
        }
        
    }

    return (
        <>
            {binnedImages === undefined && <Form.Label>Loading images...</Form.Label>}
            {binnedImages !== undefined && Object.keys(binnedImages).length === 0 && <Form.Label>You have no images yet.</Form.Label>}
            {binnedImgsToShow !== {} && Object.keys(binnedImgsToShow).length > 0 && 
            (Object.keys(binnedImgsToShow).map((timestampMilli) => {
                return (
                    <Form.Row key={`gallery_${timestampMilli}`}>
                        <Form.Group as={Col} md={"12"}>
                            {<Form.Label><div className="text-muted">{`${new Date(parseInt(timestampMilli)).toDateString()}`}</div></Form.Label>}
                            <Gallery photos={binnedImgsToShow[timestampMilli]} renderImage={imageRenderer}/>
                            <Divider className="mt-3"/>
                        </Form.Group>
                    </Form.Row>
                );
            }))}
            <button className="button-border mt-2" onClick={(event: any) => {event.preventDefault(); setMaxDays(maxDays + loadMoreAmount) }} 
                disabled={binnedImages !== undefined && Object.keys(binnedImgsToShow).length === Object.keys(binnedImages).length}>Load more</button>
        </>

    );
}

