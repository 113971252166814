import React from 'react';

import MessagingGallery from '../Gallery/MessagingGallery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1)
        }
    }),
);


interface AttachmentViewerProps {
    messageMediaURIs: string[]
}

const AttachmentViewer = ({ messageMediaURIs }: AttachmentViewerProps) => {

    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.root} spacing={1}>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h5">Attachments</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MessagingGallery referralMediaURIs={messageMediaURIs}/>
                </Grid>

            </Grid>
        </>
    );
}

export default AttachmentViewer;