import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import TextField from "@material-ui/core/TextField";
import NumberFormat from 'react-number-format';
import isPhone from "./isPhone";

interface PhoneInputProps extends ControlledInputProps {
    rules?: any
}

//Formats input as phone number with format (403) 123-4567
const PhoneInput = ({ name, control, defaultValue, rules, label, ...rest }: PhoneInputProps) => {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={Object.assign(rules ? rules : {}, { validate: {
                isPhone: isPhone
            }})}
            render={({field, fieldState}) =>        
                <NumberFormat 
                    {...field}
                    format="(###) ###-####" 
                    mask="_" 
                    customInput={TextField} 
                    label={label}            
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    variant="outlined" 
                    fullWidth 
                    margin="dense"
                    InputLabelProps={{
                        disableAnimation: true
                    }}
                />
            }
        />

    );
}

export default PhoneInput;