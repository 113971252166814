import React from "react"

import Typography from '@material-ui/core/Typography';

import EconsultCard from "./EconsultCard";
import { Econsult } from "../../../../../shared/types";

interface EconsultPatientProps {
    econsult: Econsult
}

const EconsultPatient = ({ econsult }: EconsultPatientProps) => {

    return (
        <>
            <EconsultCard title="Patient">
                <Typography>
                    {econsult.patientFirstName} {econsult.patientLastName}
                </Typography>
                <Typography>
                    PHN: {econsult.phn}
                </Typography>
                {econsult.oop !== false && <Typography>
                    Out of Province
                </Typography>}
                {econsult.patientEmail !== "" && <Typography>
                    Email: {econsult.patientEmail}
                </Typography>}
                {econsult.patientPhone !== "" && <Typography>
                    Phone: {econsult.patientPhone}
                </Typography>}
            </EconsultCard>      
        </>
    );
}

export default EconsultPatient;