import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
    },
}));

const Dashboard = (props: any) => {
    const classes = useStyles();
    return (
        <>
            <Sidebar />
            <main className={classes.content}>
                {props.children}
            </main>

        </>
    );
}

export default Dashboard;