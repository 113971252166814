import React from "react";
import TextField from "@material-ui/core/TextField";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>{
    const shared = sharedStyles(theme);
    return {
        ...shared
    }
});

interface ReadonlyTextFieldProps {
    value: string,
    label?: string
}

const NoReadWriteTextField = (props: ReadonlyTextFieldProps) => {
    const classes = useStyles();
    const { value, label } = props;
    return (
        <TextField 
            className={classes.canDisable}
            label={label}
            disabled
            margin="dense"
            fullWidth
            variant="outlined"                             
            value={value}
        />
    );
}

export default NoReadWriteTextField;