import React from "react"
import { ActivityUser } from "../../../../shared/types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        root: {
            padding: theme.spacing(1)
        },
        header: {
            
        }
	}),
);

interface MessagesHeaderProps {
    otherUser?: ActivityUser
}


const MessagesHeader = ({ otherUser }: MessagesHeaderProps) => {

    const classes = useStyles();

    return (
        <>
            <Grid container
                className={classes.root}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} md={8}>
                    <Typography className={classes.header}>
                        {otherUser?.firstName} {otherUser?.lastName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Tooltip
                        title="We bill on your behalf each time you send a message. Only the first message in a 24 hour period will be billed. Subsequent messages will not be billed."
                        children={
                            <div className="tooltip-hover">How are my messages billed?</div>
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default MessagesHeader;