import React, {useState, useEffect, useContext} from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm, useFieldArray, useFormState } from "react-hook-form";
import { AuthContext } from "../../../AuthProvider";
import serverRequest from "../../../models/serverRequest";
import { logAnalyticsEvent } from "../../../firebase";
import { Body, ProcessState, ProcessStatus } from '../../Global/components';

import Tooltip from "@material-ui/core/Tooltip";
import { FormsSelect } from "./FormsSelect";
import LocationDropdown from "../../../components/LocationDropdown";
import InputLabel from "@material-ui/core/InputLabel";
import PatientRow from "./PatientRow";
import AddPatientButton from "./AddPatientButton";
import DelayAllCheckbox from "./DelayAllCheckbox";
import FormSendButton from "./FormSendButton";
import SuccessModal from "../../../components/SuccessModal";
import { PatientInfo } from "../../../../shared/types";
import LeavePagePrompt from "../../../components/LeavePagePrompt";


export type FormPatient = {
    email: string,
    patientInfo: PatientInfo,
    delay: boolean
}


export const FormSender = () => {
    const { handleSubmit, control, setValue, reset } = useForm({mode: "onTouched"});
    const { isDirty } = useFormState( { control: control });
    const { fields, append, remove,  } = useFieldArray({
        control,
        name: "patients",
      }
    );


    const authContext = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [processState, setProcessState] = useState(ProcessState.idle);

    useEffect(() => {
        reset({
            locationIdx: 0,
            patients: [{
                patientInfo: {
                    phn: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: ''
                },
                email: ''
            }]
        });
    }, [])


    
    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);
        setErrorMessage('');

        logAnalyticsEvent("form_sender_start", {
            numPatients: data.patients.length,
            form: data.selectedForm.name
        });

        //Build body for https request
        let body = {
            physicianUid: authContext.uid,
            locationIdx: data.locationIdx,
            formId: data.selectedForm.uid,
            patients: data.patients.map((patient: FormPatient) => {
                return {
                    phn: patient.patientInfo.phn,
                    email: patient.email,
                    delay: patient.delay
                }
            })
        }

        //Send request to server to send forms
        serverRequest(authContext.user, {'Content-Type': 'application/json'}, JSON.stringify(body), 'send-forms')
        .then((res: any) => {
        
            logAnalyticsEvent("form_sender_success");

            setProcessState(ProcessState.success);
            setTimeout(() => {
                
                setProcessState(ProcessState.idle);
                reset({
                    locationIdx: data.locationIdx,
                    selectedForm: data.selectedForm,
                    patients: []
                });
                append({
                    patientInfo: {
                        phn: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: ''
                    },
                    email: ''
                });
            }, 2000);

        })//Catch any errors on the server side. Will also catch error if all emails fail to send
        .catch((error: Error) => {

            logAnalyticsEvent("form_sender_failed");
            setErrorMessage(error.message);
            setProcessState(ProcessState.error);
        });                    
    }

    const onError = () => {
        setErrorMessage("Check form for errors.");
        setProcessState(ProcessState.error);
    }


    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <>
            <Body title="Forms">
                <Form onSubmit={handleSubmit(onSubmit, onError)}>  
                    <fieldset disabled={isDisabled()} >
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <LocationDropdown
                                    name="locationIdx"
                                    control={control}
                                    label="Clinic"
                                    disabled={isDisabled()}
                                />
                            </Form.Group>    

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <FormsSelect
                                    control={control}
                                    setValue={setValue}
                                    disabled={isDisabled()}
                                    
                                />
                            </Form.Group>
                            
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="auto">
                                <Form.Label>
                                    <Tooltip title="The selected form will be individually emailed to each patient."
                                        placement="right">
                                        <div className="tooltip-hover">How is my form sent?</div>
                                    </Tooltip>
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <AddPatientButton
                                    control={control}
                                    setValue={setValue}
                                    append={append}
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                        </Form.Row>

                        {/* Patient rows header */}
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <InputLabel className="ml-1 text-muted">
                                    Patient ID (PHN)
                                </InputLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                                <InputLabel className="ml-1 text-muted">
                                    Patient Email
                                </InputLabel>
                            </Form.Group>


                            {/* Delay email checkbox */}
                            <Tooltip title="Delay sending the email until the next day at 7:00 AM MST"
                                    placement="right">
                                <Form.Group as={Col} md="auto">
                                    <DelayAllCheckbox
                                        control={control}
                                        setValue={setValue}
                                    />
                                </Form.Group>
                            </Tooltip>
                        </Form.Row>
                        
                        {fields.map((field, index) => 
                            <PatientRow
                                key={field.id}
                                control={control}
                                index={index}
                                setValue={setValue}
                                remove={remove}
                                disabled={isDisabled()}
                            />
                        )}

                        {/* Add button */}
                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <AddPatientButton
                                    control={control}
                                    setValue={setValue}
                                    append={append}
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="2">
                                <FormSendButton
                                    control={control}
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} md="auto" controlId="status">
                                <ProcessStatus processState={processState} errorMessage={errorMessage}><div className="alert alert-success process-success">Forms Sent Successfully</div></ProcessStatus>
                            </Form.Group>
                        </Form.Row>
                    </fieldset>
                </Form>
            </Body>
            <SuccessModal
                text={"Forms sent successfully."}
                show={processState === ProcessState.success}
            />
            <LeavePagePrompt isDirty={isDirty}/>

        </>
    );
}
