import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { aletheaOrange } from '../../styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: "inline-block",//Make paper not take full width
            wordBreak: "break-all"
        },
        orange: {
            backgroundColor: aletheaOrange,
            color: "white"
        },
        dark: {
            backgroundColor: theme.palette.secondary.main,
        },
        light: {
            backgroundColor: theme.palette.secondary.light,
        },
        labelRemoveButton: {
            padding: theme.spacing(0)
        },
    }),
);


interface LabelProps {
    text: string
    color: "dark" | "light" | "orange"
    closeButton?: boolean,
    labelKey?: string,
    handleRemove?: (name: string) => void,
    className?: string
}

const Label = ({ text, color, closeButton, labelKey, handleRemove = (labelKey: string) => {}, className="" }: LabelProps) => {
    const classes = useStyles();
    const getColorClass = () => {
        switch(color) {
            case "dark":
                return classes.dark
            case "light":
                return classes.light
            case "orange":
                return classes.orange
        }
    }
    return (
        <Paper elevation={1} className={`${classes.label} ${getColorClass()} ${className}`}>
            <Grid container alignItems="center" spacing={3}>
                <Grid item>
                    {text}
                </Grid>
                {closeButton && labelKey !== undefined && (
                    <Grid item>
                        <IconButton
                            className={classes.labelRemoveButton}
                            onClick={() => {handleRemove(labelKey)}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}

export default Label;