import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import TextField from '@material-ui/core/TextField';

interface FormTextFieldProps extends ControlledInputProps {
    rules?: any
}

const FormTextField = ( { name, control, defaultValue, rules, label, ...rest }: FormTextFieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={rules}
            render={({field, fieldState}) => 
                <TextField 
                    {...field}
                    {...rest}
                    onChange={(e) => {
                        if(/^[0-9]*$/.test(e.target.value))
                            field.onChange(e);
                    }}
                    label={label}            
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    variant="outlined" 
                    fullWidth 
                    margin="dense"
                    InputLabelProps={{
                        disableAnimation: true
                    }}
                />
            }
        />
    );
}

export default FormTextField

