import React from "react";
import { PatientMessage } from "./types";
import MessageSubheader from "./MessageSubheader";
import MessageListItem from "../../../components/MessageListItem";

interface PatientMessageListItemProps {
    message: PatientMessage,
    selected: boolean,
    setSelected: (id: string, checked: boolean) => void,
    openMessage: (id: string) => void
}

const PatientMessageListItem = ({ message, selected, setSelected, openMessage }: PatientMessageListItemProps) => {
    return (
        <MessageListItem
            selected={selected}
            setSelected={setSelected}
            openMessage={openMessage}
            id={message.id}
            read={message.read}
            primary={message.subject}
            secondary={<MessageSubheader message={message}/>}
        />
    );
}

export default PatientMessageListItem;