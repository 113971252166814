import React from "react";
import { useWatch, Control, FieldArrayMethodProps } from "react-hook-form";
import Button  from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
    return {
        button: {
            marginRight: "10px",
        }  
    }
})

interface AddPatientButtonProps {
    control: Control,
    setValue: (name: string, value: any) => void,
    append: (value: Partial<unknown> | Partial<unknown>[], options?: FieldArrayMethodProps | undefined) => void,
    disabled?: boolean
}

const AddPatientButton = ({control, setValue, append, disabled}: AddPatientButtonProps) => {

    const classes = useStyles();
    
    const handleClick = () => {
        append({
            patientInfo: {
                phn: '',
                firstName: '',
                lastName: '',
                phone: '',
                email: ''
            },
            email: ''
        });
    }

    return (
        <Button 
            onClick={handleClick}
            color="primary"
            disabled={disabled}

        >
            <AddCircleOutlineIcon className={classes.button}/>
            Add another recipient
        </Button >
    );
}

export default AddPatientButton;