import React from "react";
import { useWatch, Control } from "react-hook-form";
import Button  from "@material-ui/core/Button";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
    return {
        button: {
            marginTop: "10px",
        }  
    }
})

interface RemovePatientButtonProps {
    index: number,
    remove: (index?: number | number[] | undefined) => void,
    disabled: boolean
}

const RemovePatientButton = ({index, remove, disabled}: RemovePatientButtonProps) => {
    

    const classes = useStyles();
    
    const handleClick = () => {
        remove(index);
    }

    return (
        <Button 
            onClick={handleClick}
            color="primary"
            disabled={disabled}
        >
            <HighlightOffIcon className={classes.button}/>
        </Button >
    );
}

export default RemovePatientButton;