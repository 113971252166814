import { useContext, useEffect } from 'react'
import { fbStorage, logAnalyticsEvent } from '../../../firebase'
import { AuthContext } from '../../../AuthProvider'
import { ProcessState, ProcessStatus } from "../../Global/components";


function dataURItoBlob(dataURI: string) {

    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;

}

interface IUploadImage {
    image: string,
    uploadingImage: boolean,
    setuploadingImage: any,
    setImage: any,
    setProcessState: any,
    autoRefresh: boolean,
    setAutoRefresh: any,
    uploadBlob: any,
    setuploadBlob: any,
    uploadingVideo: boolean,
    setuploadingVideo: any,
    errorHandler: any

}
export function useUploadMediaFile({ image, uploadingImage, setuploadingImage, setImage, setProcessState, autoRefresh,
    setAutoRefresh, uploadBlob, setuploadBlob, uploadingVideo, setuploadingVideo, errorHandler }: IUploadImage) {

    const authContext = useContext(AuthContext);

    const folderNameImage = 'images'
    const fileTypeImage = 'jpg'
    const folderNameVideo = 'videos'
    const fileTypeVideo = 'mp4'
    let timestamp = (new Date()).getTime();


    useEffect(() => {
        if (uploadingImage && image != null) {

            logAnalyticsEvent("portal_camera_image_upload_start")
            if (image != '') {
                let data = dataURItoBlob(image)
                fbStorage.ref(`${folderNameImage}/${authContext.uid}/${timestamp}.${fileTypeImage}`).put(data).then(() => {
                    logAnalyticsEvent("portal_camera_image_upload_success")

                }).then(() => {
                    setProcessState(ProcessState.idle)
                    setuploadingImage(!uploadingImage)
                    setImage('')
                    setAutoRefresh(!autoRefresh)
                }).catch(
                    (err) => {
                        errorHandler({ userMessage: err.message, error: err, hideErrorMessage: true, analyticsLog: "portal_camera_image_upload_failed" })
                    }
                )
            }
        }
        else if (uploadingVideo && uploadBlob != null) {

            logAnalyticsEvent("portal_camera_video_upload_start")
            if (uploadBlob != undefined) {
                fbStorage.ref(`${folderNameVideo}/${authContext.uid}/${timestamp}.${fileTypeVideo}`).put(uploadBlob).then(() => {
                    logAnalyticsEvent("portal_camera_video_upload_success")
                }).then(() => {
                    setProcessState(ProcessState.idle)
                    setuploadingVideo(!uploadingVideo)
                    setuploadBlob(undefined)
                    setAutoRefresh(!autoRefresh)
                }).catch(
                    (err) => {
                        errorHandler({ userMessage: err.message, error: err, hideErrorMessage: true, analyticsLog: "portal_camera_video_upload_failed" })
                    }
                )
            }
        }

    }, [uploadingImage, uploadingVideo])


}


export default useUploadMediaFile

