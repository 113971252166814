import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { AuthContext } from '../../../AuthProvider';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fbFirestore, logAnalyticsEvent } from '../../../firebase';
import { Typography } from '@material-ui/core';
import useProcessState from '../../../components/useProcessState';
import { ProcessState, ProcessStatus } from '@alethea-medical/react-components';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import PaperPage from '../../../components/PaperPage';
import useSizeManager from '../../../components/useSizeManager';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reportContainer: {
            border: 0,
            height: "100%",
            width: "100%",
        },
        largeIcon: {
            height: "60px",
            width: "60px",
            color: alpha(theme.palette.grey[500], 0.8)
        },
        floatButton: {
            position: "absolute",
        },
        floatRight: {
            top: "50%",
            right: 0
        },
        floatLeft: {
            top: "50%",
            left: 0
        },
        container: {
            position: "relative"
        },
        note: {
            marginLeft: theme.spacing(1)
        }
    }),
);

interface BillingReportProps {

}


const BillingReport = ({ }: BillingReportProps) => {
    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const [reportParams, setReportParams] = useState<string>("");
    const [page1Url, setPage1Url] = useState<string>();
    const [page2Url, setPage2Url] = useState<string>();
    const [pageNum, setPageNum] = useState<number>(0);

    const { processState, processErrorMessage, setProcessState, setProcessErrorMessage, errorHandler } = useProcessState()
    const { sizeRef, height } = useSizeManager();
    const [showButtons, setShowButtons] = useState(false);

     useEffect(() => {
        setProcessState(ProcessState.running);
        let params = {
            "ds0.doctorfilter": authContext.uid,
            "ds0.doctorfilter2": ""
        }
        logAnalyticsEvent("billing_report_start");
        fbFirestore.collection("system").doc("datastudio").get()
            .then((snapshot) => {
                const doc = snapshot.data();
                if(doc?.billingReportPage1Url === undefined || doc?.billingReportPage2Url === undefined) {
                    return Promise.reject(new Error("Unable to load billing report URL"));
                }
                setPage1Url(doc.billingReportPage1Url);
                setPage2Url(doc.billingReportPage2Url);
                return fbFirestore.collection("users").doc(authContext.uid).get()
            }).then((snapshot) => {
                if (snapshot.exists) {
                    const currentUser = snapshot.data();
                    if (currentUser?.linkedAccount != undefined) {
                        params = { ...params, "ds0.doctorfilter2": `${currentUser.linkedAccount}` };
                    }
                }
            }).then(() => {
                const paramsAsString = JSON.stringify(params);
                const encodedParams = encodeURIComponent(paramsAsString);
                setReportParams(encodedParams);
                logAnalyticsEvent("billing_report_success");
                setProcessState(ProcessState.idle);
            }).catch(
                (err) => {
                    errorHandler({ userMessage: "Error loading billing report, please try refreshing the page. Error", error: err, analyticsLog: "billing_report_failed" });
                }
            )



    }, [authContext.uid])

    return (
        // THIS FEATURE DOESN'T WORK IF YOU ARE BLOCKING TRACKING OR ADS
        <>
            <div ref={sizeRef}>
                <PaperPage>
                    <div  onMouseEnter={() => {setShowButtons(true)}} onMouseLeave={() => {setShowButtons(false)}}>
                        <Box
                            className={classes.container}
                            height={height}
                        >
                            <Typography className={classes.note} variant='caption'>*This feature is unavailable if you have any Ad or cookie blockers</Typography>
                            {showButtons && (
                                <>
                                    {pageNum === 1 && (
                                        <IconButton
                                            className={`${classes.floatButton} ${classes.floatLeft}`}
                                            onClick={() => {
                                                setPageNum(0);
                                            }}
                                        >
                                            <ChevronLeftIcon className={classes.largeIcon}/>
                                        </IconButton>
                                    )}
                                    {pageNum === 0 && (
                                        <IconButton
                                            className={`${classes.floatButton} ${classes.floatRight}`}
                                            onClick={() => {
                                                setPageNum(1);
                                            }}
                                        >
                                            <ChevronRightIcon className={classes.largeIcon}/>
                                        </IconButton>
                                    )}
                                </>
                            )}

                            {page1Url !== undefined && <iframe style={{ display: pageNum === 0 ? "" : "none"}} className={classes.reportContainer} src={`${page1Url}${reportParams}`} frameBorder="0" allowFullScreen></iframe>}
                            {page2Url !== undefined && <iframe style={{ display: pageNum === 1 ? "" : "none"}} className={classes.reportContainer} src={`${page2Url}${reportParams}`} frameBorder="0" allowFullScreen></iframe>}
                            <ProcessStatus
                                state={processState}
                                errorMessage={processErrorMessage}
                                setState={setProcessState}
                            />
                        </Box>
                    </div>

                </PaperPage>                
            </div>

        </>
    );
}

export default BillingReport;  