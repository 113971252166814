import React, { FunctionComponent } from "react";
import * as CSS from 'csstype';
import InfoIcon from '@material-ui/icons/Info';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { GalleryRenderComponentProps, ImageSrc } from "./BaseGallery";
import "./ReferralGallery.css";
import ReactPlayer from 'react-player'
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";


interface CheckmarkProps {
    selected: boolean
}

interface ContainerProps extends CheckmarkProps {
    margin: string,
    className: string,
    image: ImageSrc,
    fileType: string,
    handleShowModal: (image: ImageSrc) => void,
    onClick: (e: any) => void
}

const Checkmark = ({ selected }: CheckmarkProps) => (
    <div className="rg-checkmark-container">
        {/* Circle */}
        {/* {!selected &&
            <svg  width="20px" height="20px">
                <circle className="rg-checkmark-circle-outer" cx="12.5" cy="12.2" r="9" />
                <circle className="rg-checkmark-circle-inner" cx="12.5" cy="12.2" r="7.5" />
            </svg>
        } */}
        
        {/* Checkmark */}
        {selected && 
            <svg className="rg-checkmark" width="30px" height="30px">
                <circle className="rg-checkmark-circle-inner" cx="12.5" cy="12.2" r="7.5" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
        }
    </div>
);


const Container: FunctionComponent<ContainerProps> = (props) => {
    const { margin, className, image, selected, fileType, handleShowModal, onClick } = props;

    return (
        <div style={{ margin } as CSS.Properties} className={className}>
            <div onClick={onClick}>
                <Checkmark selected={selected}/>
                {props.children}
            </div>
            {isImage(fileType) && 
                <button className="rg-more-info button-material" onClick={(event: any) => {
                    event.preventDefault();
                    handleShowModal(image);
                }}>

                        <svg width="20px" height="20px">
                            <circle className="rg-checkmark-circle-inner" cx="10" cy="10" r="10" />
                            <InfoIcon/>
                        </svg>
                </button>
            }

            {isVideo(fileType) && 
                <button className="rg-play button-material" onClick={(event: any) => {
                    event.preventDefault();
                    handleShowModal(image);
                }}>
                    <svg width="20px" height="20px" className="rg-play">
                        <circle className="rg-checkmark-circle-inner" cx="10" cy="10" r="10" />
                        <PlayCircleFilledIcon/>
                    </svg>
                </button>
            }
        </div>
        
    );
}

const ReferralImageRenderer = ( props: GalleryRenderComponentProps) => {
    const { index, photo, margin, selected, selectImage, unselectImage, handleShowModal } = props;
    const fileType = photo.name.split('.')[1];

    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    const selectedImgStyle = {
        transform: `translateZ(0px) scale3d(${sx}, ${sy}, 1)`
    };
    const handleClick = (e: any) => {
        e.stopPropagation();
        if(selected) {
            unselectImage(photo);
        }
        else {
            selectImage(photo);
        }
        
    };

    return (
        <>
            {isImage(fileType) && 
                <Container margin={margin} image={photo} className={'rg-image-container'} selected={selected} fileType={fileType} onClick={handleClick} handleShowModal={handleShowModal}>
                    <img
                        className={`${selected ? "rg-selected-image" : "rg-unselected-image"} rg-image`}
                        style={selected ? {...selectedImgStyle } : {}}
                        src={photo.src}
                        draggable={false}
                    />
                </Container>
            }


            {isVideo(fileType) && 
                <Container margin={margin} image={photo} className={'rg-video-container'} selected={selected} fileType={fileType} onClick={handleClick} handleShowModal={handleShowModal}>
                    <ReactPlayer 
                        className={`${selected ? "rg-selected-image" : "rg-unselected-image"} rg-image`}
                        style={selected ? {...selectedImgStyle } : {}}
                        url={photo.src}
                        width='100%'
                        height='100%'
                    />
                </Container>
            }
        </>
    );
};

export default ReferralImageRenderer;