import React, { useContext, useEffect, useState } from "react";
import clsx from 'clsx';
import { LogoutButton } from "../Global/components";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemIcon } from "@material-ui/core";
import { Link, withRouter } from 'react-router-dom';

import { AuthContext } from "../../AuthProvider";

import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip';

import AssignmentIcon from '@material-ui/icons/Assignment';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ChatIcon from '@material-ui/icons/Chat';
import HelpIcon from '@material-ui/icons/Help';
import InboxIcon from '@material-ui/icons/Inbox';
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import { Description } from "@material-ui/icons";

const drawerWidth = 230;

const useStyles = makeStyles((theme: Theme) => ({

    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    icon: {
        minWidth: '45px',

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    header: {
        marginLeft: theme.spacing(2)
    },
    headerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    headerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    drawerButton: {
        gutters: theme.mixins.gutters()
    },
    drawerButtonSelected: {
        backgroundColor: theme.palette.grey[200]
    },
    username: {
        whiteSpace: "normal",
    }
}))

interface SidebarItemProps {
    text: string,
    tooltipText?: string,
    textClass?: string,
    showText: boolean,
    link: string,
    icon: React.ReactElement,
    currentPath: string | undefined
}

const SidebarItem = ({ text, tooltipText, textClass, showText, link, icon, currentPath }: SidebarItemProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        setSelected(currentPath === link)
    }, [currentPath])

    return (
        <ListItem className={`${classes.drawerButton} ${selected ? classes.drawerButtonSelected : ""}`} button component={Link} to={link}>
            <ListItemIcon className={classes.icon}>
                <Tooltip
                    title={tooltipText ? tooltipText : text}
                    children={icon}
                />
            </ListItemIcon>
            <Fade in={showText}>
                <ListItemText className={textClass} primary={text} />
            </Fade>
        </ListItem>
    );
}

const Sidebar = withRouter(({ location }) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(true);
    const [showText, setShowAletheaText] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            //Delay showing text so that it doesn't show up below the logo before the sidebar has time to open enough
            setTimeout(() => {
                setShowAletheaText(open);
            }, 200)
        }
        else {
            setShowAletheaText(open);
        }

    }, [open])

    const showUserName = (): string => {
        const name = `${authContext?.profile?.firstName ? authContext.profile.firstName : ""} ${authContext?.profile?.lastName ? authContext.profile.lastName : ""}`
        if (!open)
            return "";
        if (showText)
            return name;
        return "";
    }

    return (
        <>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <List color="primary">
                    <ListItem >
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <img src="/logo_big.png" className="img-fluid" alt="Alethea Logo" width="40" height="40" />
                            </Grid>
                            {showText &&
                                <Grid item xs={1}>
                                    <Fade in={showText}>
                                        <Typography className={classes.header} variant="h6" color="primary">
                                            Alethea Portal
                                        </Typography>
                                    </Fade>
                                </Grid>
                            }
                        </Grid>
                    </ListItem>
                    <Divider />
                    <SidebarItem text={showUserName()} tooltipText={"Profile"} textClass={classes.username} showText={showText} link="/dashboard/profile" icon={<AccountCircleIcon color="primary" />} currentPath={location.pathname} />
                    <Divider />
                    
                    {authContext?.profile?.roles?.resident !== true && (
                        <>
                            <SidebarItem text={"Secure Messaging"} showText={showText} link="/dashboard/secure-messaging" icon={<ChatIcon color="primary" />} currentPath={location.pathname} />
                            <SidebarItem text={"Patient Messages"} showText={showText} link="/dashboard/patient-messages" icon={<InboxIcon color="primary" />} currentPath={location.pathname} />
                            <SidebarItem text={"eConsult"} showText={showText} link="/dashboard/econsult" icon={<SendIcon color="primary" />} currentPath={location.pathname} />
                            <SidebarItem text={"Forms"} showText={showText} link="/dashboard/form-sender" icon={<AssignmentIcon color="primary" />} currentPath={location.pathname} />
                            <SidebarItem text={"One-Way Messaging"} showText={showText} link="/dashboard/one-way-messaging" icon={<EmailIcon color="primary" />} currentPath={location.pathname} />
                            <SidebarItem text={"Billing Report"} showText={showText} link="/dashboard/billing-report" icon={<Description color="primary"/>} currentPath={location.pathname}/>
                        </>
                    )}
                    <SidebarItem text={"Camera & Gallery"} showText={showText} link="/dashboard/camera-gallery" icon={<AddAPhoto color="primary" />} currentPath={location.pathname} />
                    {authContext?.profile?.isSpecialist &&
                        <SidebarItem text={"Image Annotation"} showText={showText} link="/dashboard/image-annotation" icon={<PhotoLibraryIcon color="primary" />} currentPath={location.pathname} />
                    }
                    <LogoutButton className={classes.drawerButton} showText={showText} />
                </List>
                <Divider />
                <ListItem className={classes.drawerButton} button component={"a"} href="https://aletheamedical.com/support" target="_blank">
                    <ListItemIcon className={classes.icon}>
                        <Tooltip
                            title={"Support"}
                            children={<HelpIcon color="primary" />}
                        />
                    </ListItemIcon>
                    <Fade in={showText}>
                        <ListItemText primary="Support" />
                    </Fade>
                </ListItem>
                <Divider />
                <div className={classes.toolbar}>
                    {open ?
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                        :
                        <IconButton onClick={handleDrawerOpen}>
                            <ChevronRightIcon />
                        </IconButton>
                    }
                </div>

            </Drawer>
        </>
    );
})

export default Sidebar