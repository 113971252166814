import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../AuthProvider";
import { logAnalyticsEvent } from "../../../firebase";
import serverRequest from "../../../models/serverRequest";

import Modal from "react-bootstrap/Modal";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import FormTextField from "../../../components/FormTextField";
import FormTextArea from "../../../components/FormTextArea";
import SendButton from "../../../components/SendButton";
import EmailInput from "../../../components/EmailInput";

import { Activity } from "./types";
import useProcessState from "../../../components/useProcessState";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TableBody } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeader: {
            color: theme.palette.text.secondary
        }
    }),
);

interface MOAMessageProps {
    show: boolean,
    handleClose: () => void,
    activity: Activity
}


const MOAMessage = ({  show, handleClose, activity }: MOAMessageProps) => {
    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const { handleSubmit, control, reset } = useForm();

    const { processState, setProcessState, processErrorMessage, setProcessErrorMessage, errorHandler } = useProcessState();

   
    const getSubjectPrepend = (): string => {
        let subjectPrepend = "Secure Message from Dr. ";
        subjectPrepend += `${authContext?.profile?.lastName}: `;
        return subjectPrepend;
    }

    const onSubmit = (data: any) => {
        if(activity === undefined)
            return;//Shouldn't ever happen, but just in case

        setProcessState(ProcessState.running);
       
        const form = new FormData();
        form.append("physicianUid", authContext.uid);
        form.append("activityId", activity.id)
        form.append("patientEmail", data.email);
        form.append("subject", getSubjectPrepend() + data.subject);
        form.append("body", data.body);
        

        logAnalyticsEvent("secure_messaging_forward_econsult_start");
        
        serverRequest(authContext.user, {}, form, 'secure-messaging-moa-message')
        .then(() => {

            logAnalyticsEvent("secure_messaging_forward_econsult_success");

            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 2000);
            resetForm();
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error forwarding econsult", 
                analyticsLog: "secure_messaging_forward_econsult_failed"
            })
        });

    }

    const resetForm = () => {
        reset({
            email: '',
            subject: activity.subject, 
            body: ''
        });
    }

    const onError = () => {
        setProcessErrorMessage("Check form for errors.");
        setProcessState(ProcessState.error);
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <>
			<Modal show={show} onHide={handleClose} size={"lg"}>
				<Modal.Header closeButton>
                    <Grid container>
                        <Grid item xs={12}>
                            <Modal.Title className={classes.subHeader}>Forward econsult as PDF</Modal.Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.subHeader}>Securely forward this econsult, message thread, and attachments as a PDF.</Typography>    
                            <Typography className={classes.subHeader}>Your MOA can view the eConsult by creating an <a href="https://alethea.care" target="_blank" rel="noopener noreferrer">Alethea Care</a> account.</Typography>
                            <Typography className={classes.subHeader}>You can track the eConsults you have sent in the <a href="/dashboard/patient-messages" target="_blank" rel="noopener noreferrer">Patient Messages</a> tab.</Typography>
                        </Grid>
                    </Grid>
				</Modal.Header>
				<Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <fieldset disabled={isDisabled()} >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <EmailInput  
                                        name="email"
                                        label="To"
                                        control={control}
                                        rules={{required: {value: true, message: "Email is required"}}}
                                    />             
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField  
                                        name="subject"
                                        control={control}
                                        label="Subject"
                                        defaultValue={activity.subject}
                                        disabled={isDisabled()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextArea 
                                        name="body"
                                        label="Message"
                                        control={control} 
                                        initRows={3}
                                        disabled={isDisabled()}
                                    />
                                </Grid>
                                <Grid item>
                                    <SendButton
                                        label="Send"
                                        disabled={isDisabled()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} successMessage={"Email sent."}/>    
                                </Grid>
                            </Grid>
                        </fieldset>
                    </form>
				</Modal.Body>
				<Modal.Footer>
					<button className="button-border mr-auto" onClick={handleClose}>
						Close
					</button>
				</Modal.Footer>
			</Modal>
        </>       
    );
}

export default MOAMessage;