import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
import { firebaseConfigProd, firebaseConfigDev, firebaseConfigStaging } from "./firebase-config"

const prodURLEntid = 'portal.entid.ca';
const prodURLAlethea = 'aletheamd.com';
const prodURLTemp = 'entid-9c711'
const devURL = 'entid-dev-9c711';
const stagingURL = 'entid-staging';
const localURL = 'localhost';

let config: any;

const hostname = window.location.hostname;
if(hostname.includes(prodURLEntid) || hostname.includes(prodURLAlethea) || hostname.includes(prodURLTemp)) {
	console.log("Using production config");
	config = firebaseConfigProd;
}
else if(hostname.includes(devURL)) {
	console.log("Using dev config");
	config = firebaseConfigDev;
}
else if(hostname.includes(stagingURL)) {
	console.log("Using staging config");
	config = firebaseConfigStaging;
}
else if(hostname.includes(localURL)) {
	console.log("Localhost: using dev config");
	config = firebaseConfigDev;
}
else {
	console.log("No matching hostname, falling back to dev config");
	config = firebaseConfigDev;
}

const app: firebase.app.App = firebase.initializeApp(config);

const fbAuth = app.auth();
const fbAnalytics = app.analytics();
const fbFirestore = app.firestore();
const fbStorage = app.storage();
const fbFunctions = app.functions();

if(process.env.NODE_ENV === "development") {
    console.log("Using functions emulator");
    fbFunctions.useEmulator("localhost", 5001)

	if(process.env.REACT_APP_USE_FIRESTORE_EMULATOR === "true") {
		
		console.log("Using firestore emulator")
		fbFirestore.useEmulator("localhost", 8080);
	}
}


//Default update remote config every 12 hours
const fbRemoteConfig = app.remoteConfig();

const logAnalyticsEvent = (eventName: string, eventObj?: any) => {
	if(process.env.NODE_ENV === "production")
	{
		if(eventObj)
			fbAnalytics.logEvent(eventName, eventObj);
		else
			fbAnalytics.logEvent(eventName);
	}
	else
	{
		if(eventObj)
			console.log(`environment not in production, not logging analytics event: ${eventName} => ${JSON.stringify(eventObj)}`);
		else
		console.log(`environment not in production, not logging analytics event: ${eventName}`);
	}

}


export { app, fbAuth, fbAnalytics, fbFirestore, fbStorage, fbRemoteConfig, fbFunctions, logAnalyticsEvent };