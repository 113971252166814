import React, { useEffect, useState, useContext } from 'react';
import { app } from "../'../../../../firebase";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as QueryString from "query-string";
import { PatientInfo } from '../../../../shared/types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ProcessState } from "@alethea-medical/react-components";
import { AuthContext } from '../../../AuthProvider';
import formatPhone from "../../../models/formatPhone";

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

interface AVAEconsultEndpointProps extends RouteComponentProps<any> {
    setValue: (name: string, value: any, options?: any) => void,
}

const AVAEconsultEndpoint = withRouter(({ setValue, location }: AVAEconsultEndpointProps) => {

    const avaEconsultAuth = app.functions().httpsCallable("ava-econsultAuth");
    const authContext = useContext(AuthContext);

    const [state, setState] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const params = QueryString.parse(location.search);
        
        if(params.jwt) {
            setState(ProcessState.running);
            avaEconsultAuth({jwt: params.jwt})
            .then((res) => {
                //res.data = {
                    // patientInfo,
                    // patientHistory,
                    // econsultId,
                    // emrSynUid
                // }
                const patientInfo: PatientInfo = res.data.patientInfo;
                setValue("patientInfo", patientInfo, { shouldValidate: true })
                setValue("patientFirstName", patientInfo.firstName, { shouldValidate: true })
                setValue("patientLastName", patientInfo.lastName, { shouldValidate: true })
                setValue("patientEmail", patientInfo.email, { shouldValidate: true })
                setValue("patientPhone", formatPhone(patientInfo.phone), { shouldValidate: true })          
                setValue("medicalHistory", res.data.patientHistory);
                
                //Will get cleared when form is reset
                setValue("avaConsultId", res.data.avaConsultId);
                setValue("avaUuid", res.data.avaUuid)

                setState(ProcessState.success);
                setTimeout(() => {
                    setState(ProcessState.idle);
                }, 2000);
            })
            .catch((error: Error) => {
                setErrorMessage(`${error.message}`);
                setState(ProcessState.error);
                console.error(error);
            })
        }
    }, [authContext.uid])

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                open={state === ProcessState.running}
                message="Fetching AVA econsult info"
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}                
                open={state === ProcessState.success}>
                <Alert severity="success">Successfully fetched Ava econsult info</Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                open={state === ProcessState.error}
            >
                <Alert severity="error" onClose={() => {setState(ProcessState.idle)}}>
                    {`Error fetching AVA econsult info: ${errorMessage}`}
                </Alert>
            </Snackbar>
        </>
    );
})

export default AVAEconsultEndpoint;