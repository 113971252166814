import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(8),
            flexGrow: 1,
        },
        paper: {
            width: "30%",
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },

    })
);

interface AuthViewContainerProps {

}

const ColumnViewContainer: React.FunctionComponent<AuthViewContainerProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container justifyContent="center" spacing={2}>
                <div className={classes.paper}>
                    {children}
                </div>
            </Grid>
        </div>

    );
}

export default ColumnViewContainer;