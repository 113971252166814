import React, {useState, useContext} from "react";
import { useForm, useWatch, useFormState } from "react-hook-form";
import { Body } from "../../Global/components";
import { AuthContext } from "../../../AuthProvider";
import { Form, Col } from "react-bootstrap";
import FileDrop from "../../../components/FileDrop";
import serverRequest from "../../../models/serverRequest";
import { logAnalyticsEvent } from "../../../firebase";
import {ProcessStatus, ProcessState} from "../../Global/components";
import buildSignoff from "../../../models/buildSignoff";
import FormData from "form-data";
import Tooltip from "@material-ui/core/Tooltip";
import { PatientInfo } from "../../../../shared/types";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormTextField from "../../../components/FormTextField";
import FormTextArea from "../../../components/FormTextArea";
import FormCheckbox from "../../../components/FormCheckbox";
import PHNInputLookup from "../../../components/PHNInputLookup";
import EmailInput from "../../../components/EmailInput";
import LocationDropdown from "../../../components/LocationDropdown";
import SendButton from "../../../components/SendButton";
import SuccessModal from "../../../components/SuccessModal";

import InputLabel from "@material-ui/core/InputLabel"
import LeavePagePrompt from "../../../components/LeavePagePrompt";


const AnonMailer = () => {    

    const { handleSubmit, control, setValue, getValues, reset } = useForm( {mode: "onTouched"});
    const { isDirty } = useFormState({control: control});
    const locationIdx = useWatch({control, name: "locationIdx", defaultValue: 0});


    const authContext = useContext(AuthContext);

    const [processState, setProcessState] = useState(ProcessState.idle);

    const [errorMessage, setErrorMessage] = useState('');

    //Toggle boolean to reset files
    const [resetFiles, setResetFiles] = useState(false);

    const [attachments, setAttachments] = useState<FileDrop.AttachmentList>({});


    


    const getSubjectPrepend = (): string => {
        let subjectPrepend = "Secure Message from Dr. ";
        subjectPrepend += `${authContext?.profile?.lastName}: `;
        return subjectPrepend;
    }


    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);
        setErrorMessage("");

        logAnalyticsEvent("one_way_patient_email_start");

        const form = new FormData();
        form.append("physicianUid", authContext.uid);
        form.append("locationIdx", data.locationIdx);
        form.append("patientEmail", data.email);
        form.append("phn", data.patientInfo.phn);
        form.append("subject", getSubjectPrepend() + data.subject);
        form.append("body", data.body);
        form.append("delay", data.delaySend);
        Object.values(attachments).forEach((attachmentWithPreview) => {
            form.append("attachment", attachmentWithPreview.file, attachmentWithPreview.file.name);
        });
        
        serverRequest(authContext.user, {}, form, 'one-way-patient-message')
        .then(() => {

            logAnalyticsEvent("one_way_patient_email_success");

            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 2000);
            resetForm();
        })
        .catch((error: Error) => {

            logAnalyticsEvent("one_way_patient_email_failed");

            console.log(error);
            setProcessState(ProcessState.error);
            setErrorMessage(error.message);
        });

    }

    const resetForm = () => {
        reset({
            locationIdx: getValues("locationIdx"),
            delaySend: false,
            patientInfo: {
                phn: "",
                firstName: "",
                lastName: "",
                email: "",
                phone: ""
            }, 
            email: '',
            subject: '', 
            body: `\n\n${buildSignoff(authContext.profile, locationIdx)}`
        });
        setResetFiles(!resetFiles);//Toggle bool to reset   
    }

    const onError = () => {
        setErrorMessage("Check form for errors.");
        setProcessState(ProcessState.error);
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }


    return (
        <>
            <Body title="One-Way Messaging" subtitle="">
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <fieldset disabled={isDisabled()} >
                        <Form.Row>
                            <Form.Group as={Col} md="auto">
                                <Form.Label>
                                    <Tooltip title="Your email is sent to the patient through a secure email account not associated with your account. You will be added as a BCC to the email."
                                        placement="right">
                                        <div className="tooltip-hover">How is my email sent?</div>
                                    </Tooltip>
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <InputLabel>Clinic</InputLabel>
                                <LocationDropdown
                                    name="locationIdx"
                                    control={control}
                                    label="Clinic"
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <PHNInputLookup
                                    name="patientInfo"
                                    control={control}
                                    label="PHN"
                                    required
                                    setValue={(newPatientInfo: PatientInfo) => {setValue("email", newPatientInfo.email, {shouldValidate: true} )}}
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">                            
                                <EmailInput  
                                    name="email"
                                    label="Patient Email"
                                    control={control}
                                    rules={{required: {value: true, message: "Email is required"}}}
                                />
                            </Form.Group>
                        </Form.Row>
                        {/* Delay email checkbox */}
                        <Form.Row>
                            {/* Wrap in fragment to prevent error about refs from being thrown */}
                            <Tooltip title="Delay sending the email until the next day at 7:00 AM MST"
                                    placement="right">
                                    <>
                                        <FormCheckbox
                                            name="delaySend"
                                            label="Delay sending"
                                            control={control}
                                        />
                                    </>
                            </Tooltip>
                        </Form.Row>

                        <Form.Group>
                            <InputLabel>Subject</InputLabel>
                            <FormTextField  
                                name="subject"
                                control={control}
                                {...{
                                    InputProps: {
                                        startAdornment: <InputAdornment position="start">{getSubjectPrepend()}</InputAdornment>
                                    }   
                                }}
                                disabled={isDisabled()}
                            />
                
                        </Form.Group>
                        
                        {authContext.profile !== undefined && (//Check if undefined so that signoff doesn't receive undefined profile
                            <Form.Group>
                                <InputLabel>Message</InputLabel>   
                                <FormTextArea 
                                    name="body"
                                    control={control} 
                                    initRows={3}
                                    defaultValue={`\n\n${buildSignoff(authContext.profile, locationIdx)}`}
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                        )}


                        <Form.Group controlId="attachments">
                            <InputLabel>Attachments</InputLabel>
                            <FileDrop.FileDrop reset={resetFiles} attachments={attachments} setAttachments={setAttachments}/>
                        </Form.Group>


                        
                        <Form.Row>
                            <Form.Group as={Col} md="auto">
                                <SendButton
                                    label="Send"
                                    disabled={isDisabled()}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="auto">
                                <ProcessStatus processState={processState} errorMessage={errorMessage}/>
                            </Form.Group>
                        </Form.Row>

                    </fieldset>
                </Form>
            </Body>
            <SuccessModal
                text={"Message sent successfully."}
                show={processState === ProcessState.success}
            />
            <LeavePagePrompt isDirty={isDirty}/>
        </>
    );
}

export default AnonMailer;