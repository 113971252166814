import React, { useState, useEffect, useContext } from "react";
import { Control, useWatch } from "react-hook-form";
import { AuthContext } from "../../../AuthProvider";
import { app } from "../../../firebase";
import FormSelect from "../../../components/FormSelect";


interface Specialist {
    uid: string,
    label: string
}


interface RouteTree {
    [specialty: string]: {
        [subsite: string]: Specialist[]
    }
}


interface SpecialistSelectProps {
    control: Control,
    setValue: (name: string, value: any) => void,
    disabled: boolean
}

const noSpecialistUid = "none";

const SpecialistSelect = ({control, setValue, disabled}: SpecialistSelectProps) => {
    const specialty = useWatch({control, name: "specialty", defaultValue: ""});
    const subsite = useWatch({control, name: "subsite", defaultValue: ""});
    const locationIdx = useWatch({control, name: "locationIdx", defaultValue: 0});

    const authContext = useContext(AuthContext);

    const routeReferral = app.functions().httpsCallable("routing-routeReferral");

    const [specialists, setSpecialists] = useState<Specialist[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [routeTree, setRouteTree] = useState<RouteTree>({});

    useEffect(() => {
        if(authContext?.profile?.locations) {
            const selectedLocation = authContext?.profile?.locations[locationIdx]
            if(selectedLocation?.city && selectedLocation?.province && selectedLocation.city !== '' && selectedLocation?.province !== '') {
                setLoading(true);
                routeReferral({
                    city: selectedLocation.city,
                    province: selectedLocation.province
                })
                .then((result) => {
                    const data = result.data as RouteTree
                    setRouteTree(data);
                    setLoading(false);
                })
                .catch((error: Error) => {
                    console.error(error);
                    setRouteTree({});
                    setLoading(false);
                });
            }
        }

    }, [locationIdx, authContext?.profile]);

    useEffect(() => {
        //Update dropdown
        if(routeTree !== undefined && routeTree[specialty] !== undefined && routeTree[specialty][subsite] !== undefined && routeTree[specialty][subsite].length > 0) {
            setValue("specialistUid", routeTree[specialty][subsite][0].uid);
            setSpecialists(routeTree[specialty][subsite]);
        }              
        else if(loading) {
            setValue("specialistUid", noSpecialistUid);
            setSpecialists([{uid: noSpecialistUid, label: "Loading specialists"}]);
        }
            
        else {
            setValue("specialistUid", noSpecialistUid);
            setSpecialists([{uid: noSpecialistUid, label: "No specialists available"}]);        
        }
    }, [routeTree, specialty, subsite, loading]);


    return (
        <FormSelect
            name="specialistUid"
            control={control}
            options={specialists}
            label={"Specialist"}
            rules={{required: {value: true, message: "A specialist is required"}, validate: {
                isSpecialistSelected: (specialistUid: string) => { return specialistUid === noSpecialistUid ? "A specialist is required" : undefined}
            }}}
            disableWhenOne={specialists.length === 1 && specialists[0].uid === noSpecialistUid}
            getOptionLabel={(specialist: Specialist) => {
                return specialist.label
            }}
            getOptionValue={(specialist: Specialist) => {
                return specialist.uid
            }}
            disabled={disabled}
        />
    );

}

export default SpecialistSelect;