import React, { useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        floatPaper: {
            position: "fixed",
            cursor: "pointer",
            zIndex: 1000,
            borderColor: "#f36c24",
            borderStyle: "solid",
            borderWidth: theme.spacing(0.25),
            borderRightWidth: 0
        },
        floatPaperSmall: {
            height: "50px",
            width: "50px"
        },
        floatPaperLarge: {
            height: "50px",
            width: "250px"
        },
        floatButtonIcon: {
            marginLeft: theme.spacing(1),
            height: "100%",
            color: "#f36c24",
        },
        grid: {
            height: "100%"
        },
    }),
);
interface FloatingButtonProps {
    show: boolean,
    setShow: (show: boolean) => void,
    textShow: string,//Button text when show is false (button will show when clicked)
    textHide: string,//Button text when show is true (button will hide when clicked)
    top?: number,
    bottom?: number,
    right?: number,
    left?: number,
}

const FloatingButton = ({ show, setShow, textShow, textHide, top, bottom, right, left}: FloatingButtonProps) => {
    const classes = useStyles();
    const [slideIn, setSlideIn] = useState(false);
    const [hideSmallButton, setHideSmallButton] = useState(false);

    const paperLocation = {
        top: top,
        bottom: bottom,
        right: right,
        left: left
    }

    return (
        <>
            <Slide in={!hideSmallButton} direction="left" timeout={50}>
                <Paper className={`${classes.floatPaper} ${classes.floatPaperSmall}`} 
                    style={{
                        ...paperLocation
                    }} 
                    elevation={2} 
                    onMouseEnter={()=> {setSlideIn(true)}} 
                    onClick={() => {setShow(!show)}}>
                    <Grid container className={classes.grid} alignItems="center">
                        <Grid item>
                            {show ? <ChevronRightIcon className={classes.floatButtonIcon}/> : <ChevronLeftIcon className={classes.floatButtonIcon} color="primary"/>}
                        </Grid>
                    </Grid>
                </Paper>
            </Slide>
            <Slide in={slideIn} direction="left" timeout={100} 
                onEnter={() => {setHideSmallButton(true)}} 
                onExiting={() => { 
                    setTimeout(() => {
                        setHideSmallButton(false)
                    }, 20)
                }}>
                <Paper className={`${classes.floatPaper} ${classes.floatPaperLarge}`} 
                    style={{
                        ...paperLocation
                    }} 
                    elevation={2} 
                    onMouseLeave={()=> {setSlideIn(false)}} 
                    onClick={() => {setShow(!show)}}>
                    <Grid container className={classes.grid} alignItems="center">
                        <Grid item>
                            {show ? <ChevronRightIcon className={classes.floatButtonIcon} color="primary"/> : <ChevronLeftIcon className={classes.floatButtonIcon} color="primary"/>}
                        </Grid>
                        <Grid item>
                            <Typography>
                                {show ? textHide : textShow}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Slide>

        </>
    );
}

export default FloatingButton;