import React from "react";
import { useHistory } from "react-router-dom";
import { fbAuth } from "../../../firebase";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            minWidth: '45px',
        }
    }),
);

interface LogoutButtonProps {
    className: string,
    showText: boolean
}
const LogoutButton = ({className, showText}: LogoutButtonProps) => {

    const classes = useStyles();
    const history = useHistory();

    const handleClick = (event: any) => {
        event.preventDefault();

        fbAuth
        .signOut()
        .then(() => {
            history.push("/login");
        })
    }
    
    
    return (
        <ListItem className={className} button onClick={handleClick}>
            <ListItemIcon className={classes.icon}>
                <Tooltip
                    title={"Logout"}
                    children={<ExitToAppIcon color="primary"/>}
                />
            </ListItemIcon>
            <Fade in={showText}>
                <ListItemText primary="Logout"/>
            </Fade>
        </ListItem>
    )
}

export default LogoutButton;