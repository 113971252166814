import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outcomeText: {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium
        },
        outcomeTextSmall: {
            fontSize: "1.0em"
        }
    }),
);


interface EconsultOutcomesLabelProps {
    specialistResponse?: {
        outcome: string,
        diagnosis: string
    },
    fontSize?: "small" | "large"
}

const EconsultOutcomesLabel = ({ specialistResponse, fontSize="large"}: EconsultOutcomesLabelProps) => {
    const classes = useStyles();

    return (
        <>
            {specialistResponse !== undefined && (
                <>
                    <Typography className={`${classes.outcomeText} ${fontSize === "small" ? classes.outcomeTextSmall : ""}`}>
                        Outcome: {specialistResponse.outcome}
                    </Typography>
                    <Typography className={`${classes.outcomeText} ${fontSize === "small" ? classes.outcomeTextSmall : ""}`}>
                        Diagnosis: {specialistResponse.diagnosis}
                    </Typography>
                </>
            )}
        </>
    );
}

export default EconsultOutcomesLabel;