import React, { FunctionComponent } from "react";
import * as CSS from 'csstype';
import "./Gallery.css";
import { ImageSrc } from "./BaseGallery";
import ReactPlayer from 'react-player'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Grid from '@material-ui/core/Grid';
import isImage from "../../../models/isImage";
import isVideo from "../../../models/isVideo";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Label from "../../../components/Label";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            backgroundColor: "white",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
            left: 0,
            top: 0,
            height: "20%",
            width: "20%",
            "&:hover": {
                outline: "2px solid",
                outlineColor: theme.palette.primary.main
            }
        }
    }),
);



interface ContainerProps {
    margin: string,
    className: string,
    fileType: string,
    onClick: (e: any) => void
}

const Container: FunctionComponent<ContainerProps> = (props) => {
    const { margin, className, fileType, onClick } = props;

    return (
        <div style={{ margin } as CSS.Properties} className={className} onClick={onClick}>
            {props.children}
            {isVideo(fileType) &&
                <button className="rg-play button-material" onClick={onClick}>
                    <svg width="20px" height="20px" className="rg-play">
                        <circle className="rg-checkmark-circle-inner" cx="10" cy="10" r="10" />
                        <PlayCircleFilledIcon />
                    </svg>
                </button>
            }
        </div>
    );
}

const formatName = (name: string) => {
    if (/^[a-z,0-9]{16}_.*/.test(name)) {
        return name.split("_")[1]
    }
    else {
        return name;
    }
}


export interface MessagingRendererComponentProps {
    index: number,
    photo: ImageSrc,
    margin: string,
    handleShowModal: (image: ImageSrc) => void,
    labels?: string[],
}

const MessagingRenderer = ({ photo, margin, handleShowModal, labels }: MessagingRendererComponentProps) => {
    const fileType = photo.name.split('.')[1];
    const classes = useStyles();


    const handleClick = (e: any) => {
        handleShowModal(photo);
    };


    return (
        <>
            {isImage(fileType) &&
                <>
                    <Container margin={margin} className={classes.imageContainer} fileType={fileType} onClick={handleClick}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={12}>
                                <img
                                    className={`g-image`}
                                    src={photo.src}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {formatName(photo.name)}
                            </Grid>
                                {labels !== undefined && labels.map((label) => {
                                    return (
                                        <Grid item xs={12} key={`label_${label}`}>
                                            <Label text={label} color="dark"/>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </Container>
                </>
            }


            {isVideo(fileType) &&
                <Container margin={margin} className={classes.imageContainer} fileType={fileType} onClick={handleClick}>
                    <ReactPlayer
                        className={`g-image`}
                        url={photo.src}
                        width='100%'
                        height='100%'
                    />
                    {formatName(photo.name)}
                </Container>
            }
        </>

    );
};

export default MessagingRenderer;

