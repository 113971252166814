import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(7)
        },
        logo: {
            width: "30%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
        },
        centerText: {
            textAlign: "center"
        }
    }),
);

interface TextBlockProps {
    variant: "inherit" | Variant | undefined
}

const TextBlock: React.FunctionComponent<TextBlockProps> = ({variant, children}) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Typography variant={variant} className={classes.centerText}>
                {children}
            </Typography>
        </Grid>
    )
}

interface MaintenanceProps {

}

const Maintenance = ({  }: MaintenanceProps) => {

    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.container} justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <img className={classes.logo} src="Alethea LogoPDFWriter.png"/>
                </Grid>
                <TextBlock variant="h3">
                    Under Maintenance
                </TextBlock>
                <TextBlock variant="subtitle1">
                    AletheaMD will be down while it is under maintenance from 8:00 PM MST to 11:00 PM MST, January 29th, 2022. We apologize for any inconvenience.
                </TextBlock>                
            </Grid>
            
        </>
    );
}

export default Maintenance;